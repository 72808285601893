<template>
  <EngieDialog
    :open="open"
    :dialog-title="dialogTitle"
    :disable-close-button="saving"
    align-action-buttons-right
    @dialog-closed="handleDialogClosed()"
  >
    <div class="form-container">
      <EngieTextInput
        v-model="companyName"
        autofocus
        label="Company Name"
        :disabled="saving"
        :error-messages="companyNameErrors"
        @blur="$v.companyName.$touch()"
      />
      <EngieReferenceDataSelect
        v-model="planLevelId"
        label="Plan Level"
        reference-data-name="plan-types"
        :disabled="saving"
        @default-option-fetched="handleDefaultPlanLevelIdFetched($event)"
      />
      <EngieTextInput
        v-model="companyAddress"
        label="Company Address"
        :disabled="saving"
        :error-messages="companyAddressErrors"
        @blur="$v.companyAddress.$touch()"
      />
      <EngieReferenceDataSelect
        v-model="storageAmountId"
        label="Storage Amount"
        reference-data-name="storage-amounts"
        :disabled="saving"
        @defaultOptionFetched="handleDefaultStorageAmountIdFetched($event)"
      />
      <EngieTextInput
        v-model="companySize"
        label="Company Size"
        required
        :disabled="saving"
        type="number"
        :min="1"
        :error-messages="companySizeErrors"
        @blur="$v.companySize.$touch()"
      />
      <EngieTextInput
        v-model="headAdminName"
        label="Head Admin Name"
        :disabled="saving"
        :error-messages="headAdminNameErrors"
        @blur="$v.headAdminName.$touch()"
      />
      <EngieUserEmailInput
        v-model="headAdminEmail"
        label="Head Admin Email"
        :disabled="saving || editMode"
        :error-messages="headAdminEmailErrors"
        @blur="$v.headAdminEmail.$touch()"
        @unused-email-entered="handleUnusedEmailEntered()"
        @existing-email-entered="handleExistingEmailEntered()"
      />
      <EngieTextInput
        v-model="headAdminPhoneNumber"
        phone
        label="Head Admin Phone Number"
        :disabled="saving"
        :error-messages="headAdminPhoneNumberErrors"
        @blur="$v.headAdminPhoneNumber.$touch()"
        @unused-email-entered="handleUnusedEmailEntered()"
        @existing-email-entered="handleExistingEmailEntered()"
      />
    </div>
    <div v-if="editMode && companyNotDeleted" class="super-admin-actions">
      <EditDialogAction
        v-if="showPauseAction"
        title="Pause Account"
        description="Temporarily deactivate an account. Users will not have access to their account until they reactivate. All
          photos, data and information will be stored for 365 days."
        :confirm-dialog-title="`Pause ${company && company.name} Account`"
        action-button-label="Pause Account"
        :dialog-open="confirmPauseDialogOpen"
        :performing-action="pausingCompany"
        dialog-confirm-explanation="This will temporaily deactivate the account."
        @dialog-opened="handleConfirmPauseDialogOpened()"
        @dialog-closed="handleConfirmPauseDialogClosed()"
        @confirmed="handleCompanyPaused(company && company.id)"
      />
      <EditDialogAction
        v-else
        title="Reactivate Account"
        :description="`This account was paused on ${pausedDate}. If not reactivated, it will be permanently deleted 365 days from
          this date.`"
        :confirm-dialog-title="`Reactivate ${company && company.name} Account`"
        action-button-label="Reactivate Account"
        :dialog-open="confirmReactivateDialogOpen"
        :performing-action="reactivatingCompany"
        dialog-confirm-explanation="This will reactivate the account, restoring access for all active users associated with the account."
        @dialog-opened="handleConfirmReactivateDialogOpened()"
        @dialog-closed="handleConfirmReactivateDialogClosed()"
        @confirmed="handleCompanyReactivated(company && company.id)"
      />
    </div>
    <template #action-buttons>
      <EngieButton class="create-account-button" :loading="saving" @click="handleSaveClicked()">{{
        saveButtonText
      }}</EngieButton>
    </template>
    <EngieSuccessSnackbar
      v-model="showPausedSuccessSnackbar"
      :text="`${company && company.name} paused successfully`"
      @close="handlePauseSuccessSnackbarClosed()"
    />
    <EngieSuccessSnackbar
      v-model="showReactivatedSuccessSnackbar"
      :text="`${company && company.name} reactivated successfully`"
      @close="handleReactivatedSuccessSnackbarClosed()"
    />
    <EngieErrorSnackbar
      v-model="showFormErrorSnackbar"
      text="Please correct the form errors before submitting"
      @close="handleFormErrorSnackbarClosed()"
    />
    <EngieErrorSnackbar
      v-model="showErrorSavingCompanySnackbar"
      text="An error occurred while saving the account"
      @close="handleCreateCompanyErrorSnackbarClosed()"
    />
  </EngieDialog>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue"
import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"
import { checkEmail } from "@/util/validators/checkEmail"
import { checkPhoneNumber } from "@/util/validators/checkPhoneNumber"
import { phoneNumberValidation } from "@/util/validators/phoneNumberValidation"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompaniesUrl } from "@/util/urls"
import { CompanyRecord } from "@/types/CompanyRecord"
import EngieDialog from "../EngieDialog.vue"
import EngieButton from "../forms/EngieButton.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import EngieUserEmailInput from "../forms/EngieUserEmailInput.vue"
import EngieReferenceDataSelect from "../forms/EngieReferenceDataSelect.vue"
import EngieErrorSnackbar from "../EngieErrorSnackbar.vue"
import EngieSuccessSnackbar from "../EngieSuccessSnackbar.vue"
import { checkRequired } from "../../util/validators/checkRequired"
import { companySizeValidation } from "../../util/validators/companySizeValidation"
import { CompanyPutBody } from "../../types/CompanyPutBody"
import EditDialogAction from "../EditDialogAction.vue"

export default (Vue as VueConstructor<Vue & typeof validationMixin>).extend({
  components: {
    EngieButton,
    EngieDialog,
    EngieTextInput,
    EngieReferenceDataSelect,
    EngieErrorSnackbar,
    EngieUserEmailInput,
    EngieSuccessSnackbar,
    EditDialogAction,
  },
  mixins: [validationMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "Title",
    },
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      companyName: "",
      planLevelId: "",
      defaultPlanLevelId: "",
      companyAddress: "",
      storageAmountId: "",
      defaultStorageAmountId: "",
      companySize: "1",
      headAdminName: "",
      headAdminEmail: "",
      headAdminPhoneNumber: "",
      saving: false,
      showFormErrorSnackbar: false,
      showErrorSavingCompanySnackbar: false,
      usableEmailEntered: true,
      confirmPauseDialogOpen: false,
      confirmReactivateDialogOpen: false,
      pausingCompany: false,
      reactivatingCompany: false,
      showPausedSuccessSnackbar: false,
      showReactivatedSuccessSnackbar: false,
    }
  },
  computed: {
    formattedHeadAdminPhoneNumber(): string {
      if (this.headAdminPhoneNumber[0] !== "(") {
        return `(${this.headAdminPhoneNumber}`
      }

      return this.headAdminPhoneNumber
    },
    companyNameErrors(): string[] {
      return checkRequired(this.$v.companyName)
    },
    companyAddressErrors(): string[] {
      return checkRequired(this.$v.companyAddress)
    },
    companySizeErrors(): string[] {
      if (!this.$v.companySize.$dirty) {
        return []
      }

      if (!this.$v.companySize.required) {
        return ["Please enter a valid number"]
      }

      return []
    },
    headAdminNameErrors(): string[] {
      return checkRequired(this.$v.headAdminName)
    },
    headAdminEmailErrors(): string[] {
      return checkEmail(this.$v.headAdminEmail)
    },
    headAdminPhoneNumberErrors(): string[] {
      return checkPhoneNumber(this.$v.headAdminPhoneNumber)
    },
    saveButtonText(): string {
      return this.editMode ? "Save Account" : "Create New Account"
    },
    showPauseAction(): boolean {
      return this.company && !this.company.pausedDateTime
    },
    pausedDate(): string {
      return this.company && new Date(this.company.pausedDateTime).toDateString()
    },
    companyNotDeleted(): boolean {
      return this.company && !this.company.deletedDateTime
    },
  },
  watch: {
    open(open: boolean) {
      if (open && this.company) {
        this.companyName = this.company.name
        this.companyAddress = this.company.address
        this.planLevelId = this.company.planTypeId
        this.storageAmountId = this.company.storageAmountId
        this.companySize = this.company.size
        this.headAdminName = this.company.headAdminName
        this.headAdminEmail = this.company.headAdminEmail
        this.headAdminPhoneNumber = this.company.headAdminPhoneNumber
      }
    },
  },
  methods: {
    handleSaveClicked() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.showFormErrorSnackbar = true
      } else {
        this.saveCompany()
      }
    },
    getCreatedRecord(companyResponse: CompanyRecord) {
      return {
        ...companyResponse,
        headAdminName: this.headAdminName,
        headAdminEmail: this.headAdminEmail,
        headAdminPhoneNumber: this.headAdminPhoneNumber,
      }
    },
    saveCompany() {
      if (this.editMode) {
        this.updateCompany()
      } else {
        this.saveCompany()
      }
    },
    // TODO: refactor to remove duplication
    createCompany() {
      this.saving = true

      const body = {
        name: this.companyName,
        planTypeId: this.planLevelId,
        storageAmountId: this.storageAmountId,
        headAdminEmail: this.headAdminEmail,
        headAdminPhoneNumber: this.headAdminPhoneNumber,
        headAdminName: this.headAdminName,
        size: parseInt(this.companySize, 10),
        address: this.companyAddress,
      }

      makeAuthenticatedRequest(getCompaniesUrl(), "POST", body)
        .then(response => {
          this.$emit("company-created", this.getCreatedRecord(response))
          this.reset()
        })
        .catch(() => {
          this.showErrorSavingCompanySnackbar = true
        })
        .finally(() => {
          this.saving = false
        })
    },
    updateCompany() {
      this.saving = true

      // TODO: handle email change?

      const body: CompanyPutBody = {
        name: this.companyName,
        planTypeId: this.planLevelId,
        storageAmountId: this.storageAmountId,
        size: parseInt(this.companySize, 10),
        address: this.companyAddress,
      }

      if (
        this.headAdminPhoneNumber !== this.company.headAdminPhoneNumber ||
        this.headAdminName !== this.company.headAdminName
      ) {
        body.headAdminPhoneNumber = this.headAdminPhoneNumber
        body.headAdminName = this.headAdminName
      }

      makeAuthenticatedRequest(`${getCompaniesUrl()}/${this.company.id}`, "PUT", body)
        .then(response => {
          this.$emit("company-updated", this.getCreatedRecord(response))
          this.reset()
        })
        .catch(() => {
          this.showErrorSavingCompanySnackbar = true
        })
        .finally(() => {
          this.saving = false
        })
    },
    // END TODO
    handleUnusedEmailEntered() {
      this.usableEmailEntered = true
    },
    handleExistingEmailEntered() {
      this.$v.headAdminEmail.$touch()
      this.usableEmailEntered = false
    },
    handleDefaultStorageAmountIdFetched(value: string) {
      this.defaultStorageAmountId = value
    },
    handleDefaultPlanLevelIdFetched(value: string) {
      this.defaultPlanLevelId = value
    },
    handleDialogClosed() {
      this.$emit("dialog-closed")
      this.reset()
    },
    handleFormErrorSnackbarClosed() {
      this.showFormErrorSnackbar = false
    },
    handleCreateCompanyErrorSnackbarClosed() {
      this.showErrorSavingCompanySnackbar = false
    },
    reset() {
      this.companyName = ""
      this.companyAddress = ""
      this.headAdminName = ""
      this.headAdminEmail = ""
      this.headAdminPhoneNumber = ""
      this.companySize = "1"
      this.planLevelId = this.defaultPlanLevelId
      this.storageAmountId = this.defaultStorageAmountId

      this.$v.$reset()
    },
    handleConfirmPauseDialogClosed() {
      this.confirmPauseDialogOpen = false
    },
    handleConfirmPauseDialogOpened() {
      this.confirmPauseDialogOpen = true
    },
    updateCompanyPausedDateTime(companyId: string, reactivate = false) {
      const date = reactivate ? null : new Date()

      return makeAuthenticatedRequest(`${getCompaniesUrl()}/${companyId}`, "PUT", {
        pausedDateTime: date,
      })
    },
    async handleCompanyPaused(companyId: string) {
      this.pausingCompany = true

      try {
        await this.updateCompanyPausedDateTime(companyId)

        this.confirmPauseDialogOpen = false
        this.showPausedSuccessSnackbar = true

        this.$emit("company-paused", this.company.id)
      } catch (error) {
        this.showErrorSavingCompanySnackbar = true
      }

      this.pausingCompany = false
    },
    handlePauseSuccessSnackbarClosed() {
      this.showPausedSuccessSnackbar = false
    },
    handleConfirmReactivateDialogClosed() {
      this.confirmReactivateDialogOpen = false
    },
    handleConfirmReactivateDialogOpened() {
      this.confirmReactivateDialogOpen = true
    },
    async handleCompanyReactivated(companyId: string) {
      this.reactivatingCompany = true

      try {
        await this.updateCompanyPausedDateTime(companyId, true)

        this.confirmReactivateDialogOpen = false
        this.showReactivatedSuccessSnackbar = true

        this.$emit("company-reactivated", this.company.id)
      } catch (error) {
        this.showErrorSavingCompanySnackbar = true
      }

      this.reactivatingCompany = false
    },
    handleReactivatedSuccessSnackbarClosed() {
      this.showReactivatedSuccessSnackbar = false
    },
  },
  validations: {
    companyName: {
      required,
    },
    planLevelId: {
      required,
    },
    companyAddress: {
      required,
    },
    storageAmountId: {
      required,
    },
    companySize: companySizeValidation,
    headAdminName: {
      required,
    },
    headAdminEmail: {
      required,
      email,
      notTaken() {
        return this.usableEmailEntered
      },
    },
    headAdminPhoneNumber: phoneNumberValidation,
  },
})
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1rem));
  grid-column-gap: 2rem;

  .engie-text-input {
    width: 100%;
  }
}
.super-admin-actions {
  margin-top: 2rem;
}
</style>
