<template>
  <v-card :loading="loading" class="proj-card" :style="`width: ${width}`">
    <template slot="progress">
      <v-progress-linear color="primary" indeterminate> </v-progress-linear>
    </template>
    <div
      v-if="project.thumbnailImageUrl"
      class="card-img"
      :style="`background-image: url(${project.thumbnailImageUrl})`"
    ></div>
    <v-img v-else class="card-img" style="background-color: var(--lightest-grey)"></v-img>
    <v-card-text class="card-text" style="text-align: center">
      <v-card-title class="pa-0" style="margin-bottom: 1rem; display: block"
        ><h3 class="card-title" style="color: var(--navy); font-weight: 400">{{ project.name }}</h3>
      </v-card-title>
      <v-card-subtitle class="pa-0 ma-0 subtitle">Project No. {{ project.number }} </v-card-subtitle>
    </v-card-text>
    <v-card-actions class="d-flex justify-center align-center card-actions">
      <slot name="action-btn"></slot>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "100%",
    },
  },
})
</script>

<style lang="scss">
.proj-card {
  max-width: 25.8rem;
  border-radius: 15px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.subtitle {
  font-size: 1.5rem !important;
}

.card-img {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  height: 20rem;
  max-height: 20rem;
  background-position: center;
  background-size: cover;
}

.card-title {
  white-space: initial;
  word-break: break-word;
}

.card-actions {
  margin-top: auto;
}
</style>
