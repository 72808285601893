<template>
  <div>
    <EngieButton v-if="text === null || !text" :color="color" :disabled="disabled" @click="handleDialogOpened()">{{
      buttonLabel
    }}</EngieButton>
    <EngieTextButton v-else color="secondary" :disabled="disabled" @click="handleDialogOpened()">{{
      buttonLabel
    }}</EngieTextButton>
    <EngieDialog
      :open="open"
      :dialog-title="dialogTitle"
      :narrow="narrow"
      :narrower="narrower"
      :align-action-buttons-right="alignActionButtonsRight"
      :outlined-actions-section="outlinedActionsSection"
      :error-occurred="errorOccurred"
      :error-message="errorMessage"
      :loading="loading"
      @dialog-closed="closeModal()"
      @enter-pressed="handleEnterPressed()"
    >
      <slot></slot>
      <div class="action-buttons-container">
        <slot name="action-buttons"></slot>
      </div>

      <!-- This template allows us to pass any slots from parent to child so that instances of EngieTable may customize item or header slots -->
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </EngieDialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import EngieButton from "./forms/EngieButton.vue"
import EngieTextButton from "./forms/EngieTextButton.vue"
import EngieDialog from "./EngieDialog.vue"

export default Vue.extend({
  components: {
    EngieButton,
    EngieDialog,
    EngieTextButton,
  },
  props: {
    buttonLabel: {
      type: String,
      default: "Open modal",
    },
    dialogTitle: {
      type: String,
      default: "Title",
    },
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
    narrow: {
      type: Boolean,
      default: null,
    },
    narrower: {
      type: Boolean,
      default: null,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    text: {
      type: Boolean,
      default: null,
    },
    alignActionButtonsRight: {
      type: Boolean,
      default: null,
    },
    outlinedActionsSection: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("dialog-closed")
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
  },
})
</script>

<style lang="scss" scoped>
.action-buttons-container {
  display: flex;
}
</style>
