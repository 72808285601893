<template>
  <v-dialog
    :retain-focus="false"
    :value="locationDialogOpen"
    max-width="81rem"
    class="assign-dialog"
    :persistent="true"
    @keydown.esc="closeModal()"
  >
    <div class="location-dialog-container">
      <div class="dialog-title-container">
        <span class="dialog-title">Set Location Manually</span>
        <v-btn class="close-btn" icon @click="closeModal()">
          <CloseIcon class="close-icon" />
        </v-btn>
      </div>
      <div class="dialog-content-container">
        <div class="address-container">
          <h3 class="location-header">Set Location Address</h3>
          <EngieVerifiedAddressInput
            :label="'Location: '"
            :address-error="addressError"
            require-verified
            @location-selected="handleAddressLocationSelected"
          />
          <v-btn rounded depressed color="primary" class="submit-location-btn" @click="handleAddressLocationSubmit">
            Set Location
          </v-btn>
        </div>
        <div class="lat-lng-container">
          <h3 class="location-header">Set Location By Latitude &amp; Longitude</h3>
          <v-form>
            <EngieTextInput
              v-model="photoManualLatitude"
              :label="'Latitude:'"
              :error-messages="manualError"
              class="notes-input"
            />
            <EngieTextInput
              v-model="photoManualLongitude"
              :label="'Longitude:'"
              :error-messages="manualError"
              class="notes-input"
            />
            <v-btn rounded depressed color="primary" class="submit-location-btn" @click="handleManualLocationSubmit">
              Set Location
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import CloseIcon from "../common_icons/CloseIcon.vue"
import EngieVerifiedAddressInput from "../EngieVerifiedAddressInput.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"

export default {
  components: { EngieTextInput, CloseIcon, EngieVerifiedAddressInput },
  props: {
    locationDialogOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    photoManualLatitude: "",
    photoManualLongitude: "",
    manualError: [],
    addressError: null,
    addressLocation: null,
  }),
  methods: {
    closeModal() {
      this.$emit("close")
    },

    handleAddressLocationSelected(value) {
      this.addressError = null
      if (value.latitude && value.longitude) {
        this.addressLocation = { latitude: value.latitude, longitude: value.longitude }
      }
    },

    handleAddressLocationSubmit() {
      this.addressError = null
      if (this.addressLocation === null || this.addressLocation.longitude === undefined) {
        this.addressError = "No location submitted"
      } else {
        this.addressError = null
        this.$emit("location-updated", this.addressLocation)
      }
    },

    handleManualLocationSubmit() {
      if (this.photoManualLatitude === "" || this.photoManualLongitude === "") {
        this.manualError = ["No location submitted"]
      } else {
        const locationData = { latitude: this.photoLatitude, longitude: this.photoLongitude }
        this.$emit("location-updated", locationData)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.submit-location-btn {
  height: 3rem !important;
  width: 14rem;
  font-size: 1.2rem;
  min-height: 0 !important;
  min-width: 0 !important;
  font-weight: 700;
  letter-spacing: 1.5px;
  position: absolute;
  bottom: 2rem;

  &::v-deep {
    .v-btn__content {
      margin-top: 0.2rem;
    }
  }
}

.location-dialog {
  width: 100%;
}

.location-dialog-container {
  height: 69.1rem;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  .dialog-title-container {
    border-bottom: 1px solid var(--mid-light-grey);
    height: 6.2rem;
    padding: 1.6rem 2.5rem 0.8rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
      font-weight: 900;
      font-size: 2.9rem;
      margin-right: 3rem;
      word-break: break-word;
      line-height: 2.9rem;
    }

    .close-icon {
      width: 2rem;
    }
  }

  .dialog-content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .address-container {
    height: 60%;
    padding: 1.6rem 2.5rem 0.8rem 2.5rem;
    border-bottom: 1px solid var(--mid-light-grey);
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .location-header {
    font-weight: 400;
    color: var(--navy) !important;
  }

  .lat-lng-container {
    height: 100%;
    padding: 1.6rem 2.5rem 0.8rem 2.5rem;
    border-bottom: 1px solid var(--mid-light-grey);
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
