<template>
  <div class="switch-container">
    <div class="icon left" @click="handleIconClick" @keydown.enter="handleIconClick"><GalleryIcon /></div>
    <v-switch
      v-model="mapView"
      inset
      class="switch"
      :class="mapView ? 'on' : 'off'"
      :ripple="false"
      @click="handleClick"
    ></v-switch>
    <div class="icon right" style="transform: rotate(-90deg)" @click="handleIconClick" @keydown.enter="handleIconClick">
      <MapViewIcon />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import GalleryIcon from "@/components/common_icons/GalleryIcon.vue"
import MapViewIcon from "@/components/common_icons/MapViewIcon.vue"

export default Vue.extend({
  components: {
    GalleryIcon,
    MapViewIcon,
  },
  props: {
    shareSwitch: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      mapView: true,
    }
  },
  created() {
    // if the route is on the ProjectPhotos map, the mapView prop should be true; if not, false.
    if (!this.shareSwitch) {
      this.mapView = this.$route.name === "NoDeptProjectPhotoDetailMap"
    } else {
      this.mapView = this.$route.name === "ShareNoDeptProjectPhotoDetailMap"
    }
  },
  methods: {
    handleClick() {
      if (!this.mapView && !this.shareSwitch) {
        this.$router.push({
          name: "NoDeptProjectPhotoDetailGallery",
          params: {
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
            categoryId: this.$route.params.categoryId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      } else if (!this.mapView && this.shareSwitch) {
        this.$router.push({
          name: "ShareNoDeptProjectPhotoDetailGallery",
          params: {
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      } else if (this.mapView && !this.shareSwitch) {
        this.$router.push({
          name: "NoDeptProjectPhotoDetailMap",
          params: {
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
            categoryId: this.$route.params.categoryId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      } else if (this.mapView && this.shareSwitch) {
        this.$router.push({
          name: "ShareNoDeptProjectPhotoDetailMap",
          params: {
            companyId: this.companyId,
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      }
    },
    handleIconClick() {
      this.mapView = !this.mapView
      this.handleClick()
    },
  },
})
</script>
<style lang="scss" scoped>
.icon {
  position: absolute;
  top: 1.1rem;
  height: 1.7rem;
  width: 1.7rem;
  padding: 0;
  z-index: 1;
}
.left {
  left: 17%;
}
.right {
  right: 17%;
}

.switch {
  position: relative;
  margin-top: 0;

  &::v-deep {
    .v-input--switch--inset,
    .v-input--selection-controls__input {
      width: 100%;
    }
    .v-input--switch__track {
      width: 100% !important;
      height: 3.4rem;
      left: 0px;
      color: var(--light-grey) !important;
      opacity: 1;
    }
    .v-input--switch__thumb {
      height: 27px;
      width: 38px;
      top: calc(50% - 11px);
      border-radius: 12px;
      left: 4px;
      z-index: 2;
      opacity: 0.5;
    }
  }
}

.on::v-deep {
  .v-input--switch__thumb {
    //
    left: 1.9rem;
    color: white !important;
  }
  .v-input--switch__track {
    color: var(--light-grey) !important;
    opacity: 1;
  }
}

.switch-container {
  width: 8.2rem;
  height: 3.4rem;
  position: relative;
}
</style>
