<template>
  <div class="engie-search-input">
    <v-text-field
      :label="`Search ${label}`"
      :value="value"
      solo
      flat
      dense
      single-line
      hide-details
      @input="handleInput($event)"
    >
      <template #prepend><SearchIcon class="search-icon" /></template>
      <template #append>
        <transition name="clear-button">
          <v-btn v-if="showClearButton" class="close-button" small icon @click="clear()"
            ><CloseIcon class="close-icon"
          /></v-btn>
        </transition>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import SearchIcon from "../common_icons/SearchIcon.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"

export default Vue.extend({
  components: {
    SearchIcon,
    CloseIcon,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    showClearButton(): boolean {
      return this.value.length > 0
    },
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    clear() {
      this.$emit("input", "")
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-search-input {
  &::v-deep .v-text-field {
    color: var(--grey);
    border: 1px solid var(--grey);

    .v-input__slot {
      padding-left: 0 !important;
    }

    label {
      color: var(--grey);
    }
  }

  svg.search-icon {
    width: 2rem;
    margin-left: 1rem;
    position: relative;
    top: 0.3rem;
    transition: color 400ms ease;
  }

  &::v-deep .close-button {
    position: relative;
    left: 0.5rem;

    svg.close-icon {
      width: 1.3rem;
      transition: color 400ms ease;
    }
  }
}
.clear-button-enter-active,
.clear-button-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.clear-button-enter,
.clear-button-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
