<template>
  <div class="workspaces-content">
    <div v-if="loading" class="loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <v-menu v-else-if="numberOfExternalWorkspaces !== 0 && !loading" class="d-flex workspaces-menu" top offset-y>
      <template #activator="{ on, attrs }">
        <v-btn class="workspace-btn elevation-0" depressed v-bind="attrs" v-on="on">
          <v-avatar class="workspace-avatar" :color="selectedWorkspace.color">
            <span class="workspace-avatar-text text-truncate" :style="`color: ${selectedWorkspace.textColor}`">
              {{ selectedWorkspace.name.charAt(0).toUpperCase() }}
            </span>
          </v-avatar>
          <span class="workspace-name text-truncate">
            {{ selectedWorkspace.name }}
          </span>
          <v-icon style="font-size: 24px">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="workspaces-options-menu" outlined>
        <v-list-item
          v-for="workspace in allWorkspaces"
          :key="workspace.id"
          class="workspaces-options"
          mandatory
          @click="handleSelectWorkspace(workspace)"
        >
          <v-avatar class="workspace-options-avatar" :color="workspace.color">
            <span class="workspace-options-avatar-text text-truncate" :style="`color: ${workspace.textColor}`">
              {{ workspace.name.charAt(0).toUpperCase() }}
            </span>
          </v-avatar>
          <span class="workspace-options-name text-truncate">
            {{ workspace.name }}
          </span>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else class="single-workspace">
      <v-avatar class="workspace-avatar" :color="selectedWorkspace.color">
        <span class="workspace-avatar-text text-truncate" :style="`color: ${selectedWorkspace.textColor}`">
          {{ selectedWorkspace.name.charAt(0).toUpperCase() }}
        </span>
      </v-avatar>
      <span class="workspace-name text-truncate">
        {{ selectedWorkspace.name }}
      </span>
    </div>
  </div>
</template>

<script>
import { randomGeneratedColor } from "@/util/workspaceUtils"

export default {
  props: {
    userWorkspaces: {
      type: Object,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    selectedWorkspace: null,
    homeWorkspace: null,
  }),
  computed: {
    numberOfExternalWorkspaces() {
      if (this.userWorkspaces.externalWorkspaces) {
        return this.userWorkspaces.externalWorkspaces.length
      }
      return 0
    },
    allWorkspaces() {
      const currentWorkspace = JSON.parse(localStorage.getItem(`currentWorkspace[${this.userId}]`))
      const updatedExternalWorkspaces = this.userWorkspaces.externalWorkspaces.map(workspace => {
        if (currentWorkspace !== null) {
          if (workspace.companyId === currentWorkspace.companyId) {
            return currentWorkspace
          }
          const color = randomGeneratedColor(workspace.name)
          const textColor = "#ffffff"
          return { ...workspace, color, textColor }
        }
        const color = randomGeneratedColor(workspace.name)
        const textColor = "#ffffff"
        return { ...workspace, color, textColor }
      })
      if (this.userWorkspaces !== null && this.homeWorkspace !== null) {
        return [...updatedExternalWorkspaces, this.homeWorkspace]
      }
      return null
    },
  },
  mounted() {
    this.setSelectedToUserHomeWorkspace()
  },
  methods: {
    setSelectedToUserHomeWorkspace() {
      const currentWorkspace = JSON.parse(localStorage.getItem(`currentWorkspace[${this.userId}]`))
      if (currentWorkspace !== null) {
        if (this.userWorkspaces.homeWorkspace.companyId === currentWorkspace.companyId) {
          this.homeWorkspace = { ...currentWorkspace }
          this.selectedWorkspace = currentWorkspace
          this.loading = false
        } else {
          const color = randomGeneratedColor(this.userWorkspaces.homeWorkspace.name)
          this.homeWorkspace = { ...this.userWorkspaces.homeWorkspace, color, textColor: "#ffffff" }
          this.selectedWorkspace = currentWorkspace
          this.loading = false
        }
      } else {
        const color = randomGeneratedColor(this.userWorkspaces.homeWorkspace.name)
        this.homeWorkspace = { ...this.userWorkspaces.homeWorkspace, color, textColor: "#ffffff" }
        this.selectedWorkspace = this.homeWorkspace
        localStorage.setItem(`currentWorkspace[${this.userId}]`, JSON.stringify(this.selectedWorkspace))
        this.loading = false
      }
    },

    // randomGeneratedColor(stringInput) {
    //   const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    //     // eslint-disable-next-line no-bitwise
    //     return char.charCodeAt(0) + ((acc << 5) - acc)
    //   }, 0)
    //   return `hsl(${stringUniqueHash % 360}, 95%, 35%)`
    // },

    handleSelectWorkspace(workspace) {
      this.selectedWorkspace = workspace
      localStorage.setItem(`currentWorkspace[${this.userId}]`, JSON.stringify(this.selectedWorkspace))
      this.$emit("workspace-changed", workspace)
    },
  },
}
</script>

<style lang="scss" scoped>
.workspaces-content {
  height: 100%;
  width: 100%;

  .workspace-btn {
    height: 100%;
    width: 100%;
    display: block;
    background-color: var(--light-grey);
    text-transform: none;
  }
}

.workspace-avatar {
  height: 3.6rem !important;
  min-height: 3.6rem !important;
  width: 3.6rem !important;
  min-width: 3.6rem !important;
  margin-right: 0.8rem;
}

.workspace-avatar-text {
  padding-top: 0.2rem;
  font-size: 1.8rem;
  font-weight: 900;
}

.workspace-name {
  color: var(--navy);
  font-size: 1.8rem;
  font-weight: 800;
}

.workspaces-options {
  height: 100%;
  width: 100%;
}

.workspace-options-avatar {
  height: 2.6rem !important;
  min-height: 2.6rem !important;
  width: 2.6rem !important;
  min-width: 2.6rem !important;
  margin-right: 0.8rem;
}

.workspace-options-avatar-text {
  padding-top: 0.2rem;
  font-size: 1.1rem;
  font-weight: 900;
}

.workspace-options-name {
  color: var(--charcoal);
  font-size: 1.4rem;
  font-weight: 400;
}

.single-workspace {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
  background-color: var(--light-grey);
  letter-spacing: 0.66px;
}

.loading-spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
