import { render, staticRenderFns } from "./ProjectDepartmentPhotos.vue?vue&type=template&id=a15c42f0&scoped=true"
import script from "./ProjectDepartmentPhotos.vue?vue&type=script&lang=js"
export * from "./ProjectDepartmentPhotos.vue?vue&type=script&lang=js"
import style0 from "./ProjectDepartmentPhotos.vue?vue&type=style&index=0&id=a15c42f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a15c42f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VCardTitle,VImg,VProgressCircular})
