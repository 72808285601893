<template>
  <v-data-table
    class="engie-table"
    :class="{ 'freeze-first-column': freezeFirstColumn }"
    :headers="headersToRender"
    :items="items"
    :item-key="itemKey"
    :header-props="{ sortIcon: 'mdi-menu-down' }"
    :loading="loading"
    :no-data-text="noDataText"
    :server-items-length="totalCount"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    @update:page="handlePageNumberUpdated"
    @update:items-per-page="handlePageSizeUpdated"
    @update:sort-desc="handlePageSortDescendingUpdated"
  >
    <template #item.edit="{ item }">
      <v-btn text @click="handleEditClicked(item.id)"><EditIcon /></v-btn>
    </template>
    <!-- This template allows us to pass any slots from parent to child so that instances of EngieTable may customize item or header slots -->
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
  </v-data-table>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { DataTableHeader } from "vuetify"
import EditIcon from "./common_icons/EditIcon.vue"

export default Vue.extend({
  components: {
    EditIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
    },
    freezeFirstColumn: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No results",
    },
    totalCount: {
      type: Number,
      default: null,
    },
    /**
     *  When true, pencil icon buttons are added to each row. These trigger the "edit-row-clicked" event.
     */
    editable: {
      type: Boolean,
      default: null,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headersToRender() {
      return this.editable
        ? [...this.headers, { value: "edit", text: "", sortable: false, align: "center" }]
        : this.headers
    },
  },
  methods: {
    handlePageNumberUpdated(pageNumber: number) {
      this.$emit("page-number-updated", pageNumber)
    },
    handlePageSizeUpdated(pageSize: number) {
      this.$emit("page-size-updated", pageSize)
    },
    handlePageSortDescendingUpdated(sortDescending: boolean[]) {
      this.$emit("page-sort-descending-updated", sortDescending)
    },
    handleEditClicked(itemId: string) {
      this.$emit("edit-row-clicked", itemId)
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-table::v-deep {
  table {
    .v-data-table-header {
      th {
        border-bottom: 1px solid var(--navy) !important;
        font-size: 1.3rem;
        color: var(--navy) !important;
        background: white;

        .v-icon {
          opacity: 1;
          color: var(--navy) !important;
          font-size: 2rem !important;
        }
      }
    }

    tbody {
      tr {
        td {
          background: white;
        }
      }
      tr:nth-child(even) {
        td {
          background-color: var(--light-grey);
        }
      }
    }
  }

  .v-data-footer {
    font-size: 1.2rem !important;

    .v-select__selection {
      font-size: 1.2rem !important;
    }
  }

  &.freeze-first-column {
    table {
      .v-data-table-header {
        th:first-child {
          border-right: 1px solid var(--navy);
          position: sticky;
          left: 0;
        }
      }

      tbody {
        tr > td:first-child {
          border-right: 1px solid var(--navy);
          position: sticky;
          left: 0;
        }
      }
    }
  }

  svg {
    width: 2rem;
  }
}
</style>
