import { render, staticRenderFns } from "./ShareProjectDetails.vue?vue&type=template&id=2254bd0a&scoped=true"
import script from "./ShareProjectDetails.vue?vue&type=script&lang=js"
export * from "./ShareProjectDetails.vue?vue&type=script&lang=js"
import style0 from "./ShareProjectDetails.vue?vue&type=style&index=0&id=2254bd0a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2254bd0a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VDivider,VImg})
