<template>
  <div>
    <v-dialog
      :retain-focus="false"
      :value="dialogOpen"
      max-width="81rem"
      class="assign-dialog"
      :persistent="true"
      @keydown.esc="closeModal()"
    >
      <div class="assign-dialog-container">
        <AssignPhotoStepper :value="stepperIndex" @close="closeModal" @back="handleBackArrowClick">
          <template #item1>
            <v-stepper-step step="" :class="getStepperClass(0)" :complete="stepperIndex >= 1" color="secondary"
              ><span>Projects</span></v-stepper-step
            >
          </template>
          <template #item2>
            <v-stepper-step step="" :class="getStepperClass(1)" :complete="stepperIndex >= 2" color="secondary"
              ><span>Departments</span></v-stepper-step
            >
          </template>
          <template #item3>
            <v-stepper-step step="" :class="getStepperClass(2)" :complete="stepperIndex >= 3" color="secondary"
              ><span>Categories</span></v-stepper-step
            >
          </template>
          <template #item4>
            <v-stepper-step step="" :class="getStepperClass(3)" :complete="stepperIndex >= 4" color="secondary">
              <span>Confirm</span>
            </v-stepper-step>
          </template>

          <template #stepper-content>
            <v-stepper-content id="assign-dialog" :step="0" class="stepper-content-container">
              <EngieSearchInput label="by Project Name or Number" class="search" @input="debouncedHandler" />
              <div v-if="insideDialogLoading" class="loading-container">
                <v-progress-circular indeterminate color="primary" :size="70" class="mt-6"></v-progress-circular>
              </div>
              <AssignPhotoProject v-else :company-projects="companyProjects" @submitted="handleSelectProject" />
            </v-stepper-content>

            <v-stepper-content :step="1" class="stepper-content-container">
              <AssignPhotoDepartments
                :loading="insideDialogLoading"
                :company-departments="companyDepartments"
                :company-id="companyId"
                :selected-project="selectedProject"
                @department-created="handleCreateDepartment"
                @submitted="handleDepartmentSubmitted"
                @no-department-submitted="handleNoDepartmentSubmitted"
                @department-deleted="handleDepartmentDeleted"
              />
            </v-stepper-content>

            <v-stepper-content :step="2" class="stepper-content-container">
              <AssignPhotoCategories
                :loading="insideDialogLoading"
                :selected-departments="selectedDepartmentsWithCategories"
                :company-id="companyId"
                @create-dialog-open="handleCreateCategoryDialogOpen"
                @submitted="handleCategorySubmitted"
                @category-deleted="handleCategoryDeleted"
                @category-created="handleCategoryCreated"
              />
              <CategoryDialog
                :dialog-title="'Create Category'"
                :button-title="'Create Category'"
                :dialog-open="createCategoryDialogOpen"
                :selected-department="newCategoryDepartment"
                :loading="savingCategory"
                @submit="handleCreateCategory"
                @dialog-closed="handleCreateCategoryDialogClose"
              />
            </v-stepper-content>

            <v-stepper-content :step="3" class="stepper-content-container">
              <AssignPhotosConfirmation
                :confirmation-loading="confirmationLoading"
                :selected-project="selectedProject"
                :selected-departments-with-categories="selectedCategories"
                @submitted="handleSubmitPhotoForAssignment()"
              />
            </v-stepper-content>

            <v-stepper-content :step="4" class="stepper-content-container">
              <div v-if="selectedProject" class="d-flex flex-column justify-center align-center confirmed-container">
                <div class="confirmed-content">
                  <v-icon color="secondary" large class="confirmed-check">mdi-check-circle</v-icon>
                  <h1>Photos have been added to {{ selectedProject.name }}</h1>
                  <engie-button
                    color="primary"
                    class="confirmed-proj-btn"
                    @click.native="handleProjectRoute(selectedProject.id)"
                    >Go To Project</engie-button
                  >
                  <engie-button color="var(--mid-light-grey)" class="confirmed-photo-btn" @click="handlePhotoRoute"
                    >Back To Photos</engie-button
                  >
                </div>
              </div>
            </v-stepper-content>
          </template>
        </AssignPhotoStepper>
        <EngieErrorSnackbar
          v-model="errorAssigningPhotosSnackbarOpen"
          text="Error assigning all photos"
          @close="handleErrorAssigningPhotosSnackbarClose"
        />
        <EngieErrorSnackbar
          v-model="createCategoryErrorSnackbarOpen"
          text="An error occured while creating your Category"
          @close="handleCreateCategoryErrorSnackbarClose()"
        />
        <EngieErrorSnackbar
          v-model="createDepartmentErrorSnackbarOpen"
          text="An error occured while creating your Department"
          @close="handleCreateDepartmentErrorSnackbarClose()"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  getAllCompanyDepartmentsGallery,
  getAllCompanyProjects,
  getAllDepartmentCategoriesGallery,
  postCompanyDepartment,
  postDepartmentCategory,
} from "@/services/projectService"
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import { updatePhotoInformation } from "@/services/photoService"
import { getCurrentAuthUserRecord } from "@/services/authService"
import AssignPhotoStepper from "./AssignPhotoStepper.vue"
import EngieSearchInput from "../forms/EngieSearchInput.vue"
import EngieErrorSnackbar from "../EngieErrorSnackbar.vue"
import AssignPhotoDepartments from "./AssignPhotoDepartments.vue"
import AssignPhotoCategories from "./AssignPhotoCategories.vue"
import AssignPhotosConfirmation from "./AssignPhotosConfirmation.vue"
import AssignPhotoProject from "./AssignPhotoProject.vue"
import EngieButton from "../forms/EngieButton.vue"
import CategoryDialog from "../ProjectDashboard/CategoryDialog.vue"

export default {
  components: {
    AssignPhotoStepper,
    EngieSearchInput,
    EngieErrorSnackbar,
    AssignPhotoDepartments,
    AssignPhotoCategories,
    AssignPhotosConfirmation,
    AssignPhotoProject,
    EngieButton,
    CategoryDialog,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    selectedPhotosForAssignment: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    insideDialogLoading: true,
    selectedProject: null,
    selectedDepartments: [],
    selectedCategories: [],
    currentUser: null,
    projectSearchInput: "",
    userId: null,
    companyProjects: [],
    companyDepartments: [],
    selectedDepartmentsWithCategories: [],
    stepperIndex: 0,
    confirmationLoading: true,
    errorAssigningPhotosSnackbarOpen: false,
    createCategoryDialogOpen: false,
    createCategoryErrorSnackbarOpen: false,
    newCategoryDepartment: null,
    createDepartmentErrorSnackbarOpen: false,
    projectsHasNextPage: null,
    projectsPageNumber: 1,
    projectsPageSize: "9",
    observer: null,
    projectGalleryLastItem: null,
    projectSortSelect: { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
    savingCategory: false,
  }),
  watch: {
    dialogOpen(currentValue) {
      if (currentValue === true) {
        this.initialize()
      }
    },
    async stepperIndex(currentValue, previousValue) {
      if (currentValue === 1 && previousValue === 0 && this.selectedProject !== null) {
        this.insideDialogLoading = true
        this.companyDepartments = await this.setDepartments(this.selectedProject.id)
        this.insideDialogLoading = false
      }

      if (currentValue === 2 && previousValue === 1) {
        this.insideDialogLoading = true

        await this.setCategories()

        this.insideDialogLoading = false
      }
    },
  },
  created() {
    this.debouncedHandler = debounce(event => {
      this.insideDialogLoading = true
      this.searchProjects(event)
    }, DEBOUNCE_INTERVAL)
  },
  methods: {
    async closeModal() {
      this.resetAll()
      this.confirmationLoading = true
      this.$emit("close")
    },

    handleBackArrowClick() {
      if (this.stepperIndex !== 0) {
        this.stepperIndex -= 1
      }
    },

    async fetchAllCompanyProjects(
      companyId,
      userId,
      sortByStatus,
      sortByField,
      sortByDirection,
      search,
      pageSize,
      pageNumber
    ) {
      const response = await getAllCompanyProjects(
        companyId,
        userId,
        sortByStatus,
        sortByField,
        sortByDirection,
        search,
        pageSize,
        pageNumber
      )
      return response
    },
    handleDepartmentDeleted(departmentId) {
      this.companyDepartments = this.companyDepartments.filter(department => department.id !== departmentId)
    },
    async setProjects() {
      try {
        if (!this.isExternalUser) {
          if (this.observer !== null) {
            this.observer.unobserve(this.projectGalleryLastItem)
            this.insideDialogLoading = true
            this.companyProjects = []
            this.projectsHasNextPage = null
            this.projectsPageNumber = 1
          }
          const projectsRequest = await this.fetchAllCompanyProjects(
            this.$props.companyId,
            "",
            "",
            this.projectSortSelect.fieldValue,
            this.projectSortSelect.directionValue,
            this.projectSearchInput,
            this.projectsPageSize,
            this.projectsPageNumber
          )
          this.companyProjects = projectsRequest.items
          this.projectsHasNextPage = projectsRequest.hasNextPage
          this.loading = false
          this.insideDialogLoading = false
        } else {
          const user = await getCurrentAuthUserRecord()
          this.userId = user.id
          const projectsRequest = await this.fetchAllCompanyProjects(
            this.$props.companyId,
            this.userId,
            "",
            this.projectSortSelect.fieldValue,
            this.projectSortSelect.directionValue,
            this.projectSearchInput,
            this.projectsPageSize,
            this.projectsPageNumber
          )
          this.companyProjects = projectsRequest.items
          this.projectsHasNextPage = projectsRequest.hasNextPage
          this.loading = false
          this.insideDialogLoading = false
        }
      } catch (error) {
        this.loading = false
        throw new Error(`error: ${error}`)
      }
    },

    async addMoreProjects() {
      this.observer.unobserve(this.projectGalleryLastItem)
      this.projectsPageNumber += 1
      try {
        if (!this.isExternalUser) {
          const addedProjectsRequest = await this.fetchAllCompanyProjects(
            this.$props.companyId,
            "",
            "",
            this.projectSortSelect.fieldValue,
            this.projectSortSelect.directionValue,
            this.projectSearchInput,
            this.projectsPageSize,
            this.projectsPageNumber
          )
          if (addedProjectsRequest.hasNextPage === false) {
            this.projectHasNextPage = false
            const newUpdatedProjects = addedProjectsRequest.items
            return [...this.companyProjects, ...newUpdatedProjects]
          }
          const newUpdatedProjects = addedProjectsRequest.items
          this.initObserver()
          return [...this.companyProjects, ...newUpdatedProjects]
        }
        const addedProjectsRequest = await this.fetchAllCompanyProjects(
          this.$props.companyId,
          this.userId,
          "",
          this.projectSortSelect.fieldValue,
          this.projectSortSelect.directionValue,
          this.projectSearchInput,
          this.projectsPageSize,
          this.projectsPageNumber
        )
        if (addedProjectsRequest.hasNextPage === false) {
          this.projectHasNextPage = false
          const newUpdatedProjects = addedProjectsRequest.items
          return [...this.companyProjects, ...newUpdatedProjects]
        }
        const newUpdatedProjects = addedProjectsRequest.items
        this.initObserver()
        return [...this.companyProjects, ...newUpdatedProjects]
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    initObserver() {
      const contentContainer = document.querySelector("#assign-dialog")
      if (this.loading === false) {
        this.projectGalleryLastItem = document.querySelector(".last-assign-project")
      }

      const options = {
        root: contentContainer,
        threshold: [...Array(10)].map((el, index) => 0.1 * index),
      }

      const handleIntersectionEvent = async entry => {
        if (entry[0].intersectionRatio > 0.9) {
          const projects = await this.addMoreProjects()
          this.companyProjects = projects
        }
      }

      this.observer = new IntersectionObserver(handleIntersectionEvent, options)
      this.observer.observe(this.projectGalleryLastItem)
    },

    async fetchCompanyDepartments(companyId, projectId) {
      const response = await getAllCompanyDepartmentsGallery(companyId, projectId)
      return response
    },

    async setDepartments(projectId) {
      try {
        const response = await this.fetchCompanyDepartments(this.companyId, projectId)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async fetchCompanyDepartmentCategories(companyId, projectId, departmentId) {
      const response = await getAllDepartmentCategoriesGallery(companyId, projectId, departmentId)
      return response
    },

    findEachCategoryInsideDepartment(departmentsArray) {
      const promises = departmentsArray.map(async department => {
        const categories = await this.fetchCompanyDepartmentCategories(
          this.companyId,
          this.selectedProject.id,
          department.id
        )
        return { ...department, categories }
      })
      return Promise.all(promises)
    },

    async setCategories() {
      try {
        this.selectedDepartmentsWithCategories = await this.findEachCategoryInsideDepartment(this.selectedDepartments)
        this.insideDialogLoading = false
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async initialize() {
      await this.setProjects()
      this.initObserver()
    },

    async handleSelectProject(project) {
      this.selectedProject = project
      this.stepperIndex += 1
      this.confirmationLoading = false
    },

    async handleDepartmentSubmitted(value) {
      this.stepperIndex += 1
      this.selectedDepartments = value
    },

    async handleCategorySubmitted(value) {
      this.selectedCategories = value
      this.stepperIndex += 1
    },

    async searchProjects(value) {
      this.projectSearchInput = value
      await this.setProjects()
    },

    resetAll() {
      this.selectedProject = null
      this.projectsHasNextPage = null
      this.projectsPageNumber = 1
      this.selectedDepartments = []
      this.companyDepartments = []
      this.selectedDepartmentsWithCategories = []
      this.selectedCategories = []
      this.stepperIndex = 0
    },

    async handleSubmitPhotoForAssignment() {
      this.confirmationLoading = true
      try {
        const photoIds = this.selectedPhotosForAssignment.map(photo => photo.id)
        await this.sendRequestForEachPhotoId(photoIds)
        this.$emit("photo-updated")
        this.initialize()
        this.stepperIndex += 1
      } catch (error) {
        this.confirmationLoading = false
        this.errorAssigningPhotosSnackbarOpen = true
        throw new Error(`Error: ${error}`)
      }
    },

    groupCategoriesByDepartmentId(categoriesArray, departmentId) {
      const response = categoriesArray.reduce((acc, category) => {
        if (category.id !== null) {
          const index = acc.findIndex(selectedCategory => {
            return selectedCategory.id === departmentId
          })
          if (index === -1) {
            acc.push({ id: departmentId, categoryIds: [category.id] })
            return acc
          }
          acc[index].categoryIds.push(category.id)
          return acc
        }
        const index = acc.findIndex(selectedCategory => {
          return selectedCategory.id === departmentId
        })
        if (index === -1) {
          acc.push({ id: departmentId })
          return acc
        }
        return acc
      }, [])
      return response[0]
    },

    createPhotoDataForSubmission() {
      const departmentIdsArray = this.selectedCategories.map(department => {
        return this.groupCategoriesByDepartmentId(department.categories, department.id)
      })
      return { projectId: this.selectedProject.id, departments: departmentIdsArray }
    },

    sendRequestForEachPhotoId(photoIdsArray, noDeptPhotoData) {
      // if photo has department and category
      if (!noDeptPhotoData) {
        const photoData = this.createPhotoDataForSubmission()
        const promises = photoIdsArray.map(async photoId => {
          const response = await updatePhotoInformation(this.companyId, photoId, photoData)
          return response
        })
        return Promise.all(promises)
      }
      // if no dept photo data
      const promises = photoIdsArray.map(async photoId => {
        const response = await updatePhotoInformation(this.companyId, photoId, noDeptPhotoData)
        return response
      })
      return Promise.all(promises)
    },

    async handleNoDepartmentSubmitted() {
      this.confirmationLoading = true
      const photoIds = this.selectedPhotosForAssignment.map(photo => photo.id)
      const photoData = { projectId: this.selectedProject.id }
      try {
        await this.sendRequestForEachPhotoId(photoIds, photoData)
        this.$emit("photo-updated")
        this.initialize()
        this.stepperIndex = 4
      } catch (error) {
        this.confirmationLoading = false
        this.errorAssigningPhotosSnackbarOpen = true
        throw new Error(`Error: ${error}`)
      }
    },

    handleErrorAssigningPhotosSnackbarClose() {
      this.errorAssigningPhotosSnackbarOpen = false
    },

    handleProjectRoute(value) {
      this.$router.push(`/projects/${value}/photos`)
    },

    handlePhotoRoute() {
      this.closeModal()
    },

    handleCreateCategoryDialogOpen(value) {
      this.newCategoryDepartment = value
      this.createCategoryDialogOpen = true
    },

    handleCreateCategoryDialogClose() {
      this.newCategoryDepartment = null
      this.createCategoryDialogOpen = false
    },

    async createProjectDepartmentCategory(companyId, departmentId, categoryData) {
      const response = await postDepartmentCategory(companyId, departmentId, categoryData)
      return response
    },
    async handleCreateCategory(value) {
      this.insideDialogLoading = true
      try {
        this.savingCategory = true
        await this.createProjectDepartmentCategory(this.companyId, this.newCategoryDepartment.id, value)
        await this.setCategories()

        this.$emit("category-created", value)
        this.savingCategory = false
        this.insideDialogLoading = false
        this.handleCreateCategoryDialogClose()
      } catch (error) {
        this.savingCategory = false
        this.createCategoryErrorSnackbarOpen = true
        this.insideDialogLoading = false
        this.handleCreateCategoryDialogClose()
      }
    },

    handleCreateCategoryErrorSnackbarClose() {
      this.createCategoryErrorSnackbarOpen = false
    },

    async createProjectDepartment(companyId, departmentData) {
      const response = await postCompanyDepartment(companyId, departmentData)
      return response
    },

    async handleCreateDepartment(departmentData) {
      this.insideDialogLoading = true
      try {
        const response = await this.createProjectDepartment(this.companyId, departmentData)
        this.$emit("department-created", response)
        this.companyDepartments = await this.setDepartments(this.selectedProject.id)
        this.insideDialogLoading = false
      } catch (error) {
        this.createDepartmentErrorSnackbarOpen = true
        this.insideDialogLoading = false
      }
    },

    handleCreateDepartmentErrorSnackbarClose() {
      this.createDepartmentErrorSnackbarOpen = false
    },

    getStepperClass(activeIndex) {
      return this.stepperIndex === activeIndex ? "active-step" : ""
    },

    handleCategoryDeleted(event) {
      const { departmentId, categoryId } = event

      const departmentIndex = this.selectedDepartmentsWithCategories.findIndex(
        department => department.id === departmentId
      )

      const departmentWithoutCategory = { ...this.selectedDepartmentsWithCategories[departmentIndex] }

      departmentWithoutCategory.categories = departmentWithoutCategory.categories.filter(
        category => category.id !== categoryId
      )

      this.selectedDepartmentsWithCategories = [
        ...this.selectedDepartmentsWithCategories.slice(0, departmentIndex),
        departmentWithoutCategory,
        ...this.selectedDepartmentsWithCategories.slice(departmentIndex + 1),
      ]
    },
    handleCategoryCreated(category) {
      this.$emit("category-created", category)
    },
  },
}
</script>

<style lang="scss" scoped>
.assign-dialog {
  width: 100%;
}

.assign-dialog-container {
  height: 69.1rem;
  width: 100%;
  background-color: white;
}

.stepper-content-container {
  height: 100%;
  background-color: white;
}

.confirmed-container {
  height: 100%;
  width: 100%;
  padding-top: 10rem;

  .confirmed-content {
    width: 30rem;
    height: 100%;

    h1 {
      padding: 0 7rem;
      font-size: 2rem;
      letter-spacing: 0.3px;
      line-height: 2.6rem;
      text-align: center;
      color: var(--navy);
      font-weight: 600;
    }

    .confirmed-check {
      height: 6.6rem;
      width: 100%;
      font-size: 6.6rem !important;
      margin: 0 auto 2.2rem auto;
    }

    .confirmed-proj-btn {
      width: 100% !important;
      margin: 3.5rem 0 2rem 0;
      &::v-deep {
        .v-btn {
          width: 100% !important;
        }
      }
    }

    .confirmed-photo-btn {
      width: 100% !important;
      &::v-deep {
        .v-btn {
          color: white;
          width: 100% !important;
        }
      }
    }
  }
}

.active-step {
  &::v-deep {
    .v-stepper__step__step {
      border: 3px solid var(--navy) !important;
      background-color: white !important;
    }
    .v-stepper__label {
      color: var(--navy) !important;
      font-weight: 600;
      font-size: 1.2rem;
      letter-spacing: 0.3px;
    }
  }
}

::v-deep {
  .v-stepper__step__step {
    border: 3px solid var(--mid-light-grey) !important;
    background-color: white !important;
  }
  .v-stepper__step--complete {
    .v-stepper__label {
      color: var(--navy) !important;
      font-weight: 600;
      font-size: 1.2rem;
      letter-spacing: 0.3px;
    }
  }
  .v-stepper__label {
    color: var(--mid-light-grey) !important;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.3px;
  }
}

.loading-container {
  height: 100%;
  min-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
