<template>
  <div class="photo-card-container" @click="handleClick" @keydown.enter="handleClick">
    <v-progress-circular v-if="!loaded" class="spinner" indeterminate color="primary" :size="40"></v-progress-circular>
    <v-img :src="photo.thumbnailUrl" class="image" max-width="130" max-height="130" @load="handleLoad"></v-img>
  </div>
</template>

<script>
export default {
  props: {
    photo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  methods: {
    handleClick() {
      this.$emit("click", this.photo)
    },
    handleLoad() {
      this.loaded = true
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-grey);
  width: 13rem;
  height: 13rem;

  .image {
    width: 13rem;
    height: 13rem;
  }
  .spinner {
    position: absolute;
    z-index: 1;
  }
}
</style>
