<template>
  <v-card v-if="user" flat tile class="d-flex card">
    <v-list class="d-flex list">
      <v-list-item-avatar v-if="user.profilePictureUrl" style="margin: 0 2.2rem 0.8rem 0 !important">
        <v-img :src="user.profilePictureUrl" alt="avatar"></v-img>
      </v-list-item-avatar>
      <!-- <v-list-item-avatar v-else-if="user.profilePictureUrl" style="margin: 0 2.2rem 0.8rem 0 !important">
        <v-img :src="user.profilePictureUrl" alt="avatar"></v-img>
      </v-list-item-avatar> -->
      <v-list-item-avatar
        v-else
        class="empty-avatar"
        style="margin: 0 2.2rem 0.8rem 0 !important; background-color: var(--lightest-grey)"
      ></v-list-item-avatar>
      <v-list-item-content style="padding: 0">
        <v-list-item-title v-if="user.name" class="title">
          <h3>{{ user.name }}</h3>
        </v-list-item-title>
        <v-list-item-title v-else class="title">
          <h3>{{ user.displayName }}</h3>
        </v-list-item-title>
        <v-list-item-subtitle v-if="user.jobTitle" class="subtitle">{{ user.jobTitle }}</v-list-item-subtitle>
        <v-list-item-subtitle v-else class="subtitle">{{ user.email }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-card-actions v-if="!currentUser" style="align-items: start">
        <slot name="card-action-btn"></slot>
        <!-- <v-btn color="primary" depressed fab class="exit-btn"><EngiePlus /></v-btn> -->
      </v-card-actions>
    </v-list>
  </v-card>

  <v-card v-else flat tile class="d-flex card" style="padding-top: 0.5rem">
    <v-list class="d-flex list">
      <v-card-actions style="align-items: start">
        <v-btn color="secondary" depressed fab class="add-btn" @click="handleClick($event)"><EngiePlus /></v-btn>
      </v-card-actions>
      <v-list-item-content style="padding: 0">
        <v-list-item-title class="title" style="padding-top: 0.25rem">
          <h3>{{ title }}</h3>
        </v-list-item-title>
        <v-list-item-subtitle class="subtitle">{{ subtitle }}</v-list-item-subtitle>
      </v-list-item-content>
      <div style="width: 4.4rem; padding: 8px"></div>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue"
import EngiePlus from "./common_icons/PlusIcon.vue"

export default Vue.extend({
  components: { EngiePlus },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    currentUser: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick(event: Event) {
      this.$emit("add-team-click", event)
    },
  },
})
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 400;
  color: var(--navy);
}

::v-deep {
  .a {
    fill: white !important;
    stroke: white;
    stroke-width: 2px;
  }
}

.exit-btn {
  height: 2.7rem;
  width: 2.7rem;
  transform: rotate(45deg);
  padding: 5px !important;
}

.add-btn {
  padding: 4px !important;
  margin-right: 1.2rem;
  background-color: var(--navy);
  height: 2.3rem;
  width: 2.3rem;
  min-width: 2.3rem;
}

.title {
  padding-top: 2px;
  margin-bottom: 0px !important;
}

.subtitle {
  color: rgba(43, 43, 43, 0.5) !important;
  font-size: 12px;
  line-height: 15px;
}

.list {
  border-bottom: 0.25px solid var(--navy);
  width: 100%;
  padding: 0 !important;
}

.card {
  margin-top: 25.5px;
  // margin-right: 44px;
  height: 6.3rem;
  width: 100%;
  // min-width: 30.3rem;
}

.v-list-item__content {
  display: inline-block !important;
  height: 80%;
}
</style>
