<template>
  <div class="photo-uploader">
    <div
      class="drag-and-drop-container"
      :class="{ 'drag-over': dragOver }"
      @drop.prevent="handleImagesDropped"
      @dragover.prevent
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
    >
      <div class="information">
        <CloudIcon class="cloud-icon" />
        <p>Drag and drop to upload your photos</p>
        <div class="progress-info-container">
          <transition name="uploading-progress" mode="out-in">
            <v-progress-linear
              v-if="uploadingFiles"
              key="progress-indicator"
              v-model="percentageLoaded"
              color="primary"
              stream
              buffer-value="0"
            ></v-progress-linear>
            <div
              v-else-if="showUploadSuccessMessage && !improperFileUploaded"
              key="success"
              class="upload-result-message"
            >
              <p>
                <span v-if="!errorOccurred" class="check-icon"><CheckIcon /></span>{{ uploadSuccessMessage }}
              </p>
              <p v-if="errorOccurred">The following files failed to upload: {{ formattedErroredFileNames }}</p>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <label class="upload-label" for="photo-upload">
      <input
        id="photo-upload"
        ref="fileUpload"
        type="file"
        accept="image/png, image/jpeg"
        multiple
        @input="handlePhotosUploaded"
      />
      <EngieButton class="upload-button" :disabled="uploadingFiles" @click="handleUploadPhotosClicked">
        Upload Photo(s)
      </EngieButton>
    </label>
  </div>
</template>

<script lang="ts">
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import Vue from "vue"
import { pluralize } from "@/util/pluralize"
import CloudIcon from "./common_icons/CloudIcon.vue"
import CheckIcon from "./common_icons/CheckIcon.vue"
import EngieButton from "./forms/EngieButton.vue"

const ACCEPTABLE_MIME_TYPES = ["image/png", "image/jpeg"]

export default Vue.extend({
  components: {
    CloudIcon,
    EngieButton,
    CheckIcon,
  },
  props: {
    photoUploadUrl: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragOver: false,
      uploadingFiles: false,
      numberOfFilesProcessed: 0,
      totalNumberOfFilesUploading: 0,
      totalSuccessfulUploads: 0,
      showUploadSuccessMessage: false,
      improperFileUploaded: true,
      errorOccurred: false,
      errorFileNames: [] as string[],
    }
  },
  computed: {
    percentageLoaded(): number {
      return Math.max(1, (this.numberOfFilesProcessed / this.totalNumberOfFilesUploading) * 100)
    },
    formattedErroredFileNames(): string {
      return this.errorFileNames.join(", ")
    },
    uploadSuccessMessage(): string {
      return `${this.totalSuccessfulUploads} image${pluralize(this.totalSuccessfulUploads)} uploaded successfully`
    },
  },
  watch: {
    open(currentValue, previousValue) {
      if (currentValue === true && previousValue === false) {
        this.showUploadSuccessMessage = false
      }
    },
  },
  methods: {
    async handleImagesDropped(event: DragEvent) {
      this.dragOver = false
      const dataTransferItems = event.dataTransfer?.items

      if (dataTransferItems && !this.uploadingFiles) {
        const dataTransferItemsArray = Array.from(dataTransferItems)

        const dataTransferFiles = dataTransferItemsArray
          .map(item => item.getAsFile())
          .filter(item => item !== null) as File[]

        await this.uploadFiles(dataTransferFiles)
      }
    },
    resetFileInfo() {
      this.numberOfFilesProcessed = 0
      this.totalSuccessfulUploads = 0
      this.errorFileNames = []
    },
    async uploadFiles(files: File[]) {
      this.resetFileInfo()

      this.uploadingFiles = true
      this.totalNumberOfFilesUploading = files.length

      const fileUploadRequsts = files.map(item => this.uploadFileIfProperType(item!))

      await Promise.all(fileUploadRequsts)

      setTimeout(() => this.handleUploadCompleted(), 500)
    },
    handleUploadCompleted() {
      this.showUploadSuccessMessage = false
      this.uploadingFiles = false
      this.showUploadSuccessMessage = true
    },
    async uploadFileIfProperType(file: File) {
      this.improperFileUploaded = false
      this.errorOccurred = false

      if (!ACCEPTABLE_MIME_TYPES.includes(file.type)) {
        this.$emit("improper-file-uploaded")
        this.improperFileUploaded = true

        return Promise.resolve()
      }

      return this.uploadFile(file)
    },
    getFileFormData(file: File) {
      const formData = new FormData()

      formData.append("photo", file, file?.name)

      return formData
    },
    async uploadFile(file: File) {
      try {
        await makeAuthenticatedRequest(this.photoUploadUrl, "POST", this.getFileFormData(file))

        this.$emit("file-uploaded", file)

        this.totalSuccessfulUploads += 1
      } catch (error) {
        this.errorOccurred = true
        this.errorFileNames = [...this.errorFileNames, file.name]
      }

      this.numberOfFilesProcessed += 1
    },
    handleDragEnter() {
      if (!this.uploadingFiles) {
        this.dragOver = true
      }
    },
    handleDragLeave() {
      this.dragOver = false
    },
    handleUploadPhotosClicked() {
      const fileUploadRef = this.$refs.fileUpload as HTMLElement

      fileUploadRef.click()
    },
    async handlePhotosUploaded(event: InputEvent) {
      const fileInputElement = event.target as HTMLInputElement
      const fileInputList = fileInputElement?.files

      if (fileInputList) {
        await this.uploadFiles(Array.from(fileInputList))
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.photo-uploader {
  width: 60rem;
  display: flex;
  flex-direction: column;

  .drag-and-drop-container {
    height: 40rem;
    background: var(--light-grey);
    border-radius: 1rem;
    color: var(--navy);
    transition: filter 400ms ease;

    &,
    .information {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .information {
      width: 100%;
      pointer-events: none;
      transition: transform 400ms ease;

      .cloud-icon {
        margin: 8rem 0 2rem 0;
      }

      .progress-info-container {
        width: 100%;
        min-height: 8rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        .v-progress-linear {
          width: 40rem;
        }

        .upload-result-message {
          font-size: 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          transition: opacity 400ms ease, transform 400ms ease;

          p {
            font-size: 1.5rem;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
          }

          .check-icon {
            margin-right: 0.5rem;

            svg {
              width: 2rem;
              color: var(--green);
            }
          }
        }
      }
    }

    svg {
      width: 8rem;
    }

    p {
      font-size: 2.4rem;
      width: 25rem;
      text-align: center;
      cursor: default;
    }

    &.drag-over {
      filter: brightness(1.03);
      border: 0.3rem dashed var(--grey);

      .information {
        transform: scale(1.2);

        .upload-result-message {
          opacity: 0;
        }
      }
    }
  }

  .upload-label {
    margin-top: 2rem;
    display: flex;

    input {
      display: none;
    }

    .upload-button {
      margin-left: auto;
    }
  }
}

.uploading-progress-enter-active,
.uploading-progress-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.uploading-progress-enter,
.uploading-progress-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
