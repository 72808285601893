import { PhotoResponse } from "@/types/PhotoResponse"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompanyUrl } from "@/util/urls"

// SORT BY STATUS RESULTS AS QUERY
export const sortByStatusSortDirectionQuery = (sortByField: string, sortDirection: string) =>
  `sortBy=${sortByField}&sortDirection=${sortDirection}`

// SEARCH QUERY
const getNoteSearchQueryParam = (search?: string) => (search ? `&notesSearch=${search}` : "")

// ADD PAGE SIZE
export const getPageSize = (pageSize?: String) => (pageSize ? `&pageSize=${pageSize}` : ``)

// ADD PAGE NUMBER
export const getPageNumber = (pageNumber?: String) => (pageNumber ? `&pageNumber=${pageNumber}` : ``)

// ADD UNASSIGNED OR ASSIGNED
export const getUnassigned = (unassigned?: Boolean) => (unassigned ? `&unassigned=true` : ``)

// ADD UPLOADED BY
export const getUploadedBy = (uploadedBy?: String) => (uploadedBy ? `&uploadedBy=${uploadedBy}` : ``)

// GET ALL COMPANY PHOTOS GALLERY
export const getAllCompanyPhotosGallery = async (
  companyId: string,
  sortByField: string,
  sortDirection: string,
  unassigned: boolean,
  uploadedBy: string,
  pageSize: string,
  pageNumber: string,
  search: string
): Promise<PhotoResponse> => {
  try {
    const response: PhotoResponse = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/photos/gallery?${sortByStatusSortDirectionQuery(
        sortByField,
        sortDirection
      )}${getUnassigned(unassigned)}${getUploadedBy(uploadedBy)}${getPageSize(pageSize)}${getPageNumber(
        pageNumber
      )}${getNoteSearchQueryParam(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL COMPANY SHARED PHOTO COLLECTIONS GALLERY
export const getAllCompanySharedPhotoCollectionsGallery = async (
  companyId: string,
  photoCollectionId: string,
  sortByField: string,
  sortDirection: string,
  pageSize: string,
  pageNumber: string,
  search: string
): Promise<PhotoResponse> => {
  try {
    const response: PhotoResponse = await makeAuthenticatedRequest(
      `${getCompanyUrl(
        companyId
      )}/shared-photo-collections/${photoCollectionId}/gallery?${sortByStatusSortDirectionQuery(
        sortByField,
        sortDirection
      )}${getPageSize(pageSize)}${getPageNumber(pageNumber)}${getNoteSearchQueryParam(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const getAllCompanyPhotosImageInformation = async (
  companyId: string,
  sortByField: string,
  sortDirection: string,
  search: string
): Promise<PhotoResponse> => {
  try {
    const response: PhotoResponse = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/photos/image-information?${sortByStatusSortDirectionQuery(
        sortByField,
        sortDirection
      )}${getNoteSearchQueryParam(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// PATCH A PHOTO TO ADD IT TO A PROJECT
export const updatePhotoInformation = async (companyId: string, photoId: String, photoData: Object) => {
  try {
    const response = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/photos/${photoId}`, "PATCH", photoData)
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET PHOTO VIEWER INFORMATION
export const getPhotoViewerInformation = async (companyId: string, photoId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/photos/${photoId}/viewer-information`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET NUMBER OF UNASSIGNED PHOTOS
export const getUnassignedPhotos = async (companyId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/photos/unassigned-photos-summary`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// DOWNLOAD ZIP FILE OF PHOTO
export const getPhotoZipFile = async (companyId: string, photoIds: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/photos/zip-file?photoIds=${photoIds}`,
      "GET",
      null,
      false,
      "blob"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}
