<template>
  <div class="main-toolbar">
    <router-link to="/"
      ><img class="logo-button" src="../../assets/img/EngieCamLogoBadge.svg" alt="EngieCam Logo" />
    </router-link>
    <v-menu open-on-hover offset-y>
      <template #activator="{ on, attrs }">
        <v-btn :to="accountButtonTo" class="account-button" plain v-bind="attrs" v-on="on">
          <AccountIcon /> Account
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="!isSuperAdmin" :to="{ name: 'AccountSettings' }">Account Settings</v-list-item>
        <v-list-item @click="signOut()"><v-list-item-title>Log out</v-list-item-title></v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import "firebase/auth"
import AccountIcon from "./AccountIcon.vue"
import { isSuperAdmin, logoutAndDeleteDeviceSession } from "../../services/authService"

export default Vue.extend({
  components: {
    AccountIcon,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchQuery: "",
      isSuperAdmin: false,
    }
  },
  computed: {
    accountButtonTo(): null | Object {
      return this.isSuperAdmin ? null : { name: "AccountSettings" }
    },
  },
  mounted() {
    this.checkIsSuperAdmin()
  },
  methods: {
    signOut() {
      this.clearWorkspacesInLocalStorage()
      logoutAndDeleteDeviceSession().then(() => {
        this.$router.push("/login")
      })
    },
    async checkIsSuperAdmin() {
      this.isSuperAdmin = await isSuperAdmin()
    },
    clearWorkspacesInLocalStorage() {
      localStorage.removeItem(`currentWorkspace[${this.userId}]`)
    },
  },
})
</script>

<style lang="scss" scoped>
.main-toolbar {
  min-height: 8rem;
  box-shadow: 0 0.1rem 0.4rem #00000039;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  z-index: var(--z-index-main-search-bar);

  .logo-button {
    width: 16rem;
    position: relative;
    top: 0.1rem;
  }

  .account-button {
    margin-left: auto;
    margin-right: 1rem;
    text-transform: none;
    transition: color 400ms ease;
    color: var(--secondary);

    &:not(.v-btn--active):hover {
      color: var(--secondary);
    }

    svg {
      width: 2.5rem;
      margin-right: 1rem;
    }

    &.v-btn--active {
      color: var(--primary);

      &::before {
        background-color: white !important;
      }
    }
  }

  .search {
    display: flex;
    color: var(--mid-light-grey);
    margin-left: 7rem;
    font-size: 2.4rem;
    position: relative;
    top: -0.2rem;

    svg {
      width: 3.6rem;
      margin-right: 2rem;
    }

    &::v-deep .v-input {
      & .v-input__slot::before,
      & .v-input__slot::after {
        display: none;
      }
      input {
        font-size: 2.4rem;
        color: var(--mid-light-grey);
        position: relative;
        top: 0.5rem;
      }
    }
  }
}
</style>
