<template>
  <div class="engie-button">
    <v-btn
      class="engie-button"
      :loading="loading"
      depressed
      :color="color"
      :disabled="disabled"
      @click="handleClick($event)"
      ><slot></slot
    ></v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: null,
    },
    color: {
      type: String,
      default: "secondary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event: Event) {
      this.$emit("click", event)
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-button::v-deep .v-btn {
  border-radius: 2rem;
  padding: 0 4rem !important;
  min-height: 4.6rem;
  min-width: 16rem !important;
  font-size: 1.6rem;
  letter-spacing: 0.127rem;
  transition: background-color 400ms ease;
}
</style>
