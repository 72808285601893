<template>
  <div class="log-in">
    <img class="engiecam-logo" src="../assets/img/EngieCamLogo.svg" alt="EngieCam logo" />
    <h3 v-if="showTitle">{{ title }}</h3>
    <div class="content-container">
      <slot></slot>
    </div>
    <div class="error-message-container">
      <transition name="error-transition">
        <h4 v-show="showError" class="error-message">{{ errorMessage }}</h4>
      </transition>
    </div>
    <EngieButton
      v-if="showActionButton"
      class="action-btn"
      :loading="performingAction"
      @click="handleActionButtonClicked()"
      >{{ actionButtonText }}</EngieButton
    >
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import EngieButton from "./forms/EngieButton.vue"

export default Vue.extend({
  components: { EngieButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    actionButtonText: {
      type: String,
      required: true,
    },
    performingAction: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showActionButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleActionButtonClicked() {
      this.$emit("action-button-clicked")
    },
  },
})
</script>

<style lang="scss">
.log-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;

  .engiecam-logo {
    width: 21.2rem;
    height: 8.5rem;
    margin-bottom: 3rem;
  }

  h3 {
    margin-bottom: 3rem;
  }

  .content-container {
    width: 40rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .action-btn {
    margin-top: 3rem;
  }

  .error-message-container {
    position: relative;
    margin-top: -1.5rem;
    display: flex;
    width: 100%;

    .error-message {
      width: 100%;
      position: absolute;
      text-align: center;
    }
  }

  .error-transition-enter-active,
  .error-transition-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }
  .error-transition-enter,
  .error-transition-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }
}
</style>
