<template>
  <div class="project-dashboard-container">
    <div v-if="loading" class="project-dashboard-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <Page v-else :title="project.name">
      <template #toolbar>
        <ProjectStatusMenu :status="project.status" :is-user-admin="isUserAdmin" />
      </template>
      <ProjectDashNav
        class="nav"
        :is-user-admin="isUserAdmin"
        :is-external-user="isExternalUser"
        :project-id="project.id"
        :company-id="companyId"
        @share="handleShareDialogOpen"
      />
      <div class="dashboard-body">
        <transition name="route" mode="out-in">
          <router-view
            :is-external-user="isExternalUser"
            :project="project"
            :loading="loading"
            :is-user-admin="isUserAdmin"
            :company-id="companyId"
            :selected-category="selectedCategory"
            :selected-department="selectedDepartment"
            :categories="categories"
            :departments="departments"
            @set-category="handleSetCategory"
            @set-department="handleSetDepartment"
            @department-updated="handleDepartmentUpdated"
            @project-updated="handleProjectUpdated"
            @category-selected="handleCategorySelected"
            @category-updated="handleCategoryUpdated"
            @department-selected="handleDepartmentSelected"
            @set-department-no-category="handleNoCategory"
            @department-created="handleDepartmentCreated"
            @category-created="handleCategoryCreated"
          />
        </transition>
      </div>
      <EngieShareDialog
        :open="shareDialogOpen"
        :item-type="'Project'"
        :project="project"
        :company-id="companyId"
        @share-submitted="handleShareSubmitted"
        @close="handleShareDialogClose"
      />
    </Page>
    <EngieSuccessSnackbar
      v-model="shareProjectSuccessSnackbarOpen"
      text="Your project was shared successfully"
      @close="handleShareProjectSuccessSnackbarClose()"
    />
  </div>
</template>

<script>
import { hasAdminRole } from "@/services/authService"
import {
  getAllCompanyDepartmentsGallery,
  getAllDepartmentCategoriesGallery,
  getOneCompanyProject,
} from "@/services/projectService"
import Vue from "vue"
import EngieShareDialog from "../EngieShareDialog.vue"
import EngieSuccessSnackbar from "../EngieSuccessSnackbar.vue"
import Page from "../Page.vue"
import ProjectDashNav from "./ProjectDashNav.vue"
import ProjectStatusMenu from "./ProjectStatusMenu.vue"

export default Vue.extend({
  components: { ProjectStatusMenu, ProjectDashNav, Page, EngieShareDialog, EngieSuccessSnackbar },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    project: {},
    projectPhotos: [],
    departments: [],
    categories: [],
    selectedCategory: {},
    selectedDepartment: {},
    loading: true,
    isUserAdmin: false,
    shareDialogOpen: false,
    shareProjectSuccessSnackbarOpen: false,
    shareProjectErrorSnackbarOpen: false,
  }),
  watch: {
    companyId(currentValue, previousValue) {
      if (currentValue === previousValue) {
        this.loading = true
        this.initialize()
        return true
      }
      this.$router.push(`/projects`)
      return false
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    handleDepartmentCreated(department) {
      this.departments = [department, ...this.departments]
    },
    handleCategoryCreated(category) {
      this.categories = [category, ...this.categories]
    },
    async checkUserRole() {
      if (this.isExternalUser) {
        return false
      }
      const isUserAdmin = await hasAdminRole()
      return isUserAdmin
    },

    async fetchProjectInformation(companyId, projectId) {
      const response = await getOneCompanyProject(companyId, projectId)
      return response
    },

    async fetchCompanyDepartments(companyId, projectId) {
      const response = await getAllCompanyDepartmentsGallery(companyId, projectId)
      return response
    },

    async fetchCompanyDepartmentCategories(companyId, projectId, departmentId) {
      const response = await getAllDepartmentCategoriesGallery(companyId, projectId, departmentId)
      return response
    },

    async initialize() {
      this.isUserAdmin = await this.checkUserRole()
      this.project = await this.fetchProjectInformation(this.companyId, this.$route.params.projectId)
      this.setProjectIdInRecentProjectsLocalStorage(this.companyId, this.project.id)
      this.departments = await this.fetchCompanyDepartments(this.companyId, this.project.id)
      this.loading = false
    },

    handleProjectUpdated() {
      this.loading = true
      this.$emit("project-updated")
      this.loading = false
    },

    handleDepartmentUpdated() {
      this.loading = true
      this.initialize()
      this.$emit("department-updated")
    },

    handleSetCategory(value) {
      this.selectedCategory = value
    },

    setNoCategory(categoriesArray) {
      const noCategory = categoriesArray.map(category => {
        if (category.id === null) {
          return { ...category, id: null, colorHexValue: "#e2e2e2", name: "No category" }
        }
        return category
      })
      return noCategory
    },

    async handleSetDepartment(value) {
      this.selectedDepartment = value
      this.categories = await this.fetchCompanyDepartmentCategories(this.companyId, this.project.id, value.id)
    },

    async handleDepartmentSelected(value) {
      const department = this.departments.filter(department => department.id === value)
      // eslint-disable-next-line prefer-destructuring
      this.selectedDepartment = department[0]
      const categoriesArray = await this.fetchCompanyDepartmentCategories(this.companyId, this.project.id, value)
      this.categories = this.setNoCategory(categoriesArray)
    },

    async handleCategorySelected(value) {
      this.loading = true
      const department = this.departments.filter(department => department.id === value.departmentId)
      // eslint-disable-next-line prefer-destructuring
      this.selectedDepartment = department[0]
      if (this.categories.length === 0) {
        const allCategories = await this.fetchCompanyDepartmentCategories(
          this.companyId,
          this.project.id,
          value.departmentId
        )
        this.categories = this.setNoCategory(allCategories)
        const category = allCategories.filter(category => category.id === value.categoryId)
        // eslint-disable-next-line prefer-destructuring
        this.selectedCategory = category[0]
      } else {
        const category = this.categories.filter(category => category.id === value.categoryId)
        // eslint-disable-next-line prefer-destructuring
        this.selectedCategory = category[0]
      }
      this.loading = false
    },

    async handleNoCategory(value) {
      this.loading = true
      const department = this.departments.filter(department => department.id === value)
      // eslint-disable-next-line prefer-destructuring
      this.selectedDepartment = department[0]
      const categoriesArray = await this.fetchCompanyDepartmentCategories(this.companyId, this.project.id, value)
      this.categories = this.setNoCategory(categoriesArray)
      this.selectedCategory = { id: null, colorHexValue: "#e2e2e2", name: "No category" }
      this.loading = false
    },

    async handleCategoryUpdated() {
      this.categories = await this.fetchCompanyDepartmentCategories(
        this.companyId,
        this.project.id,
        this.selectedDepartment.id
      )
      this.$emit("category-updated")
    },

    handleShareDialogOpen() {
      this.shareDialogOpen = true
    },

    handleShareDialogClose() {
      this.shareDialogOpen = false
    },
    setProjectIdInRecentProjectsLocalStorage(companyId, projectId) {
      const recentProjectsInStorage = JSON.parse(localStorage.getItem(`recentProjects[${companyId}]`))
      if (recentProjectsInStorage) {
        if (recentProjectsInStorage.length < 8) {
          const recentProjectsWithoutCurrentProject = recentProjectsInStorage.filter(
            recentProjectId => recentProjectId !== projectId
          )
          const updatedRecentProjects = [projectId, ...recentProjectsWithoutCurrentProject]
          localStorage.setItem(`recentProjects[${companyId}]`, JSON.stringify(updatedRecentProjects))
        } else {
          const recentProjectsWithoutCurrentProject = recentProjectsInStorage.filter(
            recentProjectId => recentProjectId !== projectId
          )
          if (recentProjectsWithoutCurrentProject.length < 8) {
            const updatedRecentProjects = [projectId, ...recentProjectsWithoutCurrentProject]
            localStorage.setItem(`recentProjects[${companyId}]`, JSON.stringify(updatedRecentProjects))
          } else {
            const lastRecentProject = recentProjectsWithoutCurrentProject.slice(0, 8)
            const updatedRecentProjects = [projectId, ...lastRecentProject]
            localStorage.setItem("recentProjects", JSON.stringify(updatedRecentProjects))
          }
        }
      } else {
        const updatedRecentProjects = [projectId]
        localStorage.setItem(`recentProjects[${companyId}]`, JSON.stringify(updatedRecentProjects))
      }
    },

    handleShareSubmitted() {
      this.shareProjectSuccessSnackbarOpen = true
      this.handleShareDialogClose()
    },

    handleShareProjectSuccessSnackbarClose() {
      this.shareProjectSuccessSnackbarOpen = false
    },
  },
})
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dashboard-body {
  // position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 4rem;
  padding: 2rem 0;

  .dashboard-title {
    font-family: "CamptonSemibold";
    font-size: 2.8rem;
    line-height: 6.8rem;
    letter-spacing: 0.01px;
    font-weight: 900;
    color: var(--black);
  }

  .dashboard-content {
    margin-left: -12px;
  }
}

::v-deep {
  .toolbar {
    margin-left: 1.9rem !important;
  }
  .page-title {
    color: var(--navy);
    margin-bottom: 0;
  }
  .page-body {
    position: relative;
    padding-right: 1.8rem !important;
  }
}

.project-dashboard-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}
</style>
