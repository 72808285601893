<template>
  <div class="wrapper">
    <MainToolbar :user-id="userId" />
    <main>
      <SideNav
        v-if="shouldShowNonSuperAdminDashboard"
        :user-id="userId"
        :company-id="companyId"
        :user-home-company-id="userHomeCompanyId"
        :user-home-company-name="userHomeCompanyName"
        :is-external-user="isExternalUser"
        @workspace-changed="handleWorkspaceChanged"
      />
      <div id="content" class="content">
        <div v-if="isLoading" class="loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
        </div>
        <transition v-else name="route" mode="out-in">
          <router-view
            class="router-view"
            :company-id="companyId"
            :user-home-company-id="userHomeCompanyId"
            :is-external-user="isExternalUser"
            @workspace-updated="handleWorkspaceUpdated"
          ></router-view>
        </transition>
        <div class="copyright">© Copyright {{ currentYear }} EngieCam LLC</div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { Workspace } from "@/types/Workspaces"
import { UserRecord } from "@/types/UserRecord"
import SideNav from "../components/Dashboard/SideNav.vue"
import MainToolbar from "../components/Dashboard/MainToolbar.vue"
import { getCurrentAuthUserRecord, isSuperAdmin } from "../services/authService"

export default Vue.extend({
  components: {
    SideNav,
    MainToolbar,
  },
  data() {
    return {
      isSuperAdmin: null as null | boolean,
      isLoading: true,
      currentYear: new Date().getFullYear(),
      userId: null as null | string,
      userHomeCompanyId: null as any,
      userHomeCompanyName: null as any,
      companyId: null as any,
      isExternalUser: false,
    }
  },
  computed: {
    shouldShowNonSuperAdminDashboard(): boolean | null {
      return !this.isLoading && !this.isSuperAdmin
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async checkIsSuperAdmin() {
      this.isSuperAdmin = await isSuperAdmin()
    },

    async setUserCompanyId() {
      const user: UserRecord = await getCurrentAuthUserRecord()
      this.userHomeCompanyId = user.companyId
      this.userId = user.id
      const currentWorkspace: Workspace = JSON.parse(
        localStorage.getItem(`currentWorkspace[${this.userId}]`) || `${null}`
      )
      if (currentWorkspace) {
        this.companyId = currentWorkspace.companyId
      } else {
        this.companyId = this.userHomeCompanyId
      }
    },

    isUserExternal() {
      if (this.userHomeCompanyId !== this.companyId) {
        return true
      }
      return false
    },

    async initialize() {
      await this.checkIsSuperAdmin()
      await this.setUserCompanyId()
      this.isExternalUser = this.isUserExternal()
      this.isLoading = false
    },

    handleWorkspaceChanged(value: any) {
      this.isLoading = true
      this.companyId = value.companyId
      this.isExternalUser = this.isUserExternal()
      this.isLoading = false
    },

    handleWorkspaceUpdated(value: any) {
      this.isLoading = true

      const currentWorkspace: Workspace = JSON.parse(
        localStorage.getItem(`currentWorkspace[${this.userId}]`) || `${null}`
      )
      currentWorkspace.name = value.name
      localStorage.setItem(`currentWorkspace[${this.userId}]`, JSON.stringify(currentWorkspace))
      this.userHomeCompanyName = value.name
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .toolbar {
    box-shadow: 0 0.1rem 0.4rem #00000039;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    z-index: var(--z-index-main-search-bar);

    .logo-button {
      width: 16rem;
    }

    .log-out-button {
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  main {
    height: calc(100% - 8rem);
    display: flex;
    flex-grow: 1;

    .copyright {
      color: var(--white);
      background: var(--navy);
      padding: 0rem 1rem;
      height: 3.2rem;
      z-index: var(--z-index-copyright);
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.router-view {
  overflow-y: auto;
}

.route-enter-active,
.route-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateX(1rem);
}

.loading-spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
