<template>
  <div class="">
    <v-dialog
      style="height: 100%"
      :retain-focus="false"
      :value="dialogOpen"
      :persistent="true"
      :max-width="`100%`"
      content-class="lightbox-dialog-container"
      @keydown.esc="closeModal()"
    >
      <div class="content-container">
        <div v-if="loading" class=""></div>
        <div v-else class="content">
          <div class="toolbar d-flex justify-space-between align-center">
            <v-menu class="menu-container" left offset-y>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" text class="extra-menu" v-on="on">...</v-btn>
              </template>
              <v-list class="menu-list">
                <v-list-item v-if="!isShared" class="menu-item d-flex justify-center">
                  <v-btn class="menu-btn" depressed @click="handleAssignPhotoDialogOpen">Move</v-btn>
                </v-list-item>
                <v-list-item v-if="!isShared" class="menu-item d-flex justify-center">
                  <v-btn class="menu-btn" depressed @click="handleShareDialogOpen">Share</v-btn>
                </v-list-item>
                <v-list-item class="menu-item d-flex justify-center">
                  <v-btn class="menu-btn" depressed @click="handlePhotoDownload">Download</v-btn>
                </v-list-item>
                <v-list-item v-if="!isShared && !isExternalUser" class="menu-item d-flex justify-center">
                  <v-btn class="menu-btn" depressed @click="handleConfirmationDialogOpen">Delete</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn class="action-btn" icon @click="closeModal()">
              <close-icon class="close-icon" />
            </v-btn>
          </div>
          <div
            :style="`background-image: url('${encodeOriginalImageUrl}'); height: ${findImageHeight()};`"
            class="lightbox-img"
          ></div>
        </div>
        <AssignPhotoDialog
          v-if="!isShared"
          :is-external-user="isExternalUser"
          :company-id="companyId"
          :dialog-open="assignPhotoDialogOpen"
          :selected-photos-for-assignment="selectedPhotosForAssignment"
          @photo-updated="handlePhotoUpdated"
          @close="handleAssignPhotoDialogClose"
        />
        <EngieShareDialog
          v-if="!isShared"
          :open="shareDialogOpen"
          :item-type="'Photo'"
          :photos="selectedPhotosForSharing"
          :company-id="companyId"
          @share-submitted="handleShareSubmitted"
          @close="handleShareDialogClose"
        />
        <EngieConfirmationDialogButton
          v-if="!isShared"
          :open="confirmationDialogOpen"
          :dialog-title="`Delete Photo(s)`"
          :narrower="true"
          :disable-confirmation="!confirmation"
          style="display: none"
          @confirmed="handlePhotoDelete"
          @dialog-closed="handleConfirmationDialogClose"
        >
          <div class="confirmation-content-container">
            <h3 style="font-weight: 400">Are you sure?</h3>
            <p>Type "DELETE" to confirm</p>
            <EngieTextInput v-model="confirmationInput" class="confirmation-input" :label="''"></EngieTextInput>
          </div>
        </EngieConfirmationDialogButton>
        <EngieSuccessSnackbar
          v-model="sharePhotoSuccessSnackbarOpen"
          text="Your photo(s) were shared successfully"
          @close="handleSharePhotoSuccessSnackbarClose()"
        />
        <EngieSuccessSnackbar
          v-model="deletePhotoSuccessSnackbarOpen"
          text="Your photo(s) were shared successfully"
          @close="handleDeletePhotoSuccessSnackbarClose()"
        />
        <EngieSuccessSnackbar
          v-model="assignPhotoSuccessSnackbarOpen"
          text="Your photo(s) were assigned successfully"
          @close="handleAssignPhotoSuccessSnackbarClose()"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompanyUrl } from "@/util/urls"
import { downloadImage } from "@/util/downloadImage"
import EngieShareDialog from "@/components/EngieShareDialog.vue"
import EngieConfirmationDialogButton from "@/components/EngieConfirmationDialogButton.vue"
import EngieTextInput from "@/components/forms/EngieTextInput.vue"
import EngieSuccessSnackbar from "@/components/EngieSuccessSnackbar.vue"
import CloseIcon from "./common_icons/CloseIcon.vue"
import AssignPhotoDialog from "./Photos/AssignPhotoDialog.vue"

export default {
  components: {
    EngieShareDialog,
    EngieConfirmationDialogButton,
    EngieTextInput,
    EngieSuccessSnackbar,
    CloseIcon,
    AssignPhotoDialog,
  },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    photo: {
      type: Object,
      default: null,
    },
    projectId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: true,
    imageId: null,
    userId: "",

    shareDialogOpen: false,
    selectedPhotosForSharing: null,
    sharePhotoSuccessSnackbarOpen: false,

    confirmationDialogOpen: false,
    confirmationInput: "",
    deletePhotoSuccessSnackbarOpen: false,

    assignPhotoDialogOpen: false,
    selectedPhotosForAssignment: null,
    assignPhotoSuccessSnackbarOpen: false,
  }),
  computed: {
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },

    encodeOriginalImageUrl() {
      return encodeURI(this.photo.originalImageUrl)
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    closeModal() {
      this.$emit("close-lightbox")
    },

    async initialize() {
      this.imageId = this.photo.id
      this.loading = false
    },

    handleConfirmationDialogClose() {
      this.confirmationDialogOpen = false
      this.photosForDelete = null
    },

    handleConfirmationDialogOpen() {
      this.confirmationDialogOpen = true
      this.photosForDelete = this.imageId
    },

    async deletePhoto(companyId, photoId) {
      try {
        const response = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/photos/${photoId}`, "DELETE")
        return response
      } catch (error) {
        this.deletePhotoErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    async handlePhotoDelete() {
      const response = await this.deletePhoto(this.companyId, this.imageId)
      this.handleConfirmationDialogClose()
      this.deletePhotoSuccessSnackbarOpen = true
      this.$router.go(-1)
      return response
    },

    handleDeletePhotoSuccessSnackbarClose() {
      this.deletePhotoSuccessSnackbarOpen = false
    },

    async handlePhotoDownload() {
      downloadImage(this.photo.originalImageUrl)
    },

    // sharing
    handleShareDialogOpen() {
      this.selectedPhotosForSharing = [this.photo]
      this.shareDialogOpen = true
    },

    handleShareDialogClose() {
      this.selectedPhotosForSharing = []
      this.shareDialogOpen = false
    },

    handleShareSubmitted() {
      this.sharePhotoSuccessSnackbarOpen = true
      this.handleShareDialogClose()
    },

    // assigning
    handlePhotoUpdated() {
      this.assignPhotoSuccessSnackbarOpen = true
    },
    handleAssignPhotoDialogOpen() {
      this.selectedPhotosForAssignment = [this.photo]
      this.assignPhotoDialogOpen = true
    },
    handleAssignPhotoDialogClose() {
      this.selectedPhotosForAssignment = []
      this.assignPhotoDialogOpen = false
    },
    handleAssignPhotoSuccessSnackbarClose() {
      this.assignPhotoSuccessSnackbarOpen = false
    },

    findImageHeight() {
      const img = new Image()
      img.src = this.photo.originalImageUrl
      return `${img.height}px`
    },
  },
}
</script>

<style lang="scss">
.lightbox-dialog-container {
  height: 100% !important;
  padding: 0 !important;
  background-color: var(--grey);
}
</style>

<style lang="scss" scoped>
.content-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .toolbar {
    height: 5.2rem;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 5;
    position: absolute;
    width: 100%;
    top: 0;

    .menu-container {
      .menu-list {
        padding: 0 !important;
        color: var(--navy) !important;
        box-shadow: none !important;
        margin-right: 1rem;
        border: 2px solid var(--light-grey);
        &::v-deep {
          box-shadow: none !important;
          .v-menu__content {
            box-shadow: none;
          }
        }
      }
    }
  }
}
.menu-btn {
  color: var(--navy);
  background-color: white !important;
  transition: 0.15s;
  font-size: 1.4rem;
  font-weight: 600;
  width: 100% !important;
  letter-spacing: 0.3px;
  line-height: 1.4rem;
  text-transform: none;
  display: flex;
  justify-content: left;
  &:before {
    display: none;
  }
}

.menu-btn:hover {
  background-color: var(--navy) !important;
  color: white !important;
}

.v-list-item--active::before {
  display: none;
}

.v-list-item {
  padding: 0rem 0rem !important;
  min-height: 0px;
}

.extra-menu {
  margin-left: 1.7rem !important;
  position: relative !important;
  color: var(--navy);
  font-size: 9rem;
  letter-spacing: -3px;
  &::v-deep {
    .v-btn__content {
      bottom: 2.3rem;
      right: 0.5rem;
    }
  }
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}

.action-btn {
  margin-right: 3rem;
}

.close-icon {
  width: 2rem;
}

.lightbox-img {
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: var(--grey);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
