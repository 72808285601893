<template>
  <div class="role-based-dashboard">
    <div v-if="isLoading" class="full-page-loading-spinner">
      <v-progress-circular v-if="isLoading" indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <SuperAdminDashboard v-if="shouldShowSuperAdminDashboard" />
    <UserDashboard
      v-if="shouldShowNonSuperAdminDashboard"
      :company-id="companyId"
      :user-home-company-id="userHomeCompanyId"
      :is-external-user="isExternalUser"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import SuperAdminDashboard from "../../views/SuperAdminDashboard.vue"
import UserDashboard from "../../views/UserDashboard.vue"
import { isSuperAdmin } from "../../services/authService"

export default Vue.extend({
  components: {
    SuperAdminDashboard,
    UserDashboard,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSuperAdmin: null as boolean | null,
      isLoading: true,
    }
  },
  computed: {
    shouldShowNonSuperAdminDashboard(): boolean | null {
      return !this.isLoading && !this.isSuperAdmin
    },
    shouldShowSuperAdminDashboard(): boolean | null {
      return !this.isLoading && this.isSuperAdmin
    },
  },
  mounted() {
    this.checkIsSuperAdmin()
  },
  methods: {
    async checkIsSuperAdmin() {
      this.isSuperAdmin = await isSuperAdmin()
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" scoped>
.role-based-dashboard {
  height: 100%;
  width: 100%;
  margin-bottom: 3rem;

  .full-page-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
