<template>
  <div class="upload-photos-dialog-container">
    <v-dialog
      :value="open"
      attach=".upload-photos-dialog-container"
      transition="dialog-bottom-transition"
      hide-overlay
      width="100%"
    >
      <v-card>
        <PageTitle>
          Upload new photos
          <template #toolbar>
            <v-btn icon @click="closeUploadNewPhotosDialog()">
              <CloseIcon class="close-icon" />
            </v-btn>
          </template>
        </PageTitle>
        <div class="content">
          <transition name="storage-meter" mode="out-in">
            <label v-if="storageInformationLoaded" for="linear-progress" class="storage-meter-label">
              <strong>{{ percentageOfStorageUsed }}%</strong> of <strong>{{ storagePlanDisplayName }}</strong> used
              <v-progress-linear id="linear-progress" :value="percentageOfStorageUsed"></v-progress-linear>
            </label>
            <div v-else class="loading-spinner-container">
              <v-progress-circular indeterminate color="primary" :size="41.5"></v-progress-circular>
            </div>
          </transition>
          <PhotoUploader
            :open="open"
            :photo-upload-url="getPhotoUploadUrl()"
            @improper-file-uploaded="handleImproperFileUploaded"
            @file-uploaded="handleFileUploaded"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { getCompanyPhotosUrl, getCompanyStorageInformationUrl } from "@/util/urls"
import { StorageInformationResponse } from "@/types/StorageInformationResponse"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import PageTitle from "../PageTitle.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"
import PhotoUploader from "../PhotoUploader.vue"

const GIGABYTE_SIZE = 1_000_000_000

export default Vue.extend({
  components: {
    CloseIcon,
    PageTitle,
    PhotoUploader,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      storageInformation: null as null | StorageInformationResponse,
      newFilesUploadedByteTotal: 0,
      storagePlanDisplayName: "",
      storageInformationLoaded: false,
    }
  },
  computed: {
    percentageOfStorageUsed(): number | null {
      if (
        this.storageInformation === null ||
        (this.storageInformation.totalBytesUsed === 0 && this.newFilesUploadedByteTotal === 0)
      ) {
        return 0
      }

      const totalBytesUsedIncludingNewUploads = this.storageInformation.totalBytesUsed + this.newFilesUploadedByteTotal
      const storageConsumedRatio =
        totalBytesUsedIncludingNewUploads / (this.storageInformation.planGigMaximum * GIGABYTE_SIZE)

      return Math.max(1, Math.floor(storageConsumedRatio * 100))
    },
  },
  mounted() {
    this.getCompanyInformation()
  },
  methods: {
    async getCompanyInformation() {
      this.storageInformation = await makeAuthenticatedRequest(getCompanyStorageInformationUrl(this.companyId))
      if (this.storageInformation !== null) {
        this.storagePlanDisplayName = this.storageInformation.storagePlanDisplayName
        this.storageInformationLoaded = true
      }
    },
    handleImproperFileUploaded() {
      this.$emit("improper-file-uploaded")
    },
    getPhotoUploadUrl() {
      return this.companyId ? getCompanyPhotosUrl(this.companyId) : null
    },
    handleFileUploaded(file: File) {
      this.newFilesUploadedByteTotal += file.size
      this.$emit("new-file-uploaded")
    },
    closeUploadNewPhotosDialog() {
      this.$emit("close-clicked")
    },
  },
})
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog__content {
    left: 22rem;
    top: 8rem;
    width: calc(100% - 22rem);
    align-items: flex-start;
    height: 100%;

    .v-dialog {
      height: 100%;
      margin: 0 !important;

      .v-card {
        padding: 8rem;
        padding-top: 2rem;
        min-height: 100%;

        h1 {
          padding: 0;
          border-bottom: none;
        }

        .content {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 8rem;
          margin-bottom: 5rem;

          label.storage-meter-label {
            font-size: 1.5rem;

            .v-progress-linear {
              width: 60rem;
              margin-top: 0.5rem;
              margin-bottom: 2rem;
            }
          }
          .loading-spinner-container {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .toolbar {
      margin-left: auto;
    }
  }
}

.storage-meter-enter-active,
.storage-meter-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.storage-meter-enter,
.storage-meter-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
