<template>
  <div class="engie-snackbar">
    <v-snackbar :value="value" :timeout="5000" :color="color" @input="handleInput($event)">
      {{ text }}

      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="handleClose()"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInput(event: Event) {
      this.$emit("input", event)
    },
    handleClose() {
      this.$emit("close")
    },
  },
})
</script>

<style lang="scss">
.v-snack__content {
  font-size: 1.5rem !important;
}
</style>
