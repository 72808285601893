<template>
  <div class="engie-textarea" :class="{ 'animated-label': animatedLabel }">
    <v-textarea
      ref="textArea"
      :value="value"
      :label="label"
      :rows="rows"
      :row-height="rowHeight"
      outlined
      :rules="required !== null ? [requiredRule] : []"
      no-resize
      @input="handleInput($event)"
      @blur="handleBlur($event)"
    >
    </v-textarea>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { required as requiredRule } from "vuelidate/lib/validators"

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    animatedLabel: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: "",
    },
    rowHeight: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: "Label",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      requiredRule,
    }
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    handleBlur(value: Event) {
      this.$emit("blur", value)
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-textarea::v-deep .v-text-field {
  width: 100rem;
  label {
    color: var(--charcoal);
    text-align: center;
    line-height: 23px;
  }
  fieldset {
    border-color: var(--light-grey);
    border-radius: 0.5rem;
  }

  legend {
    // Vuetify sets this property in javascript. So, a necessary sin.
    width: 0 !important;
  }

  &.v-input--is-focused {
    caret-color: var(--orange) !important;

    fieldset {
      border-color: var(--grey);
      border-width: 0.1rem;
      border-radius: 0;
    }
  }

  .error--text {
    color: red !important;
  }
}

.engie-textarea.animated-label::v-deep .v-text-field {
  input {
    padding: 2.1rem 0 0.8rem;
  }
  &.v-input--is-focused,
  &.v-input--is-dirty {
    label.v-label--active {
      color: var(--mid-grey) !important;
      transform: translate(0, -1.2rem) scale(0.7) !important;
    }
  }
}

.engie-textarea:not(.animated-label)::v-deep .v-text-field {
  padding-top: 3.6rem;
  label {
    transform: translate(-1rem, -5rem);
  }
}

.loading-enter-active,
.loading-leave-active {
  transition: all 1s ease-out;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
}
</style>
