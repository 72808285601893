import { render, staticRenderFns } from "./ProjectInformation.vue?vue&type=template&id=48e3d836&scoped=true"
import script from "./ProjectInformation.vue?vue&type=script&lang=js"
export * from "./ProjectInformation.vue?vue&type=script&lang=js"
import style0 from "./ProjectInformation.vue?vue&type=style&index=0&id=48e3d836&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e3d836",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardTitle,VDivider,VProgressCircular})
