<template>
  <ProjectDashboardContent>
    <template #title>Photos by Department</template>
    <template #content>
      <div v-if="loading" class="project-department-loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
      </div>
      <div v-else class="d-flex flex-wrap" style="width: 100%">
        <div v-for="department in departments" :key="department.id" class="card-container">
          <ProjectDashCard v-if="department.id !== null">
            <template #img-content>
              <router-link to="" @click.native="handleCardClick(department)">
                <v-img
                  v-if="department.latestPhotoThumbnailUrl"
                  style="border-radius: 15px"
                  height="100%"
                  :src="department.latestPhotoThumbnailUrl"
                ></v-img>
                <v-img v-else style="border-radius: 15px; background-color: var(--lightest-grey)" height="100%"></v-img>
              </router-link>
            </template>
            <template #text-content>
              <v-card-title class="title-content">
                {{ department.name }}
              </v-card-title>
              <v-btn
                depressed
                rounded
                class="edit-btn"
                @click="handleEditDepartmentDialogOpen(department.name, department.id)"
              >
                <edit-icon style="height: 2rem" />
              </v-btn>
            </template>
          </ProjectDashCard>

          <ProjectDashCard v-else>
            <template #img-content>
              <router-link to="" @click.native="handleNoDepartmentClick()">
                <v-img
                  v-if="department.latestPhotoThumbnailUrl"
                  style="border-radius: 15px"
                  height="100%"
                  :src="department.latestPhotoThumbnailUrl"
                ></v-img>
                <v-img v-else style="border-radius: 15px; background-color: var(--lightest-grey)" height="100%"></v-img>
              </router-link>
            </template>
            <template #text-content>
              <v-card-title class="title-content">
                {{ department.name }}
              </v-card-title>
            </template>
          </ProjectDashCard>
        </div>
        <EngieDialog
          :open="editDepartmentDialogOpen"
          :dialog-title="'Edit Department'"
          :dialog-max-width="'50rem'"
          :narrower="true"
          @dialog-closed="handleEditDepartmentDialogClose()"
        >
          <div class="dialog-content-department">
            <h4 class="subheader">New Department Name:</h4>
            <EngieTextInput v-model="editSelectedDepartmentName" :required="true" :label="''" class="text-input" />
          </div>
          <EngieButton class="submit-btn" :disabled="disableSubmit" @click="handleSubmitEditedDepartment"
            >Save Changes</EngieButton
          >
        </EngieDialog>
        <EngieCreateButton
          :title="'Add Department'"
          :dialog-title="'Create New Department'"
          :open="createDepartmentDialogOpen"
          :narrow="true"
          :department="true"
          @create="handleCreateDepartment"
          @dialog-opened="handleCreateDepartmentDialogClick"
          @dialog-closed="handleCreateDepartmentDialogClick"
        >
          <template #button>
            <v-btn height="100%" class="d-flex btn card" depressed @click="handleCreateDepartmentDialogClick()">
              <div class="d-flex flex-column align-center">
                <EngiePlus style="height: 34px" />
                <p>Add Department</p>
              </div>
            </v-btn>
          </template>
        </EngieCreateButton>
        <EngieSuccessSnackbar
          v-model="createDepartmentSuccessSnackbarOpen"
          text="Your department was created successfully"
          @close="handleCreateDepartmentSuccessSnackbarClose()"
        />
        <EngieErrorSnackbar
          v-model="createDepartmentErrorSnackbarOpen"
          text="An error occured while creating your department"
          @close="handleCreateDepartmentErrorSnackbarClose()"
        />
        <EngieSuccessSnackbar
          v-model="editDepartmentSuccessSnackbarOpen"
          text="Your department was edited successfully"
          @close="handleEditDepartmentSuccessSnackbarClose()"
        />
        <EngieErrorSnackbar
          v-model="editDepartmentErrorSnackbarOpen"
          text="An error occured while editing your department"
          @close="handleEditDepartmentErrorSnackbarClose()"
        />
      </div>
    </template>
  </ProjectDashboardContent>
</template>

<script>
import Vue from "vue"
import EngieCreateButton from "@/components/ProjectDashboard/EngieCreateButton.vue"
import ProjectDashboardContent from "@/components/ProjectDashboard/ProjectDashboardContent.vue"
import ProjectDashCard from "@/components/ProjectDashboard/ProjectDashCard.vue"
import {
  getAllCompanyDepartmentsGallery,
  patchCompanyDepartment,
  postCompanyDepartment,
} from "@/services/projectService"
import EditIcon from "@/components/common_icons/EditIcon.vue"
import EngieSuccessSnackbar from "@/components/EngieSuccessSnackbar.vue"
import EngieErrorSnackbar from "@/components/EngieErrorSnackbar.vue"
import EngieDialog from "@/components/EngieDialog.vue"
import EngieButton from "@/components/forms/EngieButton.vue"
import EngieTextInput from "@/components/forms/EngieTextInput.vue"
import EngiePlus from "@/components/common_icons/PlusIcon.vue"

export default Vue.extend({
  components: {
    ProjectDashCard,
    ProjectDashboardContent,
    EngieCreateButton,
    EngieSuccessSnackbar,
    EngieErrorSnackbar,
    EngieDialog,
    EngieButton,
    EngieTextInput,
    EngiePlus,
    EditIcon,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    photos: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String,
      default: "",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    selectedDepartment: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      newDepartmentName: "",
      createDepartmentDialogOpen: false,
      editDepartmentDialogOpen: false,
      projectDepartments: [],
      loading: false,
      companyDepartments: [],
      createDepartmentSuccessSnackbarOpen: false,
      createDepartmentErrorSnackbarOpen: false,
      editDepartmentSuccessSnackbarOpen: false,
      editDepartmentErrorSnackbarOpen: false,
      editSelectedDepartmentName: "",
      editSelectedDepartmentId: "",
    }
  },
  computed: {
    disableSubmit() {
      if (this.editSelectedDepartmentName) {
        return false
      }
      return true
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    handleCardClick(department) {
      this.$emit("set-department", department)
      this.$router.push({
        name: "ProjectDeptCategoryPhotos",
        params: { projectId: this.project.id, departmentId: department.id },
      })
    },

    async fetchCompanyDepartments(companyId, projectId) {
      const response = await getAllCompanyDepartmentsGallery(companyId, projectId)
      return response
    },

    async initialize() {
      this.loading = true
      this.loading = false
    },

    async createProjectDepartment(companyId, departmentData) {
      const response = await postCompanyDepartment(companyId, departmentData)
      return response
    },

    async resetDepartmentsOnCreation() {
      this.$emit("department-updated")
      this.createDepartmentDialogOpen = false
      this.createDepartmentSuccessSnackbarOpen = true
    },

    async handleCreateDepartment(value) {
      this.loading = true
      const departmentData = { name: value, projectId: this.project.id }
      const response = await this.createProjectDepartment(this.companyId, departmentData)
      if (response) {
        await this.resetDepartmentsOnCreation()
      } else {
        this.createDepartmentDialogOpen = false
        this.createDepartmentErrorSnackbarOpen = true
      }
      this.loading = false
    },

    handleCreateDepartmentSuccessSnackbarClose() {
      this.createDepartmentSuccessSnackbarOpen = false
    },

    handleCreateDepartmentErrorSnackbarClose() {
      this.createDepartmentErrorSnackbarOpen = false
    },

    handleCreateDepartmentDialogClick() {
      this.createDepartmentDialogOpen = !this.createDepartmentDialogOpen
    },

    async editProjectDepartment(companyId, departmentId, departmentData) {
      const response = await patchCompanyDepartment(companyId, departmentId, departmentData)
      return response
    },

    async resetDepartmentsOnEdit() {
      this.loading = true
      this.editDepartmentDialogOpen = false
      this.editDepartmentSuccessSnackbarOpen = true
      this.$emit("department-updated")
    },

    async handleSubmitEditedDepartment() {
      this.loading = true
      const newDepartmentName = { name: this.editSelectedDepartmentName }
      const response = await this.editProjectDepartment(
        this.companyId,
        this.editSelectedDepartmentId,
        newDepartmentName
      )
      if (response) {
        await this.resetDepartmentsOnEdit()
      } else {
        this.editDepartmentDialogOpen = false
        this.editDepartmentErrorSnackbarOpen = true
      }
      this.loading = false
    },

    handleEditDepartmentDialogOpen(departmentName, departmentId) {
      this.editSelectedDepartmentName = departmentName
      this.editSelectedDepartmentId = departmentId
      this.editDepartmentDialogOpen = true
    },

    handleEditDepartmentDialogClose() {
      this.editDepartmentDialogOpen = false
    },

    handleEditDepartmentSuccessSnackbarClose() {
      this.editDepartmentSuccessSnackbarOpen = false
    },

    handleEditDepartmentErrorSnackbarClose() {
      this.editDepartmentErrorSnackbarOpen = false
    },

    closeModal() {
      this.$emit("dialog-closed")
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },

    handleNoDepartmentClick() {
      this.$router.push(this.$router.push(`/projects/${this.project.id}/photos/departments/no-department/no-dept-map`))
    },
  },
})
</script>

<style lang="scss" scoped>
.card-container {
  margin: 0 12px 24px 12px;
  width: 326;
  height: 25.7rem;
}
.edit-btn {
  z-index: 999;
}

.card {
  height: 24.4rem;
  width: 26rem;
  border-radius: 1.5rem;
}

.text-box {
  background-color: transparent;
  height: 5.1rem;
  padding: 0 1.6rem;
  position: absolute;
  bottom: -1px;
  display: flex;
  color: var(--black) !important;
  background-color: white;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}

.v-card__title {
  font-family: "CamptonMedium";
  font-size: 1.5rem;
  line-height: 1.4rem;
  font-weight: 400;
  z-index: 3;
  padding: 0;
}

.project-department-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.dialog-title {
  font-weight: 800;
}

.dialog-content-department {
  padding: 1.5rem;
  font-size: 1.4rem;
}

.subheader {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--black);
  margin-bottom: 1rem;
  font-weight: 400;
}

.submit-btn {
  text-align: center;
}

.edit-btn {
  z-index: 2;
  background-color: white !important;
  padding: 2rem 1rem !important;
  border-radius: 100%;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
}

.btn {
  border-radius: 1.5rem;
  height: 24.4rem !important;
  width: 26rem;
  margin: 0 12px 24px 12px;
  text-transform: none;

  p {
    color: rgba(43, 43, 43, 0.5);
    margin: 1rem 0 0 0 !important;
    font-size: 1.2rem;
  }
}

::v-deep {
  .engie-text-input:not(.animated-label),
  .v-text-field {
    padding-top: 0px !important;
    .v-input__slot {
      border: 2px solid var(--grey);
    }
  }
}
</style>
