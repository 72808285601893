<template>
  <nav>
    <transition name="menu" mode="out-in">
      <div v-if="initialized" key="menu" class="link-container">
        <router-link to="/"><DashboardIcon />Dashboard</router-link>
        <router-link to="/projects"><ProjectsIcon />Projects</router-link>
        <router-link to="/photos"><PhotosIcon />Photos</router-link>
        <router-link to="/map"><MapIcon />View Map</router-link>
        <router-link v-if="isAdmin && !isExternalUser" class="admin" :to="usersLinkTo"><AdminIcon />Users</router-link>
        <router-link v-if="isHeadAdmin && !isExternalUser" :to="companyLinkTo"
          ><CompanyAccountIcon />Company Account</router-link
        >
        <div v-if="!isShared" class="workspaces-container">
          <Workspaces :user-id="userId" :user-workspaces="userWorkspaces" @workspace-changed="handleWorkspaceChanged" />
        </div>
      </div>

      <div v-else key="loading" class="loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
      </div>
    </transition>
  </nav>
</template>

<script lang="ts">
import Vue from "vue"
import { hasAdminRole, hasHeadAdminRole } from "@/services/authService"
import { checkForUserWorkspaces } from "@/util/workspaceUtils"
import { UserWorkspace } from "@/types/Workspaces"
import DashboardIcon from "./DashboardIcon.vue"
import ProjectsIcon from "./ProjectsIcon.vue"
import PhotosIcon from "./PhotosIcon.vue"
import MapIcon from "./MapIcon.vue"
import AdminIcon from "./AdminIcon.vue"
import CompanyAccountIcon from "./CompanyAccountIcon.vue"
import Workspaces from "./Workspaces.vue"

export default Vue.extend({
  components: {
    DashboardIcon,
    ProjectsIcon,
    PhotosIcon,
    MapIcon,
    AdminIcon,
    CompanyAccountIcon,
    Workspaces,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    userHomeCompanyName: {
      type: String,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialized: false,
      isAdmin: false,
      isHeadAdmin: false,
      userWorkspaces: null as null | UserWorkspace,
    }
  },
  computed: {
    companyLinkTo(): string {
      return `/companies/${this.companyId}`
    },
    usersLinkTo(): string {
      return `${this.companyLinkTo}/users`
    },
  },
  watch: {
    userHomeCompanyName(currentValue, previousValue) {
      if (previousValue !== currentValue) {
        this.initialized = false
        this.init()
      }
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    async init() {
      if (!this.isShared) {
        this.isAdmin = await hasAdminRole()
        this.isHeadAdmin = await hasHeadAdminRole()
        this.userWorkspaces = await checkForUserWorkspaces(this.userId)
        this.initialized = true
      } else {
        this.initialized = true
      }
    },

    handleWorkspaceChanged(value: any) {
      this.$emit("workspace-changed", value)
    },
  },
})
</script>

<style lang="scss" scoped>
.workspaces-container {
  border-top: 1px solid var(--mid-light-grey);
  width: 100%;
  height: 8rem;
  position: absolute;
  bottom: 0;
}

nav {
  background: var(--light-grey);
  min-width: 22rem;
  height: 100%;
  padding-top: 2rem;

  .link-container {
    height: 100%;
    position: relative;
  }

  a {
    font-size: 1.4rem;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    height: 4.4rem;
    text-decoration: none;
    color: var(--navy);
    transition: filter 400ms ease;

    &:not(.router-link-exact-active):hover {
      filter: brightness(2.5);
    }

    svg {
      margin-right: 1.5rem;
      width: 2rem;
    }

    &.router-link-exact-active {
      background: var(--white);
      transition: none;
      box-shadow: -5px 1px 5px 2px #c8c8c82e;
    }

    &.admin {
      padding-left: 3.2rem;

      svg {
        width: 3rem;
        margin-right: 1.1rem;
        margin-left: -0.9rem;
      }
    }
  }

  .loading-spinner-container {
    display: flex;
    justify-content: center;
  }
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.menu-enter,
.menu-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
