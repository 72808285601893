<template>
  <div>
    <div v-if="loading" class="team-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <v-card flat>
      <slot name="title"></slot>
      <div class="team-container">
        <v-row class="team-container-row">
          <EngieTeamCard
            v-if="!isExternalUser && isUserAdmin"
            :title="'Add new team member'"
            class="team-container-card"
            @add-team-click="handleAddTeamClick"
          />
          <v-col v-for="projectUser in projectUsers" :key="projectUser.id" class="team-container-col">
            <EngieTeamCard
              :user="projectUser"
              :current-user="projectOwner ? projectUser.id === projectOwner.id : ''"
              class="team-container-card"
            >
              <template v-if="!isExternalUser && isUserAdmin" #card-action-btn>
                <v-btn color="primary" depressed fab class="exit-btn" @click="handleRemoveUserFromTeam(projectUser)"
                  ><EngiePlus />
                </v-btn>
              </template>
            </EngieTeamCard>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <AddTeamMembersDialog
      v-if="!isExternalUser && isUserAdmin"
      :dialog-open="dialogOpen"
      :current-user="currentUser"
      :current-internal-team-members="currentInternalTeamMembers"
      @close="closeModal()"
      @team-selected="handleTeamSelected"
    ></AddTeamMembersDialog>
  </div>
</template>

<script>
import AddTeamMembersDialog from "@/components/AddTeamMembersDialog.vue"
import EngieTeamCard from "./EngieTeamCard.vue"
import EngiePlus from "./common_icons/PlusIcon.vue"

export default {
  components: { AddTeamMembersDialog, EngieTeamCard, EngiePlus },
  props: {
    projectCreator: {
      type: Object,
      default: null,
    },
    currentUser: {
      type: Object,
      default: null,
    },
    currentInternalTeamMembers: {
      type: Array,
      required: false,
      default: null,
    },
    projectTeamPage: {
      type: Boolean,
      default: false,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorOccurred: false,
      errorMessage: "",
      projectOwner: {},
      projectUsers: [],
      dialogOpen: false,
      createProjectSuccessSnackbarOpen: false,
      loading: false,
    }
  },
  mounted() {
    this.intialize()
  },
  methods: {
    intialize() {
      this.loading = true
      this.projectOwner = this.projectCreator
      if (this.currentInternalTeamMembers) {
        this.projectUsers = [...this.currentInternalTeamMembers]
      } else {
        this.projectUsers = [...this.projectUsers, this.currentUser]
      }
      this.loading = false
      this.$emit("initialized")
    },

    handleAddTeamClick() {
      this.dialogOpen = true
    },

    closeModal() {
      this.dialogOpen = false
    },

    handleTeamSelected(value) {
      this.projectUsers = value
      this.$emit("project-users-selected", this.projectUsers)
      this.dialogOpen = false
    },

    handleRemoveUserFromTeam(selectedUser) {
      if (this.$props.projectTeamPage) {
        const updatedProjectUsers = this.projectUsers.filter(projectUsers => projectUsers.id !== selectedUser.id)
        this.$emit("project-users-removed", updatedProjectUsers)
      } else {
        this.projectUsers = this.projectUsers.filter(projectUsers => projectUsers.id !== selectedUser.id)
        this.$emit("project-users-removed", this.projectUsers)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.team-container {
  position: relative;
  margin-bottom: 2rem;

  .team-container-row {
    margin: 0 !important;
  }
  .team-container-col {
    flex-grow: 0 !important;
    margin: 0;
    padding: 0;
    width: 37rem;
  }
  .team-container-card {
    width: 37rem;
    margin-right: 0rem;
    padding-right: 0;

    .exit-btn {
      height: 2.7rem;
      width: 2.7rem;
      transform: rotate(45deg);
      padding: 5px !important;
    }
  }
}
</style>
