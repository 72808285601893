<template>
  <div class="user-summary">
    <div class="profile-picture">
      <div v-if="loading" class="profile-loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
      </div>
      <EngieThumbnailUpload
        v-else
        class="thumbnail-upload"
        :thumbnail="userProfilePictureString ? userProfilePictureString : userProfilePictureUrl"
        @image-added="handleImageAdded"
        ><template #btn-content>
          <div class="btn-content">
            <EngiePlus style="height: 3.8rem; width: 3.8rem" />
            <p>Click to add photo</p>
          </div>
        </template></EngieThumbnailUpload
      >
    </div>
    <div class="user-info">
      <p class="user-name">{{ name }}</p>
      <p class="user-job-title">{{ jobTitle }}</p>
      <p>Email: {{ email }}</p>
      <p>Phone: {{ phoneNumber }}</p>
      <div class="button-container">
        <ChangePasswordDialogButton
          :open="changePasswordDialogOpen"
          :user-id="userId"
          @dialog-closed="handleChangePasswordDialogClosed()"
          @dialog-opened="handleChangePasswordDialogOpened()"
          @password-saved="handlePasswordSaved()"
        />
        <ChangeUserInformationDialogButton
          :open="changeUserInformationDialogOpen"
          :initial-name="name"
          :initial-email="email"
          :initial-phone-number="phoneNumber"
          :initial-job-title="jobTitle"
          @dialog-closed="handleChangeUserInformationDialogClosed()"
          @dialog-opened="handleChangeUserInformationDialogOpened()"
          @information-updated="handleUserInformationUpdated"
        />
      </div>
    </div>
    <EngieSuccessSnackbar
      v-model="changePasswordSuccessSnackbarOpen"
      text="Password saved successfully"
      @close="handlePasswordUpdatedSuccessSnackbarClosed()"
    />
    <EngieSuccessSnackbar
      v-model="changeUserInformationSuccessSnackbarOpen"
      text="Your information was updated successfully"
      @close="handleUserInformationUpdatedSuccessSnackbarClosed()"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { postUserProfilePhoto } from "@/services/userInformationService"
import EngiePlus from "@/components/common_icons/PlusIcon.vue"
import ChangePasswordDialogButton from "./ChangePasswordDialogButton.vue"
import ChangeUserInformationDialogButton from "./ChangeUserInformationDialogButton.vue"
import EngieSuccessSnackbar from "../EngieSuccessSnackbar.vue"
import { UserUpdatePayload } from "../../types/UserUpdatePayload"
import EngieThumbnailUpload from "../forms/EngieThumbnailUpload.vue"

export default Vue.extend({
  components: {
    EngieSuccessSnackbar,
    ChangePasswordDialogButton,
    ChangeUserInformationDialogButton,
    EngieThumbnailUpload,
    EngiePlus,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    jobTitle: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    userProfilePictureUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      changePasswordDialogOpen: false,
      changePasswordSuccessSnackbarOpen: false,
      changeUserInformationDialogOpen: false,
      changeUserInformationSuccessSnackbarOpen: false,
      userProfilePictureString: "",
      userProfilePictureFile: {},
      loading: false,
    }
  },
  methods: {
    async handleImageAdded(value: FormData) {
      this.loading = true
      const profilePhotoFile = value
      try {
        const response = await postUserProfilePhoto(this.userId, profilePhotoFile)
        this.userProfilePictureString = response.profilePictureUrl
        this.$emit("profile-photo-success")
        this.loading = false
      } catch (error) {
        this.$emit("profile-photo-error")
        this.loading = false
      }
    },

    handleChangePasswordDialogClosed() {
      this.changePasswordDialogOpen = false
    },
    handleChangePasswordDialogOpened() {
      this.changePasswordDialogOpen = true
    },
    handleChangeUserInformationDialogClosed() {
      this.changeUserInformationDialogOpen = false
    },
    handleChangeUserInformationDialogOpened() {
      this.changeUserInformationDialogOpen = true
    },
    handlePasswordSaved() {
      this.changePasswordSuccessSnackbarOpen = true
      this.handleChangePasswordDialogClosed()
    },
    handlePasswordUpdatedSuccessSnackbarClosed() {
      this.changePasswordSuccessSnackbarOpen = false
    },
    handleUserInformationUpdatedSuccessSnackbarClosed() {
      this.changeUserInformationSuccessSnackbarOpen = false
    },
    handleUserInformationUpdated(update: UserUpdatePayload) {
      this.changeUserInformationSuccessSnackbarOpen = true

      this.handleChangeUserInformationDialogClosed()

      this.$emit("user-updated", update)
    },
  },
})
</script>

<style lang="scss" scoped>
.user-summary {
  height: 20rem;
  display: flex;

  .profile-picture {
    height: 100%;
    width: 20rem;
    border-radius: 50%;
    background: var(--light-grey);
    margin-right: 3rem;

    .btn-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      p {
        color: rgba(43, 43, 43, 0.5);
        margin: 1rem 0;
        font-size: 1.2rem;
        width: 20rem;
        text-align: center;
      }
    }

    .thumbnail-upload::v-deep {
      .img-upload-btn {
        width: 100% !important;
        border-radius: 50%;
        background-color: var(--light-grey);
        height: 20rem !important;
      }
      .input-label {
        display: none;
      }

      .current-img {
        margin-top: 0 !important;
        width: 100% !important;
        border-radius: 50%;
        background-color: var(--light-grey);
        height: 20rem !important;
      }

      .edit-img-btn {
        bottom: -0.75rem;
        left: 2rem;
      }
    }
  }

  .user-info {
    font-size: 1.5rem;

    p {
      margin-bottom: 0.5rem;
    }

    .user-name {
      color: var(--navy);
      font-size: 4.8rem;
    }

    .user-job-title {
      color: var(--mid-grey);
    }

    .button-container {
      margin-top: 3rem;
      display: flex;

      & > * {
        margin-right: 2rem;
      }
    }
  }
}

.profile-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 25%;
}
</style>
