<template>
  <div class="photo-content">
    <div v-if="!user" class="wrapper">
      <transition name="route" mode="out-in">
        <router-view
          class="photo-view"
          :user="user"
          :is-user="isUser"
          :company-id="companyId"
          :photo-collection="photoCollection"
        ></router-view>
      </transition>
      <div class="copyright">© Copyright {{ currentYear }} EngieCam LLC</div>
    </div>

    <div v-else class="wrapper">
      <MainToolbar />
      <main>
        <SideNav is-shared />
        <transition name="route" mode="out-in">
          <router-view :user="user" :company-id="companyId" :photo-collection="photoCollection"></router-view>
        </transition>
        <div class="copyright">© Copyright {{ currentYear }} EngieCam LLC</div>
      </main>
    </div>
  </div>
</template>

<script>
import MainToolbar from "../Dashboard/MainToolbar.vue"
import SideNav from "../Dashboard/SideNav.vue"

export default {
  components: { MainToolbar, SideNav },
  props: {
    user: {
      type: Object,
      default: null,
    },
    isUser: {
      type: Boolean,
      default: true,
    },
    companyId: {
      type: String,
      default: null,
    },
    photoCollection: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
}
</script>

<style lang="scss" scoped>
.photo-content {
  height: 100%;
  width: 100%;
}

.copyright {
  color: var(--white);
  background: var(--navy);
  padding: 0.5rem 1rem;
  z-index: var(--z-index-copyright);
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .toolbar {
    box-shadow: 0 0.1rem 0.4rem #00000039;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    z-index: var(--z-index-main-search-bar);

    .logo-button {
      width: 16rem;
    }

    .log-out-button {
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  main {
    // height: calc(100% - 8rem);
    height: 100%;
    display: flex;
    flex-grow: 1;

    .copyright {
      color: var(--white);
      background: var(--navy);
      padding: 0rem 1rem;
      height: 3.2rem;
      z-index: var(--z-index-copyright);
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.photo-view {
  overflow-y: scroll !important;
}
</style>
