<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206 170">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="add-project-icon"
          d="M184,170H22A22,22,0,0,1,0,148V22A22,22,0,0,1,22,0H76a4,4,0,0,1,2.83,1.17L95.66,18H184a22,22,0,0,1,22,22V148A22,22,0,0,1,184,170ZM22,8A14,14,0,0,0,8,22V148a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V40a14,14,0,0,0-14-14H94a4,4,0,0,1-2.83-1.17L74.34,8Z"
        />
        <path class="add-project-icon" d="M139,98H67a4,4,0,0,1,0-8h72a4,4,0,0,1,0,8Z" />
        <path class="add-project-icon" d="M103,134a4,4,0,0,1-4-4V58a4,4,0,0,1,8,0v72A4,4,0,0,1,103,134Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.add-project {
  fill: #003347;
}
</style>
