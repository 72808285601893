<template>
  <div class="role-based-dashboard">
    <div v-if="isLoading" class="full-page-loading-spinner">
      <v-progress-circular v-if="isLoading" indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <SharePhotoCollections
      v-if="shouldShowSharedPhotos"
      :user="currentUser"
      :company-id="this.$route.params.companyId"
    />
  </div>
</template>

<script>
import { getCurrentAuthUserRecord } from "@/services/authService"
import { getAllCompanySharedPhotoCollectionsGallery } from "@/services/photoService"
import SharePhotoCollections from "./SharePhotoCollections.vue"

export default {
  components: { SharePhotoCollections },
  data: () => ({
    shouldShowSharedPhotos: false,
    isLoading: true,
    currentUser: null,
    userCompanyId: null,
  }),
  mounted() {
    this.initialize()
  },
  methods: {
    // check if user is logged in, check if user's company is the same as the project they are being shared
    async fetchCurrentUser() {
      try {
        const user = await getCurrentAuthUserRecord()
        return user
      } catch (error) {
        return null
      }
    },

    async fetchPhotoCollection(companyId, photoCollectionId, sortByField, sortDirection, pageSize, pageNumber, search) {
      try {
        const response = await getAllCompanySharedPhotoCollectionsGallery(
          companyId,
          photoCollectionId,
          sortByField,
          sortDirection,
          pageSize,
          pageNumber,
          search
        )
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async initialize() {
      const response = await this.fetchCurrentUser()
      // if user has account
      this.pageNumber = 1
      if (response) {
        this.currentUser = response
        this.shouldShowSharedPhotos = true
        this.isLoading = false
        // if the user does not have an account
      } else {
        this.shouldShowSharedPhotos = true
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.role-based-dashboard {
  height: 100vh;
  width: 100%;
  // overflow-y: scroll;
}
.full-page-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
