/* eslint-disable import/no-cycle */

import axios, { AxiosError } from "axios"
import firebase from "firebase/app"
import "firebase/auth"
import { CompanyRole } from "@/types/CompanyRole"
import { UserRecord } from "@/types/UserRecord"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getAuthorizedSessionsUrl, getAuthorizedSessionUrl, getUserUrl } from "@/util/urls"
import { sessionIdLocalStorageKey } from "@/constants/sessionIdLocalStorageKey"

const COMPANY_PAUSED_ERROR_CODE = 100
const TOO_MANY_DEVICE_SESSIONS_ERROR_CODE = 101

export class AuthorizedSessionCreationError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, AuthorizedSessionCreationError.prototype)
  }
}

export class CompanyPausedError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, CompanyPausedError.prototype)
  }
}

export class TooManyDeviceSessionsError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, TooManyDeviceSessionsError.prototype)
  }
}

export const isSuperAdmin = async (): Promise<boolean> => {
  const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult()

  return idTokenResult?.claims.superAdmin
}

const throwAuthSessionError = (error: AxiosError) => {
  if (error.response?.data.errors[0].code === COMPANY_PAUSED_ERROR_CODE) {
    throw new CompanyPausedError("This user's company has been paused")
  }

  if (error.response?.data.errors[0].code === TOO_MANY_DEVICE_SESSIONS_ERROR_CODE) {
    throw new TooManyDeviceSessionsError("Too many devices in-use")
  }
}

export const createAuthorizedSession = async (
  emailAddress: string,
  password: string
): Promise<AuthorizedSessionResponse> => {
  try {
    return await makeAuthenticatedRequest(getAuthorizedSessionsUrl(), "POST", {
      email: emailAddress,
      password,
    })
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throwAuthSessionError(error)
    }

    await firebase.auth().signOut()

    throw new AuthorizedSessionCreationError("Authorized Session creation failed")
  }
}

export const deleteAuthorizedSession = async (userId: string, sessionId: string) => {
  try {
    await makeAuthenticatedRequest(getAuthorizedSessionUrl(userId, sessionId), "delete")
    localStorage.removeItem(sessionIdLocalStorageKey)
  } catch (error) {
    throw new Error("Authorized Session deletion failed")
  }
}

export const getCurrentAuthUserOrThrowError = () => {
  if (firebase.auth().currentUser == null) {
    throw Error("No current user found")
  } else {
    return firebase.auth().currentUser as firebase.User
  }
}

export const getCurrentAuthUserId = () => getCurrentAuthUserOrThrowError().uid

export const getCurrentUserIdToken = () => getCurrentAuthUserOrThrowError().getIdToken()
export const getCurrentAuthUserRecord = () => makeAuthenticatedRequest<UserRecord>(getUserUrl(getCurrentAuthUserId()))

export const signIn = async (emailAddress: string, password: string) => {
  const authSessionResponse = await createAuthorizedSession(emailAddress, password)

  if (authSessionResponse.sessionId) {
    localStorage.setItem(sessionIdLocalStorageKey, authSessionResponse.sessionId)
  }

  return firebase.auth().signInWithCustomToken(authSessionResponse.token)
}

export const getAuthenticatedUserId = () => firebase.auth().currentUser?.uid

const deleteAuthorizedSessionOrThrowError = () => {
  const currentUserId = firebase.auth().currentUser?.uid
  const sessionId = localStorage.getItem(sessionIdLocalStorageKey)

  if (currentUserId && sessionId) {
    return deleteAuthorizedSession(currentUserId, sessionId)
  }

  throw new Error("Current user did not have a valid id and/or session")
}

const logOutAuth = () => firebase.auth().signOut()

export const logoutAndDeleteDeviceSession = async () => {
  const authenticatedUserIsSuperAdmin = await isSuperAdmin()

  if (!authenticatedUserIsSuperAdmin) {
    await deleteAuthorizedSessionOrThrowError()
  }

  return logOutAuth()
}

export const getCurrentUserEmail = () => {
  const email = firebase.auth().currentUser?.email

  if (!email) {
    throw Error("User has no email address")
  }

  return email
}

export const reauthenticateUser = async (password: string) => {
  const credential = firebase.auth.EmailAuthProvider.credential(getCurrentUserEmail(), password)

  return getCurrentAuthUserOrThrowError().reauthenticateWithCredential(credential)
}

export const updatePassword = async (newPassword: string) =>
  getCurrentAuthUserOrThrowError().updatePassword(newPassword)

const userHasAdminRole = (userRecord: UserRecord) =>
  userRecord.companyRole === CompanyRole.ADMIN || userRecord.companyRole === CompanyRole.HEAD_ADMIN

export const isCompanyAdmin = async (companyId: string) => {
  const hasSuperAdminStatus = await isSuperAdmin()

  if (hasSuperAdminStatus) {
    return true
  }

  const userRecord: UserRecord = await getCurrentAuthUserRecord()

  return userRecord.companyId === companyId && userHasAdminRole(userRecord)
}

const authenticatedUserRoleIsInList = (roleList: CompanyRole[]) => async () => {
  const userRecord: UserRecord = await getCurrentAuthUserRecord()

  return (roleList as string[]).includes(userRecord.companyRole)
}

export const hasAdminRole = authenticatedUserRoleIsInList([CompanyRole.HEAD_ADMIN, CompanyRole.ADMIN])
export const hasHeadAdminRole = authenticatedUserRoleIsInList([CompanyRole.HEAD_ADMIN])
