<template>
  <Page title="Account Settings" :loading="loading">
    <UserSummary
      :name="name"
      :job-title="jobTitle"
      :email="email"
      :phone-number="phoneNumber"
      :user-id="userId"
      :user-profile-picture-url="userProfilePictureUrl"
      @user-updated="handleUserUpdated"
      @profile-photo-error="handleAddUserProfilePictureErrorSnackbarOpen()"
      @profile-photo-success="handleAddUserProfilePictureSuccessSnackbarOpen()"
    />
    <!-- <AccountSettingsContent /> -->
    <EngieSuccessSnackbar
      v-model="addUserProfilePictureSuccessSnackbarOpen"
      text="Your profile picture was updated successfully."
      @close="handleAddUserProfilePictureSuccessSnackbarClose()"
    />
    <EngieErrorSnackbar
      v-model="addUserProfilePictureErrorSnackbarOpen"
      text="An error occured while updating your profile picture."
      @close="handleAddUserProfilePictureErrorSnackbarClose()"
    />
  </Page>
</template>

<script lang="ts">
import Vue from "vue"
import "firebase/auth"
import { getCurrentAuthUserId } from "@/services/authService"
// import AccountSettingsContent from "@/components/AccountSettings/AccountSettingsContent.vue"
import EngieSuccessSnackbar from "@/components/EngieSuccessSnackbar.vue"
import EngieErrorSnackbar from "@/components/EngieErrorSnackbar.vue"
import Page from "../components/Page.vue"
import { makeAuthenticatedRequest } from "../util/makeAuthenticatedRequest"
import { getUserUrl } from "../util/urls"
import { UserResponse } from "../types/UserResponse"
import UserSummary from "../components/AccountSettings/UserSummary.vue"
import { UserUpdatePayload } from "../types/UserUpdatePayload"

export default Vue.extend({
  components: {
    Page,
    UserSummary,
    // AccountSettingsContent,
    EngieErrorSnackbar,
    EngieSuccessSnackbar,
  },
  data() {
    return {
      name: "",
      jobTitle: "",
      email: "",
      userId: "",
      phoneNumber: "",
      userProfilePictureUrl: "",
      loading: true,
      addUserProfilePictureErrorSnackbarOpen: false,
      addUserProfilePictureSuccessSnackbarOpen: false,
    }
  },

  created() {
    this.getUser()
  },
  methods: {
    async getUser() {
      const userData: UserResponse = await makeAuthenticatedRequest(getUserUrl(getCurrentAuthUserId()))
      this.loading = false
      this.name = userData.name
      this.email = userData.email
      this.userId = userData.id
      this.userProfilePictureUrl = userData.profilePictureUrl
      this.jobTitle = userData.jobTitle || ""
      this.phoneNumber = userData.phoneNumber
    },
    handleUserUpdated(update: UserUpdatePayload) {
      this.name = update.name
      this.email = update.email
      this.phoneNumber = update.phoneNumber
      this.jobTitle = update.jobTitle
    },
    handleAddUserProfilePictureErrorSnackbarOpen() {
      this.addUserProfilePictureErrorSnackbarOpen = true
    },
    handleAddUserProfilePictureErrorSnackbarClose() {
      this.addUserProfilePictureErrorSnackbarOpen = false
    },
    handleAddUserProfilePictureSuccessSnackbarOpen() {
      this.addUserProfilePictureSuccessSnackbarOpen = true
    },
    handleAddUserProfilePictureSuccessSnackbarClose() {
      this.addUserProfilePictureSuccessSnackbarOpen = false
    },
  },
})
</script>
