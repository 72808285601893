<template>
  <div class="page">
    <div class="page-title">
      <PageTitle
        >{{ title }}
        <div class="toolbar">
          <slot name="toolbar"></slot></div
      ></PageTitle>
    </div>
    <div class="page-body">
      <transition name="loading" mode="out-in">
        <div v-if="loading" class="loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
        </div>
        <div v-else class="page-content-wrapper">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import PageTitle from "./PageTitle.vue"

export default Vue.extend({
  components: {
    PageTitle,
  },
  props: {
    title: {
      type: String,
      default: "My Page",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.page {
  height: calc(100% - 3rem);
  display: flex;
  flex-direction: column;

  .page-title {
    .toolbar {
      margin-left: auto;
    }
  }
  .page-body {
    padding: 2rem 3rem;
    flex-grow: 1;

    .page-content-wrapper {
      width: 100%;
    }

    &::v-deep .page-title {
      margin: 0 -3rem;
    }

    .loading-spinner-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
