<template>
  <div class="login-container">
    <AuthForm
      title="Log in to your account"
      action-button-text="log in"
      :show-error="errorOccurred"
      :error-message="errorMessage"
      :performing-action="loggingIn"
      @action-button-clicked="handleLogin()"
    >
      <EngieTextInput
        v-model="emailAddress"
        class="email-address-input"
        label="Email address"
        :disabled="loggingIn"
        :animated-label="true"
        autofocus
        @enter-pressed="handleLogin()"
      />
      <EngieTextInput
        v-model="password"
        class="password-input"
        label="Password"
        type="password"
        :disabled="loggingIn"
        :animated-label="true"
        @enter-pressed="handleLogin()"
      />
      <EngieDialog
        narrower
        dialog-title="Log out of other sessions?"
        :open="maxDevicesUsed"
        @dialog-closed="handleTooManyDevicesDialogClosed()"
      >
        <p>
          You are currently signed into EngieCam on two other devices. If you continue, your other sessions will be
          logged out.
        </p>
        <template #action-buttons>
          <EngieButton class="cancel-btn" color="var(--mid-light-grey)" @click="handleCleanupSessionsCancelled()"
            >Cancel</EngieButton
          >
          <EngieButton
            :loading="loggingoutOfSessionsAndLoggingIn"
            color="primary"
            @click="handleCleanupSessionsAndLoginClicked()"
            >Sign In</EngieButton
          >
        </template>
      </EngieDialog>
    </AuthForm>
    <div class="forgot-password-container">
      <p>Need help logging in?</p>
      <EngieTextButton class="mt-2" color="primary" @click="openForgotPasswordDialog()"
        >Reset your password</EngieTextButton
      >

      <EngieDialog
        narrower
        dialog-title="Need help logging in?"
        :open="forgotPasswordDialogOpen"
        @dialog-closed="handleForgotPasswordDialogClosed()"
      >
        <p>
          Enter your email address and we'll send you a link you can use to reset your password. This link will expire
          in 20 minutes.
        </p>
        <EngieTextInput
          v-model="forgotPasswordEmailAddress"
          autofocus
          class="email-address-input"
          label="Email address"
          :disabled="sendingForgotPasswordEmail"
          @enter-pressed="sendForgotPasswordEmail()"
        />
        <template #action-buttons>
          <EngieButton class="cancel-btn" color="var(--mid-light-grey)" @click="handleForgotPasswordDialogClosed()"
            >Cancel</EngieButton
          >
          <EngieButton :loading="sendingForgotPasswordEmail" color="primary" @click="sendForgotPasswordEmail()"
            >Send email</EngieButton
          >
        </template>
      </EngieDialog>
      <EngieSuccessSnackbar
        v-model="showPasswordResetEmailSentSnackbar"
        text="Password reset email sent"
        @close="handlePasswordResentSentSnackbarClosed()"
      />
    </div>
    <div class="toc-privacy-container">
      <router-link to="/terms-and-conditions">Terms and Conditions</router-link> |
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getSendForgotPasswordUrl, getUserSessionCleanupUrl } from "@/util/urls"
import AuthForm from "../components/AuthForm.vue"
import EngieButton from "../components/forms/EngieButton.vue"
import EngieTextButton from "../components/forms/EngieTextButton.vue"
import EngieTextInput from "../components/forms/EngieTextInput.vue"
import EngieDialog from "../components/EngieDialog.vue"
import EngieSuccessSnackbar from "../components/EngieSuccessSnackbar.vue"
import { TooManyDeviceSessionsError, CompanyPausedError, signIn } from "../services/authService"

export default Vue.extend({
  components: { AuthForm, EngieTextInput, EngieDialog, EngieButton, EngieTextButton, EngieSuccessSnackbar },
  data() {
    return {
      errorOccurred: false,
      emailAddress: "",
      password: "",
      loggingIn: false,
      errorMessage: "",
      maxDevicesUsed: false,
      loggingoutOfSessionsAndLoggingIn: false,
      forgotPasswordDialogOpen: false,
      forgotPasswordEmailAddress: "",
      sendingForgotPasswordEmail: false,
      showPasswordResetEmailSentSnackbar: false,
    }
  },
  methods: {
    async handleLogin() {
      this.loggingIn = true
      this.maxDevicesUsed = false

      try {
        await signIn(this.emailAddress, this.password)

        this.$router.push("/")
      } catch (error) {
        if (error instanceof TooManyDeviceSessionsError) {
          this.maxDevicesUsed = true
        } else if (error instanceof CompanyPausedError) {
          this.errorMessage = "Your account has been placed on hold."
        } else {
          this.errorMessage = "Invalid login credentials."
        }

        this.errorOccurred = true
        this.loggingIn = false
      }
    },
    async handleCleanupSessionsAndLoginClicked() {
      await makeAuthenticatedRequest(getUserSessionCleanupUrl(this.emailAddress), "DELETE", { password: this.password })
      this.maxDevicesUsed = false
      this.handleLogin()
    },
    handleCleanupSessionsCancelled() {
      this.maxDevicesUsed = false
    },
    openForgotPasswordDialog() {
      this.forgotPasswordEmailAddress = ""
      this.forgotPasswordDialogOpen = true
    },
    handleForgotPasswordDialogClosed() {
      this.forgotPasswordDialogOpen = false
    },
    async sendForgotPasswordEmail() {
      this.sendingForgotPasswordEmail = true

      await makeAuthenticatedRequest(getSendForgotPasswordUrl(this.forgotPasswordEmailAddress), "POST")

      this.sendingForgotPasswordEmail = false
      this.forgotPasswordDialogOpen = false
      this.showPasswordResetEmailSentSnackbar = true
    },
    handlePasswordResentSentSnackbarClosed() {
      this.showPasswordResetEmailSentSnackbar = false
    },
    handleTooManyDevicesDialogClosed() {
      this.handleCleanupSessionsCancelled()
    },
  },
})
</script>

<style lang="scss">
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .toc-privacy-container {
    margin-top: 4rem;
    margin-bottom: 8rem;
  }

  .forgot-password-container {
    margin-top: 3rem;

    p {
      text-align: center;
      margin-bottom: 0;
    }

    .engie-text-button {
      margin-left: 0;
    }
  }
}
.email-address-input,
.password-input {
  width: 40rem;
}
</style>
