import { Renderer } from "@googlemaps/markerclusterer"
import engieIcon from "../assets/img/engieIcon.png"

class MarkerRenderer implements Renderer {
  googlePackage: any

  constructor(googlePackage: any) {
    this.googlePackage = googlePackage
  }
  // eslint-disable-next-line
  render(config: { count: any; position: any; markers?: any }) {
    if (config.markers) {
      let hasMultipleCategories = null
      for (let i = 0; i < config.markers.length - 1; i += 1) {
        if (config.markers[i].icon.fillColor !== config.markers[i + 1].icon.fillColor) {
          hasMultipleCategories = true
          break
        } else {
          hasMultipleCategories = false
        }
      }

      type markerLabel = {
        text: string
        className: string
        fontSize: string
        fontWeight: string
        fontFamily: string
        color: string
      }

      const label: markerLabel = {
        text: String(config.count),
        className: "marker-label",
        fontSize: "20px",
        fontWeight: "900",
        fontFamily: "CamptonRegular",
        color: "white",
      }

      if (hasMultipleCategories) {
        const marker = new this.googlePackage.maps.Marker({
          label,
          position: config.position,
          icon: {
            url: engieIcon,
            size: new this.googlePackage.maps.Size(50, 50),
            scaledSize: new this.googlePackage.maps.Size(30, 30),
            labelOrigin: new this.googlePackage.maps.Point(-8, -8),
            anchor: new this.googlePackage.maps.Point(41, 84),
            origin: new this.googlePackage.maps.Point(0, 0),
          },
          // adjust zIndex to be above other markers
          zIndex: Number(this.googlePackage.maps.Marker.MAX_ZINDEX) + config.count,
        })
        return marker
      }
      const color = config.markers[0]?.icon.fillColor || "white"
      const svgMarker = {
        path: "M57.5,8.5A22.38,22.38,0,0,0,39.68.4h-.33A22.38,22.38,0,0,0,21.53,8.5c-4.07,4.92-6.32,11.76-6.32,19.27,0,8.8,5.91,20.22,10.36,28.94C30.18,65.76,39.46,79.6,39.51,79.6s9-13.84,14-22.89c4.69-8.59,10.36-20.14,10.36-28.94C63.82,20.26,61.57,13.42,57.5,8.5Zm-18,27.64A10.31,10.31,0,1,1,49.82,25.83,10.31,10.31,0,0,1,39.51,36.14Z",
        fillColor: color,
        fillOpacity: 1,
        rotation: 0,
        scale: 0.5,
        strokeWeight: 0,
        strokeColor: "#404040",
        anchor: new this.googlePackage.maps.Point(41, 84),
        origin: new this.googlePackage.maps.Point(0, 0),
      }
      const marker = new this.googlePackage.maps.Marker({
        label,
        position: config.position,
        icon: svgMarker,
        // adjust zIndex to be above other markers
        zIndex: Number(this.googlePackage.maps.Marker.MAX_ZINDEX) + config.count,
      })
      return marker
    }
  }
}

export const getMarkerRenderer = (googlePackage: any) => {
  return new MarkerRenderer(googlePackage)
}
