import { Validation } from "vuelidate"
import { ValidationRule } from "vuelidate/lib/validators"
import { DEFAULT_REQUIRED_FIELD_ERROR_MESSAGE } from "./checkRequired"

type PhoneValidation = Validation & { required: ValidationRule; minLength: ValidationRule }

export const checkPhoneNumber = (phoneProperty: PhoneValidation) => {
  if (!phoneProperty.$dirty) {
    return []
  }

  if (!phoneProperty.required) {
    return [DEFAULT_REQUIRED_FIELD_ERROR_MESSAGE]
  }

  if (!phoneProperty.minLength) {
    return ["Please enter a valid phone number"]
  }

  return []
}
