<template>
  <Page title="Company Account" :loading="loading">
    <CompanyInformation
      :company-id="companyId"
      :company-name="companyName"
      :company-address="companyAddress"
      :company-size="companySize"
      @saved="handleSave($event)"
    />
    <HeadAdminInformation
      :head-admin-email="headAdminEmail"
      :head-admin-name="headAdminName"
      :head-admin-phone-number="headAdminPhoneNumber"
      @saved="handleSave($event)"
    />
    <PageSubheader>Plan Information</PageSubheader>
    <div v-if="loadingPlanInformation">
      <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
    </div>
    <div v-else class="plan-info">
      <div class="info-item">
        Using <span class="highlighted">{{ seatsUsed }}</span> out of
        <span class="highlighted">{{ totalSeats }}</span> seats
      </div>
      <div class="info-item">
        Using <span class="highlighted">{{ storageUsedToDisplay }}</span> out of
        <span class="highlighted">{{ totalStorage }}</span>
      </div>
      <h3>Change Plan</h3>
      <!-- TODO: insert real contact info -->
      <p>To change your plan, please contact hello@engiecam.com</p>
    </div>
    <EngieSuccessSnackbar
      v-model="showSaveSuccessSnackbar"
      text="Saved successfully"
      @close="handleSaveSnackbarClosed()"
    />
  </Page>
</template>

<script lang="ts">
import Vue from "vue"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompanyMetadataUrl, getCompanyStorageInformationUrl } from "@/util/urls"
import { fetchReferenceDataListAndFindDisplayName } from "@/util/referenceDataUtils"
import { CompanyWithMetadataRecord } from "@/types/CompanyWithMetadataRecord"
import Page from "@/components/Page.vue"
import PageSubheader from "@/components/PageSubheader.vue"
import CompanyInformation from "@/components/CompanyAccount/CompanyInformation.vue"
import HeadAdminInformation from "@/components/CompanyAccount/HeadAdminInformation.vue"
import EngieSuccessSnackbar from "@/components/EngieSuccessSnackbar.vue"
import { StorageInformationResponse } from "@/types/StorageInformationResponse"

const KB_IN_BYTES = 1_000
const MB_IN_BYTES = 1_000_000
const GB_IN_BYTES = 1_000_000_000
const TB_IN_BYTES = 1_000_000_000_000

export default Vue.extend({
  components: {
    Page,
    PageSubheader,
    CompanyInformation,
    HeadAdminInformation,
    EngieSuccessSnackbar,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companyWithMetadata: null as null | CompanyWithMetadataRecord,
      storageInfo: null as null | StorageInformationResponse,
      planDisplayName: null as null | string,
      totalStorage: null as null | string,
      storageUsed: "N/A",
      showSaveSuccessSnackbar: false,
    }
  },
  computed: {
    companyName(): string | undefined {
      return this.companyWithMetadata?.name
    },
    companyAddress(): string | undefined {
      return this.companyWithMetadata?.address
    },
    companySize(): number | undefined {
      return this.companyWithMetadata?.size
    },
    headAdminName(): string | undefined {
      return this.companyWithMetadata?.headAdminName
    },
    headAdminEmail(): string | undefined {
      return this.companyWithMetadata?.headAdminEmail
    },
    headAdminPhoneNumber(): string | undefined {
      return this.companyWithMetadata?.headAdminPhoneNumber
    },
    seatsUsed(): string | undefined {
      return this.companyWithMetadata?.numberOfActiveUsers
    },
    loading(): boolean {
      return this.companyWithMetadata === null
    },
    loadingPlanInformation(): boolean {
      return this.totalSeats === null || this.totalStorage === null
    },
    // Plan names are currently of the form "X-X", so we split on the hyphen to grab only the max
    totalSeats(): string | undefined {
      return this.planDisplayName?.split("-")[1]
    },
    storageUsedToDisplay(): string {
      if (typeof this.storageInfo?.totalBytesUsed !== "undefined") {
        if (this.storageInfo?.totalBytesUsed < KB_IN_BYTES) return "less than 1 KB"
        if (this.storageInfo?.totalBytesUsed < MB_IN_BYTES) return `${this.getRoundedStorageCount(KB_IN_BYTES)} KB`
        if (this.storageInfo?.totalBytesUsed < GB_IN_BYTES) return `${this.getRoundedStorageCount(MB_IN_BYTES)} MB`
        if (this.storageInfo?.totalBytesUsed < TB_IN_BYTES) return `${this.getRoundedStorageCount(GB_IN_BYTES)} GB`

        return `${this.getRoundedStorageCount(TB_IN_BYTES)} TB`
      }

      return "N/A"
    },
  },
  watch: {
    isExternalUser(currentValue) {
      if (currentValue === true) {
        this.$router.push(`/`)
      }
    },
  },
  mounted() {
    this.fetchCompanyAndHeadAdminInfo()
  },
  methods: {
    async fetchCompanyAndHeadAdminInfo() {
      const companyResponse: CompanyWithMetadataRecord = await makeAuthenticatedRequest(
        getCompanyMetadataUrl(this.companyId)
      )

      const storageResponse: StorageInformationResponse = await makeAuthenticatedRequest(
        getCompanyStorageInformationUrl(this.companyId)
      )

      this.companyWithMetadata = companyResponse
      this.storageInfo = storageResponse

      this.fetchPlanInformation()
    },
    async fetchPlanInformation() {
      if (this.companyWithMetadata) {
        this.totalStorage = await fetchReferenceDataListAndFindDisplayName(
          "storage-amounts",
          this.companyWithMetadata.storageAmountId
        )

        this.planDisplayName = await fetchReferenceDataListAndFindDisplayName(
          "plan-types",
          this.companyWithMetadata.planTypeId
        )
      }
    },
    handleSave(companyUpdate: Partial<CompanyWithMetadataRecord>) {
      if (this.companyWithMetadata) {
        this.showSaveSuccessSnackbar = true
        this.companyWithMetadata = { ...this.companyWithMetadata, ...companyUpdate }
        this.$emit("workspace-updated", { companyId: this.companyWithMetadata.id, name: this.companyWithMetadata.name })
      }
    },
    handleSaveSnackbarClosed() {
      this.showSaveSuccessSnackbar = false
    },
    getRoundedStorageCount(storageUnit: number) {
      return (this.storageInfo!.totalBytesUsed / storageUnit).toFixed(2)
    },
  },
})
</script>

<style lang="scss" scoped>
.plan-info {
  font-size: 2rem;

  .info-item {
    margin: 2rem 0;
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.6rem;
  }

  .highlighted {
    color: var(--secondary);
    font-family: "CamptonMedium";
  }
}
</style>
