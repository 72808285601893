<template>
  <Page title="Photos" class="photo-container" :loading="companyId === ''">
    <template #toolbar><EngieButton @click="handleUploadNewPhotosClicked()">Upload new photos</EngieButton></template>
    <div class="photos-content">
      <PhotosSortBar
        :is-external-user="isExternalUser"
        @photo-sort-clicked="handlePhotoSortClicked"
        @photo-status-clicked="handlePhotoStatusClicked"
        @photo-switch-clicked="handlePhotoSwitchClicked"
        @search="handleSearch"
      />

      <div id="photo-gallery">
        <div v-if="loading" class="photos-loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
        </div>
        <div v-else-if="!loading && totalPhotos === 0" class="d-flex justify-center align-center empty-gallery">
          <h3>No photos currently uploaded.</h3>
        </div>
        <PhotoGallery
          v-else
          :total-photos="totalPhotos"
          :is-external-user="isExternalUser"
          :organized-photos="organizedPhotos"
          :photos="currentPhotos"
          :company-id="companyId"
          @photo-updated="handlePhotosUpdated"
          @photo-location-updated="handlePhotoLocationUpdated"
          @photo-deleted="handlePhotoDeleted"
          @share-submitted="openSharePhotoSuccessSnackbar"
          @end-of-scroll="handleEndOfScroll"
        >
        </PhotoGallery>
        <div v-if="loadingMorePhotos" class="loading-more-photos-spinner">
          <v-progress-circular indeterminate color="primary" :size="40"></v-progress-circular>
        </div>
      </div>
    </div>

    <UploadPhotosDialog
      :open="showUploadDialog"
      :company-id="companyId"
      @new-file-uploaded="newPhotosAddedInUploader"
      @improper-file-uploaded="handleImproperFileUploaded"
      @close-clicked="closeUploadNewPhotosDialog"
    />
    <EngieErrorSnackbar
      v-model="showImproperFileUploaded"
      text="Only .png and .jpeg image files may be uploaded"
      @close="handleErrorSnackbarClosed"
    />
    <EngieErrorSnackbar
      v-model="errorFetchingPhotosSnackbarOpen"
      text="Error fetching all photos"
      @close="handleFetchErrorSnackbarClose"
    />
    <EngieSuccessSnackbar
      v-model="updatePhotoSuccessSnackbarOpen"
      text="Your photo was updated successfully"
      @close="handleUpdatePhotoSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="updatePhotoLocationSuccessSnackbarOpen"
      text="Your photo's location was updated successfully"
      @close="handleUpdatePhotoLocationSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="deletePhotoSuccessSnackbarOpen"
      text="Your photo(s) were deleted successfully"
      @close="handleDeletePhotoSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="sharePhotoSuccessSnackbarOpen"
      text="Your photo(s) were shared successfully"
      @close="handleSharePhotoSuccessSnackbarClose()"
    />
  </Page>
</template>

<script lang="ts">
import Vue from "vue"
import { getAllCompanyPhotosGallery } from "@/services/photoService"
import { PhotoResponse } from "@/types/PhotoResponse"
import { Photo } from "@/types/Photo"
import { getCurrentAuthUserRecord } from "@/services/authService"
import PhotosSortBar from "@/components/Photos/PhotosSortBar.vue"
import PhotoGallery from "@/components/Photos/PhotoGallery.vue"
import EngieSuccessSnackbar from "@/components/EngieSuccessSnackbar.vue"
import Page from "../components/Page.vue"
import EngieButton from "../components/forms/EngieButton.vue"
import EngieErrorSnackbar from "../components/EngieErrorSnackbar.vue"
import UploadPhotosDialog from "../components/Photos/UploadPhotosDialog.vue"
import { SortOption } from "../types/SortOption"
import { PhotoStatusOption } from "../types/PhotoStatusOption"

export default Vue.extend({
  components: {
    Page,
    EngieButton,
    EngieErrorSnackbar,
    UploadPhotosDialog,
    PhotosSortBar,
    PhotoGallery,
    EngieSuccessSnackbar,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userId: null as unknown as string,
      showUploadDialog: false,
      showImproperFileUploaded: false,
      searchInput: "",
      sortSelect: { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
      statusSelect: { label: "Unassigned", fieldValue: true },
      uploadedBy: null as unknown as string,
      errorFetchingPhotosSnackbarOpen: false,
      organizedPhotos: {},
      loading: true,
      loadingMorePhotos: false,
      updatePhotoSuccessSnackbarOpen: false,
      updatePhotoLocationSuccessSnackbarOpen: false,
      deletePhotoSuccessSnackbarOpen: false,
      sharePhotoSuccessSnackbarOpen: false,
      observer: null as unknown as IntersectionObserver,
      pageNumber: 0,
      hasNextPage: true as Boolean,
      photoGallery: null as any,
      currentPhotos: [] as Photo[],
      totalPhotos: 0 as number,
      scrollObservedEl: null as any,
    }
  },
  watch: {
    companyId(currentValue, previousValue) {
      if (currentValue !== previousValue) {
        this.initialize()
        return true
      }
      return false
    },
  },
  async mounted() {
    await this.initialize()
  },
  beforeDestroy() {
    this.removeObserver()
  },
  methods: {
    async fetchUserId() {
      const user = await getCurrentAuthUserRecord()
      return user.id
    },

    handleUploadNewPhotosClicked() {
      this.showUploadDialog = true
    },

    async newPhotosAddedInUploader() {
      await this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },

    async closeUploadNewPhotosDialog() {
      this.showUploadDialog = false
    },
    handleImproperFileUploaded() {
      this.showImproperFileUploaded = true
    },
    handleErrorSnackbarClosed() {
      this.showImproperFileUploaded = false
    },
    async handlePhotoSortClicked(value: SortOption) {
      this.sortSelect = value
      this.loading = true
      await this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },
    async handlePhotoStatusClicked(value: PhotoStatusOption) {
      this.statusSelect = value
      this.loading = true
      await this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },
    async handlePhotoSwitchClicked(value: any) {
      this.loading = true
      if (value === "user") {
        this.uploadedBy = this.userId
      } else {
        this.uploadedBy = ""
      }

      await this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },
    async handleSearch(value: string) {
      this.searchInput = value
      this.loading = true
      await this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },

    handleFetchErrorSnackbarClose() {
      this.errorFetchingPhotosSnackbarOpen = false
    },

    handleUpdatePhotoSuccessSnackbarClose() {
      this.updatePhotoSuccessSnackbarOpen = false
    },

    handleUpdatePhotoLocationSuccessSnackbarClose() {
      this.updatePhotoLocationSuccessSnackbarOpen = false
    },

    handlePhotosUpdated() {
      this.updatePhotoSuccessSnackbarOpen = true
    },

    async handlePhotoDeleted() {
      this.loading = true
      this.deletePhotoSuccessSnackbarOpen = true
      await this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },

    handleDeletePhotoSuccessSnackbarClose() {
      this.deletePhotoSuccessSnackbarOpen = false
    },

    handlePhotoLocationUpdated() {
      this.updatePhotoLocationSuccessSnackbarOpen = true
    },

    openSharePhotoSuccessSnackbar() {
      this.sharePhotoSuccessSnackbarOpen = true
    },

    handleSharePhotoSuccessSnackbarClose() {
      this.sharePhotoSuccessSnackbarOpen = false
    },

    async fetchAllPhotos(
      companyId: string,
      sortByField: string,
      sortDirection: string,
      unassigned: boolean,
      uploadedBy: string,
      pageSize: string,
      pageNumber: string,
      search: string
    ) {
      const response = getAllCompanyPhotosGallery(
        companyId,
        sortByField,
        sortDirection,
        unassigned,
        uploadedBy,
        pageSize,
        pageNumber,
        search
      )
      return response
    },

    getMonthAndYear(dateString: string) {
      const date = new Date(dateString)

      const month = date.toLocaleString("default", { month: "long" })
      const year = date.toLocaleString("default", { year: "numeric" })
      return { month, year }
    },

    updatedGroupedPhotos(yearMap: any, year: string, month: string, photo: Photo) {
      const newMap = new Map<string, Map<string, Photo[]>>(yearMap)
      const yearEntry = newMap.get(year)
      if (yearEntry) {
        const monthEntry = yearEntry.get(month)
        if (monthEntry) {
          yearEntry.set(month, [...monthEntry, photo])
        } else {
          yearEntry.set(month, [photo])
        }
      } else {
        const monthMap = new Map()
        monthMap.set(month, [photo])
        newMap.set(year, monthMap)
      }
      return newMap
    },

    groupByYearAndMonth(photos: any) {
      return photos.reduce((acc: any, currentPhoto: any) => {
        const { month, year } = this.getMonthAndYear(currentPhoto.createdDateTime)

        return this.updatedGroupedPhotos(acc, year, month, currentPhoto)
      }, new Map())
    },

    async resetPhotos() {
      this.pageNumber = 1
      try {
        const photos: PhotoResponse = await this.fetchAllPhotos(
          this.companyId,
          this.sortSelect.fieldValue,
          this.sortSelect.directionValue,
          this.statusSelect.fieldValue,
          this.uploadedBy,
          "48",
          `${this.pageNumber}`,
          this.searchInput
        )
        this.currentPhotos = photos.items
        const updatedPhotos = this.groupByYearAndMonth(photos.items)
        this.totalPhotos = photos.total
        this.organizedPhotos = updatedPhotos
        this.hasNextPage = photos.hasNextPage
        this.loading = false
      } catch (error) {
        this.errorFetchingPhotosSnackbarOpen = true
        this.loading = false
        throw new Error(`${error}`)
      }
    },

    async initialize() {
      this.loading = true
      this.userId = await this.fetchUserId()
      this.uploadedBy = this.userId
      await this.resetPhotos()
      this.removeObserver()
      this.initObserver()
    },

    async addMorePhotos() {
      this.removeObserver()
      this.pageNumber += 1
      try {
        this.loadingMorePhotos = true
        const addedPhotosResponse: PhotoResponse = await this.fetchAllPhotos(
          this.companyId,
          this.sortSelect.fieldValue,
          this.sortSelect.directionValue,
          this.statusSelect.fieldValue,
          this.uploadedBy,
          "48",
          `${this.pageNumber}`,
          this.searchInput
        )
        this.loadingMorePhotos = false

        if (!addedPhotosResponse.hasNextPage) {
          this.hasNextPage = false
          this.currentPhotos = [...this.currentPhotos, ...addedPhotosResponse.items]
          const updatedPhotos = this.groupByYearAndMonth(this.currentPhotos)
          return updatedPhotos
        }

        this.initObserver()
        this.currentPhotos = [...this.currentPhotos, ...addedPhotosResponse.items]
        const updatedPhotos = this.groupByYearAndMonth(this.currentPhotos)
        return updatedPhotos
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    initObserver() {
      this.photoGallery = document.querySelector(".page-content-wrapper")
      this.scrollObservedEl = document.getElementById("last-photo")

      if (this.scrollObservedEl) {
        const options = {
          root: null,
          rootMargin: "200px",
          threshold: 0.1,
        }

        const handleIntersectionEvent = async (entry: any) => {
          if (entry[0].isIntersecting && this.hasNextPage) {
            const photos = await this.addMorePhotos()
            this.organizedPhotos = photos
          }
        }

        this.observer = new IntersectionObserver(handleIntersectionEvent, options)
        this.observer.observe(this.scrollObservedEl)
      }
    },

    removeObserver() {
      if (this.scrollObservedEl) {
        this.observer.unobserve(this.scrollObservedEl)
      }
    },

    async handleEndOfScroll() {
      const photos = await this.addMorePhotos()
      this.organizedPhotos = photos
    },
  },
})
</script>
<style lang="scss" scoped>
.photo-container {
  height: calc(100% - 3rem);
  width: 100%;
}

.empty-gallery {
  // border: 1px solid red;
  padding-top: 15%;
  h3 {
    font-weight: 400;
  }
}

.photos-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 15%;
}

.photos-content {
  height: 100%;
}

.loading-more-photos-spinner {
  display: flex;
  justify-content: center;
}
</style>
