<template>
  <transition name="loading" mode="out-in">
    <div>
      <div class="dashboard-title">
        <slot name="title"></slot>
      </div>
      <div class="d-flex flex-wrap dashboard-content">
        <slot name="content"></slot>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    photos: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String,
      default: "",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedDepartment: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style lang="scss">
.dashboard-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 4rem;
  padding: 2rem 0;

  .dashboard-title {
    padding-right: 0.5rem;
    display: flex;
    justify-content: space-between;
    font-family: "CamptonSemibold";
    font-size: 2.8rem;
    line-height: 6.8rem;
    letter-spacing: 0.01px;
    font-weight: 900;
    color: var(--black);
  }

  .dashboard-content {
    margin-left: -12px;
  }
}

.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
