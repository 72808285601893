export const getPaginationQueryParams = (
  paginated: boolean,
  pageSize: number,
  pageNumber: number,
  sortDirection: string,
  sortBy: string
) =>
  paginated
    ? `?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortDirection=${sortDirection}`
    : `?paginated=false`

const getSearchQueryParam = (search?: string) => (search ? `&search=${search}` : "")

export const getCompaniesUrl = () => `${process.env.VUE_APP_COMPANY_SERVICE_URL}/companies`
export const getCompanyUrl = (companyId: string) => `${process.env.VUE_APP_COMPANY_SERVICE_URL}/companies/${companyId}`
export const getCompanyMetadataUrl = (companyId: string) => `${getCompanyUrl(companyId)}/meta-data`
export const getCompanyUserCountUrl = (companyId: string) => `${getCompanyUrl(companyId)}/user-count`
export const getCompanyPhotosUrl = (companyId: string) => `${getCompanyUrl(companyId)}/photos`
export const getCompanyStorageInformationUrl = (companyId: string) => `${getCompanyUrl(companyId)}/storage-information`
export const getReferenceDataListUrl = (referenceDataListName: string) =>
  `${process.env.VUE_APP_REFERENCE_DATA_SERVICE_URL}/${referenceDataListName}?paginated=false`

export const getUsersUrl = () => `${process.env.VUE_APP_USER_SERVICE_URL}/users`

export const getCreateNewUsersUrl = (companyId: string) =>
  `${process.env.VUE_APP_USER_SERVICE_URL}/users?companyId=${companyId}`

export const getCompanyUsersUrl = (
  companyId: string,
  paginated: boolean,
  pageSize: number,
  pageNumber: number,
  sortDirection: string,
  search: string
) =>
  `${getUsersUrl()}${getPaginationQueryParams(
    paginated,
    pageSize,
    pageNumber,
    sortDirection,
    "name"
  )}&companyId=${companyId}${getSearchQueryParam(search)}`

export const getUserUrl = (userId: string) => `${getUsersUrl()}/${userId}`
export const getUserCSVUrl = (companyId: string) => `${getUsersUrl()}/csv?companyId=${companyId}`
export const getPasswordUpdateUrl = (userId: string) => `${getUserUrl(userId)}/password`

export const getSendForgotPasswordUrl = (emailAddress: string) =>
  `${getUsersUrl()}/${emailAddress}/forgot-password-link`
export const getForgotPasswordRedemptionUrl = (forgotPasswordId: string) =>
  `${getUsersUrl()}/forgot-password-link/${forgotPasswordId}/redemption`

export const getEmailInUseUrl = (emailAddress: string) => `${getUsersUrl()}/email-addresses/${emailAddress}`
export const getHeadAdminUrl = (headAdminId: string) => `${getUsersUrl()}/head-admins/${headAdminId}`
export const getCompanyCSVUrl = () => `${process.env.VUE_APP_COMPANY_SERVICE_URL}/companies/meta-data/csv`
export const getAuthorizedSessionsUrl = () => `${process.env.VUE_APP_USER_SERVICE_URL}/authorized-sessions`
export const getAuthorizedSessionUrl = (userId: string, sessionId: string) =>
  `${process.env.VUE_APP_USER_SERVICE_URL}/authorized-sessions/${userId}/${sessionId}`
export const getUserSessionCleanupUrl = (email: string) => `${getAuthorizedSessionsUrl()}/${email}`
export const getSignupCodeVerificationUrl = (userId: string, code: string) =>
  `${process.env.VUE_APP_USER_SERVICE_URL}/signup-codes?userId=${userId}&code=${code}`
export const getSignupCodeRedemptionUrl = (userId: string, code: string) =>
  `${process.env.VUE_APP_USER_SERVICE_URL}/signup-codes/redemption?userId=${userId}&code=${code}`
export const getCompaniesWithMetadataUrl = (
  paginated: boolean,
  pageSize: number,
  pageNumber: number,
  sortDirection: string,
  search: string
) =>
  `${process.env.VUE_APP_COMPANY_SERVICE_URL}/companies/meta-data${getPaginationQueryParams(
    paginated,
    pageSize,
    pageNumber,
    sortDirection,
    "name"
  )}${getSearchQueryParam(search)}`

export const getGoogleMapsApiKey = () => `${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
