<template>
  <v-dialog
    :retain-focus="false"
    :value="open"
    :max-width="dialogMaxWidth"
    :persistent="true"
    @keydown.enter="handleEnterPressed()"
    @keydown.esc="closeModal()"
  >
    <v-card>
      <!-- The way vuetify mounts dialogs appears to break v-deep, so we have to use style attributes to style dialogs :/ -->
      <v-card-title>
        <span class="dialog-title">{{ dialogTitle }}</span>
        <v-btn class="close-btn" icon :disabled="disableCloseButton" @click="closeModal()">
          <CloseIcon class="close-icon" />
        </v-btn>
      </v-card-title>

      <transition name="loading" mode="out-in">
        <v-card-text v-if="!loading" key="slot-content">
          <slot></slot>
        </v-card-text>
        <v-card-text v-else key="loading-content">
          <div class="loading-container">
            <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
          </div>
        </v-card-text>
      </transition>

      <transition name="error">
        <v-card-text v-if="errorOccurred" class="dialog-error-text">{{ errorMessage }}</v-card-text>
      </transition>

      <v-card-actions :class="{ 'outlined-actions-section': outlinedActionsSection }">
        <div :class="actionButtonsContainerClass">
          <slot name="action-buttons"></slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue"
import CloseIcon from "./common_icons/CloseIcon.vue"

export default Vue.extend({
  components: {
    CloseIcon,
  },
  props: {
    dialogTitle: {
      type: String,
      default: "Title",
    },
    open: {
      type: Boolean,
      default: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: null,
    },
    narrower: {
      type: Boolean,
      default: null,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    alignActionButtonsRight: {
      type: Boolean,
      default: null,
    },
    outlinedActionsSection: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dialogMaxWidth(): string {
      if (this.narrow) {
        return "50vw"
      }

      if (this.narrower) {
        return "40vw"
      }

      return "70vw"
    },
    actionButtonsContainerClass(): string {
      return this.alignActionButtonsRight ? "action-buttons-container align-right" : "action-buttons-container"
    },
  },
  methods: {
    closeModal() {
      this.$emit("dialog-closed")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
  },
})
</script>

<style lang="scss">
// This CSS cannot be scoped because the dialog is mounted near the root of the app -- v-deep will not work
.v-dialog {
  border-radius: 0 !important;
  box-shadow: none !important;

  .v-sheet.v-card {
    border-radius: 0 !important;
  }

  .v-card__title {
    display: flex;
    border-bottom: 1px solid var(--mid-light-grey);

    .dialog-title {
      font-size: 2.9rem;
      margin-right: 3rem;
      word-break: break-word;
      line-height: 1.1;
    }

    .v-btn {
      position: absolute;
      right: 2rem;

      .v-icon {
        font-size: 4rem;
      }
    }
  }

  .v-card__text {
    padding-top: 2rem !important;

    .loading-container {
      display: flex;
      justify-content: center;
    }
  }

  .v-card__actions {
    &.outlined-actions-section {
      padding: 2rem !important;
      border-top: 1px solid var(--mid-light-grey);
    }

    .action-buttons-container {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 1rem;

      &.align-right {
        justify-content: right;
      }
    }
  }

  .dialog-error-text {
    color: red !important;
    font-size: 1.5rem;
    text-align: center;
  }

  .close-icon {
    width: 2rem;
  }

  .action-buttons-container {
    button {
      margin-right: 2rem;
    }
    .v-btn:not(.v-btn--text) .v-btn__content {
      color: white;
    }
  }
}

.error-enter-active,
.error-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.error-enter,
.error-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
