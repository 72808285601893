<template>
  <div v-if="confirmationLoading" class="loading-container">
    <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
  </div>
  <div v-else class="project-information-container">
    <h2>Project</h2>
    <div style="margin-bottom: 3.7rem">
      <h3>{{ selectedProject.name }}</h3>
      <h3>Project No. {{ selectedProject.number }}</h3>
    </div>
    <h2>Department {{ "&amp;" }} Category</h2>
    <div
      v-for="department in selectedDepartmentsWithCategories"
      :key="department.id"
      class="category-checkbox-container"
    >
      <h3 class="header">Department</h3>
      <div class="d-flex align-center" style="margin-bottom: 2rem">
        <v-icon color="secondary">mdi-check-circle</v-icon>
        <h4 class="body">{{ department.name }}</h4>
      </div>
      <h3 class="header">Category</h3>
      <div v-for="category in department.categories" :key="category.id">
        <div class="d-flex align-center" style="margin-bottom: 2rem">
          <v-icon color="secondary">mdi-check-circle</v-icon>
          <div
            class="color-hex-value"
            :style="
              category.colorHexValue
                ? `background-color: ${category.colorHexValue};`
                : `border: 0.5px solid var(--lightest-grey);`
            "
          ></div>
          <h4 class="body">{{ category.name }}</h4>
        </div>
      </div>
    </div>
    <EngieButton color="primary" class="submit-btn" @click="handleSubmitPhotoForAssignment()">
      Add To Project
    </EngieButton>
  </div>
</template>

<script>
import EngieButton from "../forms/EngieButton.vue"

export default {
  components: { EngieButton },
  props: {
    confirmationLoading: {
      type: Boolean,
      default: true,
    },
    selectedProject: {
      type: Object,
      default: () => ({}),
    },
    selectedDepartmentsWithCategories: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleSubmitPhotoForAssignment() {
      this.$emit("submitted")
    },
  },
}
</script>

<style lang="scss" scoped>
.project-information-container {
  height: 53rem;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  h2 {
    color: var(--black);
    font-weight: 400;
    margin-bottom: 2.7rem;
  }
  h3 {
    color: var(--black);
    font-weight: 400;
    font-family: "CamptonRegular";
  }

  .header {
    font-size: 1.8rem;
    font-family: "CamptonMedium";
    margin-bottom: 2rem;
  }
  .body {
    color: var(--navy);
    font-weight: 400;
    margin-left: 1rem;
  }

  .submit-btn {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: calc(100% - 10rem);
    bottom: 3rem;
  }
}

.project-information-container::-webkit-scrollbar {
  display: none;
}

.color-hex-value {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 2px;
  margin-left: 1rem;
}

.loading-container {
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep {
  .engie-button .v-btn {
    min-height: 0px !important;
    min-width: 0px !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.75px !important;
    line-height: 1.6rem !important;
    padding: 0 1rem !important;
  }
}
</style>
