import { Validation } from "vuelidate"
import { ValidationRule } from "vuelidate/lib/validators"
import { DEFAULT_REQUIRED_FIELD_ERROR_MESSAGE } from "./checkRequired"

type CompanySizeValidation = Validation & { required: ValidationRule; minValue: ValidationRule }

export const checkCompanySize = (companySizeProperty: CompanySizeValidation) => {
  if (!companySizeProperty.$dirty) {
    return []
  }

  if (!companySizeProperty.required) {
    return [DEFAULT_REQUIRED_FIELD_ERROR_MESSAGE]
  }

  if (!companySizeProperty.minValue) {
    return ["Please enter a valid company size"]
  }

  return []
}
