<template>
  <div class="action-container">
    <h5>{{ title }}</h5>
    <p>
      {{ description }}
    </p>
    <EngieConfirmationDialogButton
      class="confirm-admin-action-dialog"
      :open="dialogOpen"
      :dialog-title="confirmDialogTitle"
      :button-label="actionButtonLabel"
      text
      :confirming="performingAction"
      :disable-confirmation="confirmationButtonDisabled"
      @dialog-opened="handleDialogOpened()"
      @dialog-closed="handleDialogClosed()"
      @confirmed="handleConfirmed()"
    >
      <div class="dialog-content">
        <h5>Are you sure?</h5>
        <p v-if="!typeConfirmation">{{ dialogConfirmExplanation }}</p>
        <div v-else class="type-confirmation-form-container">
          <p>Type "{{ typeConfirmationValueUppercased }}" to confirm</p>
          <EngieTextInput v-model="typeConfirmationValue" label="" />
        </div>
        <slot></slot>
      </div>
    </EngieConfirmationDialogButton>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import EngieConfirmationDialogButton from "./EngieConfirmationDialogButton.vue"
import EngieTextInput from "./forms/EngieTextInput.vue"

export default Vue.extend({
  components: {
    EngieConfirmationDialogButton,
    EngieTextInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    confirmDialogTitle: {
      type: String,
      required: true,
    },
    actionButtonLabel: {
      type: String,
      required: true,
    },
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    performingAction: {
      type: Boolean,
      default: false,
    },
    dialogConfirmExplanation: {
      type: String,
      required: true,
    },
    /**
     * When this property is set, the value specified will be required to be typed by the user in a text input to confirm.
     */
    typeConfirmation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      typeConfirmationValue: "",
    }
  },
  computed: {
    typeConfirmationValueUppercased(): string {
      return this.typeConfirmation?.toUpperCase()
    },
    confirmationButtonDisabled(): boolean {
      return (
        this.typeConfirmation !== null &&
        this.typeConfirmationValue.toUpperCase() !== this.typeConfirmationValueUppercased
      )
    },
  },
  methods: {
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleDialogClosed() {
      this.$emit("dialog-closed")
    },
    handleConfirmed() {
      this.$emit("confirmed")
    },
  },
})
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  h5 {
    width: 100%;
  }

  p {
    max-width: 60%;
  }

  .confirm-admin-action-dialog {
    margin-left: auto;
  }
}

.dialog-content {
  margin: 4rem 0;

  h5,
  p {
    text-align: center;
  }
}
</style>
