<template>
  <div>
    <div v-if="loading" class="project-category-photo-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else class="container" style="">
      <div class="d-flex flex-column header-container" style="height: 16rem">
        <div class="d-flex justify-space-between align-center" style="padding: 0 3rem; height: 5rem">
          <h2 class="header">
            <router-link class="header-link" to="" @click.native="handleProjectRouteClick">
              All Departments
            </router-link>
            > No Department
          </h2>
          <NoDeptProjectPhotoSwitch />
        </div>
        <v-divider style="width: 100%; margin-top: 1rem" />
        <div class="search-toolbar">
          <EngieSearchInput class="search" :label="'Through Notes'" @input="debouncedHandler" />
        </div>
      </div>
      <div class="project-photo-detail-content-container">
        <transition name="route" mode="out-in">
          <div v-if="routerLoading" class="project-category-photo-loading-spinner-container">
            <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
          </div>
          <router-view
            v-else
            :no-department="true"
            :markers="markers"
            :photos="photos"
            :search-loading="loading"
            :company-id="companyId"
            :user-home-company-id="userHomeCompanyId"
            :is-external-user="isExternalUser"
            :project="project"
            :departments="departments"
            :selected-department="selectedDepartment"
            @photo-notes-updated="handlePhotoNotesUpdated"
            @photo-location-updated="handlePhotoLocationUpdated"
            @photo-updated="handlePhotoUpdated"
            @reassign="handleAssignPhotoDialogOpen"
            @new-marker-location-added="handleNewMarkerLocationAdded"
            @photos-deleted="handleConfirmationDialogOpen"
            @share-submitted="handleSharePhotoSuccessSnackbarOpen"
          />
        </transition>
      </div>
    </div>
    <EngieConfirmationDialogButton
      :open="confirmationDialogOpen"
      :dialog-title="`Delete Photo(s)`"
      :narrower="true"
      :disable-confirmation="!confirmation"
      style="display: none"
      @confirmed="handlePhotoDelete"
      @dialog-closed="handleConfirmationDialogClose"
    >
      <div class="confirmation-content-container">
        <h3 style="font-weight: 400">Are you sure?</h3>
        <p>Type "DELETE" to confirm</p>
        <EngieTextInput v-model="confirmationInput" class="confirmation-input" :label="''"></EngieTextInput>
      </div>
    </EngieConfirmationDialogButton>
    <AssignPhotoDialog
      :is-external-user="isExternalUser"
      :company-id="companyId"
      :dialog-open="assignPhotoDialogOpen"
      :selected-photos-for-assignment="selectedPhotosForAssignment"
      @photo-updated="handlePhotoUpdated"
      @close="handleAssignPhotoDialogClose"
    />
    <EngieSuccessSnackbar
      v-model="updatePhotoSuccessSnackbarOpen"
      text="Your photo was updated successfully"
      @close="handleUpdatedPhotoSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="updatePhotoLocationSuccessSnackbarOpen"
      text="Your photo location was updated successfully"
      @close="handleUpdatedPhotoLocationSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="deletePhotoSuccessSnackbarOpen"
      text="Your photo(s) were deleted successfully"
      @close="handleDeletePhotoSuccessSnackbarClose()"
    />
    <EngieErrorSnackbar
      v-model="deletePhotoErrorSnackbarOpen"
      text="Your photo(s) were unable to be deleted successfully"
      @close="handleDeletePhotoErrorSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="sharePhotoSuccessSnackbarOpen"
      text="Your photo(s) were shared successfully"
      @close="handleSharePhotoSuccessSnackbarClose()"
    />
  </div>
</template>

<script>
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompanyUrl } from "@/util/urls"
import EngieSearchInput from "@/components/forms/EngieSearchInput.vue"
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import { getProjectMarkersNoDepartment } from "@/services/projectService"
import AssignPhotoDialog from "@/components/Photos/AssignPhotoDialog.vue"
import EngieSuccessSnackbar from "@/components/EngieSuccessSnackbar.vue"
import EngieConfirmationDialogButton from "@/components/EngieConfirmationDialogButton.vue"
import EngieTextInput from "@/components/forms/EngieTextInput.vue"
import EngieErrorSnackbar from "@/components/EngieErrorSnackbar.vue"
import NoDeptProjectPhotoSwitch from "./NoDeptProjectPhotoSwitch.vue"

export default {
  components: {
    EngieSearchInput,
    AssignPhotoDialog,
    EngieSuccessSnackbar,
    EngieConfirmationDialogButton,
    EngieTextInput,
    EngieErrorSnackbar,
    NoDeptProjectPhotoSwitch,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: null,
    },
    selectedDepartment: {
      type: Object,
      default: null,
    },
    selectedCategory: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    photoSearchInput: "",
    markers: [],
    photos: [],
    routerLoading: false,
    header: "",
    assignPhotoDialogOpen: false,
    selectedPhotosForAssignment: [],
    updatePhotoSuccessSnackbarOpen: false,
    updatePhotoLocationSuccessSnackbarOpen: false,
    confirmationDialogOpen: false,
    confirmationInput: "",
    deletePhotoSuccessSnackbarOpen: false,
    deletePhotoErrorSnackbarOpen: false,
    photosForDelete: [],
    sharePhotoSuccessSnackbarOpen: false,
  }),
  computed: {
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },
  },
  created() {
    this.debouncedHandler = debounce(async event => {
      await this.searchProjectPhotos(event)
    }, DEBOUNCE_INTERVAL)
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async setMarkers() {
      const markers = await this.fetchNoDepartmentMarkers(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false",
        null
      )
      return markers.items
    },

    async fetchNoDepartmentMarkers(companyId, projectId, sortByField, sortDirection, paginated, search) {
      try {
        const response = await getProjectMarkersNoDepartment(
          companyId,
          projectId,
          sortByField,
          sortDirection,
          paginated,
          search
        )
        return response
      } catch (error) {
        throw new Error(`error`)
      }
    },

    async initialize() {
      this.loading = true
      const markers = await this.fetchNoDepartmentMarkers(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false"
      )
      this.markers = markers.items
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.loading = false
    },

    async searchProjectPhotos(value) {
      this.photoSearchInput = value
      const markers = await this.fetchNoDepartmentMarkers(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false",
        this.photoSearchInput
      )
      this.markers = markers.items
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.routerLoading = false
    },

    async handlePhotoDetailSelectSubmit(value) {
      this.selectedCategories = value
      this.markers = await this.setMarkers()
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
    },

    handleAssignPhotoDialogOpen(value) {
      this.routerLoading = true
      this.selectedPhotosForAssignment = value
      this.assignPhotoDialogOpen = true
    },

    handleAssignPhotoDialogClose() {
      this.selectedPhotoForAssignment = []
      this.assignPhotoDialogOpen = false
      this.routerLoading = false
    },

    async handlePhotoUpdated() {
      this.loading = true
      this.selectedPhotosForAssignment = []
      this.markers = await this.setMarkers()
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.updatePhotoSuccessSnackbarOpen = true
      this.loading = false
      this.routerLoading = false
    },

    handleUpdatedPhotoSuccessSnackbarClose() {
      this.updatePhotoSuccessSnackbarOpen = false
    },

    handlePhotoNotesUpdated() {
      this.updatePhotoSuccessSnackbarOpen = true
    },

    handlePhotoLocationUpdated() {
      this.updatePhotoLocationSuccessSnackbarOpen = true
    },

    handleUpdatedPhotoLocationSuccessSnackbarClose() {
      this.updatePhotoLocationSuccessSnackbarOpen = false
    },

    async handleNewMarkerLocationAdded() {
      this.routerLoading = true
      this.markers = await this.setMarkers()
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.updatePhotoLocationSuccessSnackbarOpen = true
      this.routerLoading = false
    },

    handleProjectRouteClick() {
      this.$router.push({
        name: "ProjectDepartmentPhotos",
        params: { projectId: this.project.id },
      })
    },

    handleDepartmentRouteClick() {
      this.$router.push({
        name: "ProjectDeptCategoryPhotos",
        params: { projectId: this.project.id, departmentId: this.$route.params.departmentId },
      })
    },

    handleConfirmationDialogClose() {
      this.confirmationDialogOpen = false
      this.photosForDelete = []
    },

    handleConfirmationDialogOpen(value) {
      this.confirmationDialogOpen = true
      this.photosForDelete = value
    },

    async handlePhotoDelete() {
      this.routerLoading = true
      if (this.photosForDelete.length <= 1) {
        const response = await this.deletePhoto(this.companyId, this.photosForDelete[0])
        this.handleConfirmationDialogClose()
        this.deletePhotoSuccessSnackbarOpen = true
        this.markers = await this.setMarkers()
        this.photos = this.markers.map(marker => {
          if (marker.latitude === null) {
            return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
          }
          return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
        })
        this.routerLoading = false
        return response
      }
      const response = await this.sendDeleteRequestForEachPhotoId(this.photosForDelete)
      this.handleConfirmationDialogClose()
      this.deletePhotoSuccessSnackbarOpen = true
      this.markers = await this.setMarkers()
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.routerLoading = false
      return response
    },

    async deletePhoto(companyId, photoId) {
      try {
        const response = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/photos/${photoId}`, "DELETE")
        return response
      } catch (error) {
        this.deletePhotoErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    sendDeleteRequestForEachPhotoId(photoIdsArray) {
      const promises = photoIdsArray.map(async photoId => {
        const response = await this.deletePhoto(this.companyId, photoId)
        return response
      })
      return Promise.all(promises)
    },

    handleDeletePhotoSuccessSnackbarClose() {
      this.deletePhotoSuccessSnackbarOpen = false
    },

    handleDeletePhotoErrorSnackbarClose() {
      this.deletePhotoErrorSnackbarOpen = false
    },

    handleSharePhotoSuccessSnackbarOpen() {
      this.sharePhotoSuccessSnackbarOpen = true
    },

    handleSharePhotoSuccessSnackbarClose() {
      this.sharePhotoSuccessSnackbarOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  font-family: "CamptonSemiBold";
  font-size: 2.8rem;
  font-weight: bold;
}

.header-link {
  text-decoration: none;
  color: var(--black);
  transition: 0.25s;
  &:hover {
    color: var(--primary);
  }
}

.project-photo-detail-content-container {
  min-height: 72.2rem;
  height: 100%;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 6rem);
  margin-top: 6rem;
  max-width: 100% !important;
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
}

.search {
  margin-right: 2rem;
  &::v-deep {
    .v-text-field,
    .v-text-field--solo,
    .v-input--dense,
    .v-input__control {
      min-height: 3.2rem !important;
      width: 32rem;
    }
    .v-input__prepend-outer {
      margin: auto !important;
      padding-bottom: 0.5rem;
      display: flex;
    }
    .v-input__control {
      padding-left: 1rem;
    }
  }
}

.search-toolbar {
  display: flex;
  align-items: center;
  padding-left: 4.5rem;
  height: 7rem;
  box-shadow: none;
}

.project-category-photo-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
