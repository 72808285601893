<template>
  <div>
    <ProjectDashboardContent>
      <template #title>
        <div>
          <router-link class="header-link" to="" @click.native="handleProjectRouteClick">All Departments</router-link> >
          {{ selectedDepartment.name }}
        </div>
      </template>
      <template #content>
        <div v-if="categories.length === 0 || loading === true" class="project-department-loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
        </div>
        <div v-else class="d-flex flex-wrap" style="width: 100%">
          <div v-for="category in categories" :key="category.id" class="card-container">
            <ProjectDashCard>
              <template #img-content>
                <router-link to="" @click.native="handleCardClick(category)">
                  <v-img
                    v-if="category.latestPhotoThumbnailUrl"
                    style="border-radius: 15px"
                    height="100%"
                    :src="category.latestPhotoThumbnailUrl"
                  ></v-img>
                  <v-img
                    v-else
                    style="border-radius: 15px; background-color: var(--lightest-grey)"
                    height="100%"
                  ></v-img>
                </router-link>
              </template>
              <template #text-content>
                <v-card-title class="title-content">
                  <v-icon v-if="category.colorHexValue" :style="{ color: category.colorHexValue }" class="map-marker"
                    >mdi-map-marker</v-icon
                  >
                  <v-icon v-else style="color: var(--grey)" class="map-marker">mdi-map-marker-outline</v-icon>
                  {{ category.name }}
                </v-card-title>
                <v-btn depressed rounded class="edit-btn" @click="handleEditCategoryDialogOpen(category)">
                  <EditIcon style="height: 2rem" />
                </v-btn>
              </template>
            </ProjectDashCard>
          </div>
          <div class="btn-container">
            <v-btn height="100%" class="d-flex btn" depressed @click="handleCreateCategoryDialogOpen()">
              <div class="d-flex flex-column align-center">
                <EngiePlus style="height: 34px" />
                <p>Add Category</p>
              </div>
            </v-btn>
          </div>
          <CategoryDialog
            :dialog-title="'Edit Category'"
            :button-title="'Save Changes'"
            :dialog-open="editCategoryDialogOpen"
            :selected-department="selectedDepartment"
            :selected-category="editSelectedCategory"
            @submit="handleSubmitEditedCategory"
            @dialog-opened="handleEditCategoryDialogOpen"
            @dialog-closed="handleEditCategoryDialogClose"
          />
          <CategoryDialog
            :dialog-title="'Create Category'"
            :button-title="'Create Category'"
            :dialog-open="createCategoryDialogOpen"
            :selected-department="selectedDepartment"
            @submit="handleCreateCategory"
            @dialog-opened="handleCreateCategoryDialogOpen"
            @dialog-closed="handleCreateCategoryDialogClose"
          />
          <EngieSuccessSnackbar
            v-model="createCategorySuccessSnackbarOpen"
            text="Your Category was created successfully"
            @close="handleCreateCategorySuccessSnackbarClose()"
          />
          <EngieErrorSnackbar
            v-model="createCategoryErrorSnackbarOpen"
            text="An error occured while creating your Category"
            @close="handleCreateCategoryErrorSnackbarClose()"
          />
          <EngieSuccessSnackbar
            v-model="editCategorySuccessSnackbarOpen"
            text="Your Category was edited successfully"
            @close="handleEditCategorySuccessSnackbarClose()"
          />
          <EngieErrorSnackbar
            v-model="editCategoryErrorSnackbarOpen"
            text="An error occured while editing your Category"
            @close="handleEditCategoryErrorSnackbarClose()"
          />
        </div>
      </template>
    </ProjectDashboardContent>
  </div>
</template>

<script>
import Vue from "vue"
import EngiePlus from "@/components/common_icons/PlusIcon.vue"
import ProjectDashboardContent from "@/components/ProjectDashboard/ProjectDashboardContent.vue"
import ProjectDashCard from "@/components/ProjectDashboard/ProjectDashCard.vue"
import {
  getAllDepartmentCategoriesGallery,
  patchDepartmentCategory,
  postDepartmentCategory,
} from "@/services/projectService"
import EngieSuccessSnackbar from "@/components/EngieSuccessSnackbar.vue"
import EngieErrorSnackbar from "@/components/EngieErrorSnackbar.vue"
import CategoryDialog from "@/components/ProjectDashboard/CategoryDialog.vue"
import EditIcon from "@/components/common_icons/EditIcon.vue"

export default Vue.extend({
  components: {
    ProjectDashCard,
    ProjectDashboardContent,
    EngieSuccessSnackbar,
    EngieErrorSnackbar,
    CategoryDialog,
    EngiePlus,
    EditIcon,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: String,
      default: "",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedDepartment: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {
      newCategoryName: "",
      newCategoryHexColor: "",
      createCategoryDialogOpen: false,
      editCategoryDialogOpen: false,
      loading: false,
      createCategorySuccessSnackbarOpen: false,
      createCategoryErrorSnackbarOpen: false,
      editCategorySuccessSnackbarOpen: false,
      editCategoryErrorSnackbarOpen: false,
      editSelectedCategory: null,
    }
  },
  computed: {
    disableSubmit() {
      if (this.editSelectedCategoryName) {
        return false
      }
      return true
    },
    departmentId() {
      return this.$route.params.departmentId
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    handleCardClick(category) {
      if (category.name === "No category") {
        this.$emit("set-department-no-category", this.selectedDepartment.id)
        this.$router.push({
          name: "ProjectPhotoDetailMap",
          params: { projectId: this.project.id, departmentId: this.selectedDepartment.id },
        })
      } else {
        this.$emit("set-category", category)
        this.$router.push({
          name: "ProjectPhotoDetailMap",
          params: { projectId: this.project.id, departmentId: this.selectedDepartment.id, categoryId: category.id },
        })
      }
    },

    async fetchCompanyDepartmentCategories(companyId, projectId, departmentId) {
      const response = await getAllDepartmentCategoriesGallery(companyId, projectId, departmentId, true)
      return response
    },

    initialize() {
      this.loading = true
      if (this.categories.length === 0) {
        this.$emit("department-selected", this.$route.params.departmentId)
      }
      this.loading = false
    },

    async createProjectDepartmentCategory(companyId, departmentId, categoryData) {
      const response = await postDepartmentCategory(companyId, departmentId, categoryData)
      return response
    },

    async handleCreateCategory(value) {
      this.loading = true
      try {
        await this.createProjectDepartmentCategory(this.companyId, this.departmentId, value)
        this.resetCategoriesOnCreation()
      } catch (error) {
        this.createCategoryErrorSnackbarOpen = true
        this.handleCreateCategoryDialogClose()
      }
      this.loading = false
    },

    resetCategoriesOnCreation() {
      this.loading = true
      this.$emit("category-updated")
      this.createCategoryDialogOpen = false
      this.createCategorySuccessSnackbarOpen = true
    },

    handleCreateCategorySuccessSnackbarClose() {
      this.createCategorySuccessSnackbarOpen = false
    },

    handleCreateCategoryErrorSnackbarClose() {
      this.createCategoryErrorSnackbarOpen = false
    },

    handleCreateCategoryDialogOpen() {
      this.createCategoryDialogOpen = true
    },

    handleCreateCategoryDialogClose() {
      this.createCategoryDialogOpen = false
    },

    async editProjectDepartmentCategory(companyId, departmentId, categoryId, categoryData) {
      const response = await patchDepartmentCategory(companyId, departmentId, categoryId, categoryData)
      return response
    },

    async resetCategoriesOnEdit() {
      this.loading = true
      this.$emit("category-updated")
      this.editCategoryDialogOpen = false
      this.editCategorySuccessSnackbarOpen = true
    },

    async handleSubmitEditedCategory(value) {
      this.loading = true
      try {
        await this.editProjectDepartmentCategory(this.companyId, this.departmentId, this.editSelectedCategory.id, value)
        await this.resetCategoriesOnEdit()
        this.loading = false
      } catch (error) {
        this.editCategoryDialogOpen = false
        this.editCategoryErrorSnackbarOpen = true
        this.loading = false
      }
    },

    handleEditCategoryDialogOpen(category) {
      this.editSelectedCategory = category
      this.editCategoryDialogOpen = true
    },

    handleEditCategoryDialogClose() {
      this.editSelectedCategory = null
      this.editCategoryDialogOpen = false
    },

    handleEditCategorySuccessSnackbarClose() {
      this.editCategorySuccessSnackbarOpen = false
    },

    closeModal() {
      this.$emit("dialog-closed")
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },

    handleProjectRouteClick() {
      this.$router.push({
        name: "ProjectDepartmentPhotos",
        params: { projectId: this.project.id },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.dialog-content-category {
  font-size: 1.4rem;
  padding: 1.5rem;
}
.card-container {
  margin: 0 12px 24px 12px;
  height: 25.7rem;
}
.edit-btn {
  z-index: 999;
}

.header-link {
  text-decoration: none;
  color: var(--black);
  transition: 0.25s;
  &:hover {
    color: var(--primary);
  }
}

.card {
  height: 24.4rem;
  border-radius: 1.5rem;
}

.text-box {
  background-color: transparent;
  height: 5.1rem;
  padding: 0 1.6rem;
  position: absolute;
  bottom: -1px;
  display: flex;
  color: var(--black) !important;
  background-color: white;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}

.v-card__title {
  font-family: "CamptonMedium";
  font-size: 1.5rem;
  line-height: 1.4rem;
  font-weight: 400;
  z-index: 3;
  padding: 0;
}

.project-department-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.dialog-title {
  font-weight: 800;
}

.dialog-content-department {
  padding: 1.5rem;
}

.subheader {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--black);
  margin-bottom: 1rem;
  font-weight: 400;
}

.submit-btn {
  text-align: center;
}

.edit-btn {
  z-index: 2;
  background-color: white !important;
  padding: 2rem 1rem !important;
  border-radius: 100%;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
}

.map-marker {
  margin-right: 0.5rem;
}

::v-deep {
  .engie-text-input:not(.animated-label),
  .v-text-field {
    padding-top: 0px !important;
    .v-input__slot {
      border: 2px solid var(--grey);
    }
  }
}

.btn-container {
  height: 24.4rem;
  width: 26rem;
  margin: 0 12px 24px 12px;

  .btn {
    border-radius: 1.5rem;
    width: 100%;
    text-transform: none;

    p {
      color: rgba(43, 43, 43, 0.5);
      margin: 1rem 0 0 0 !important;
      font-size: 1.2rem;
    }
  }
}
</style>
