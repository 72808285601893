<template>
  <Page title="Super Admin">
    <template #toolbar
      ><AddNewAccountDialogButton
        :open="addNewAccountDialogOpen"
        @dialog-opened="handleDialogOpened()"
        @dialog-closed="handleDialogClosed()"
        @company-created="handleCompanyCreated($event)"
        @company-form-error-occurred="() => (showAddNewAccountError = true)"
        @company-creation-error-occurred="() => (showAddNewAccountError = true)"
    /></template>
    <div>
      <PageToolbar
        v-model="companySearchQuery"
        :show="companies !== null"
        search-label="companies"
        @input="handleCompanySearchQueryChanged($event)"
        @download-csv="downloadCsv()"
        >EngieCam currently has {{ totalCompanyCountOverall }} active account{{ companyPluralization }}
      </PageToolbar>
      <CompanyTable
        :companies="companies"
        :total-count="queryResultCompanyCount"
        :loading="loadingCompanies"
        @page-number-updated="handleCompanyPageNumberUpdated($event)"
        @page-size-updated="handleCompanyPageSizeUpdated($event)"
        @page-sort-descending-updated="handleCompanySortDescendingUpdated($event)"
        @edit-company-clicked="handleEditCompanyClicked($event)"
      />
      <EngieSuccessSnackbar
        v-model="showCreateCompanySuccessSnackbar"
        :text="`${newCompanyName} created successfully!`"
        @close="handleCompanyCreationSuccessSnackbarClosed()"
      />
      <EngieSuccessSnackbar
        v-model="showUpdateCompanySuccessSnackbar"
        :text="`${updatedCompanyName} updated successfully`"
        @close="handleCompanyUpdateSuccessSnackbarClosed()"
      />
      <AddEditAccountDialog
        :open="editCompanyDialogOpen"
        :dialog-title="editCompanyDialogTitle"
        :edit-mode="true"
        :company="companyToEdit"
        @dialog-closed="handleEditCompanyDialogClosed()"
        @company-updated="handleCompanyUpdated($event)"
        @company-paused="handleCompanyPaused($event)"
        @company-reactivated="handleCompanyReactivated($event)"
      />
    </div>
    <EngieErrorSnackbar
      v-model="showAddNewAccountError"
      text="Please correct the form errors before submitting"
      @close="() => (showAddNewAccountError = false)"
    />
    <EngieErrorSnackbar
      v-model="showErrorCreatingCompanySnackbar"
      text="An error occurred while saving the new account"
      @close="handleCreateCompanyErrorSnackbarClosed()"
    />
  </Page>
</template>

<script lang="ts">
import Vue from "vue"
import { debounce } from "vue-debounce"
import { ReferenceDataItem } from "@/types/ReferenceDataItem"
import { PaginatedResponse } from "@/types/PaginatedResponse"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getReferenceDataDisplayName, getReferenceDataValues } from "@/util/referenceDataUtils"
import { getCompaniesWithMetadataUrl, getCompanyCSVUrl } from "@/util/urls"
import Page from "../components/Page.vue"
import AddNewAccountDialogButton from "../components/SuperAdminDashboard/AddNewAccountDialogButton.vue"
import CompanyTable from "../components/SuperAdminDashboard/CompanyTable.vue"
import { CompanyRecord } from "../types/CompanyRecord"
import EngieSuccessSnackbar from "../components/EngieSuccessSnackbar.vue"
import EngieErrorSnackbar from "../components/EngieErrorSnackbar.vue"
import { getSortDirection } from "../util/getSortDirection"
import { DEBOUNCE_INTERVAL } from "../constants/debounceInternval"
import AddEditAccountDialog from "../components/SuperAdminDashboard/AddEditAccountDialog.vue"
import { findByIdAndReplace } from "../util/arrayUtils"
import PageToolbar from "../components/PageToolbar.vue"
import { pluralize } from "../util/pluralize"
import { downloadFile } from "../util/downloadFile"

export default Vue.extend({
  components: {
    Page,
    AddNewAccountDialogButton,
    CompanyTable,
    EngieSuccessSnackbar,
    EngieErrorSnackbar,
    AddEditAccountDialog,
    PageToolbar,
  },
  data() {
    return {
      companies: null as null | CompanyRecord[],
      totalCompanyCountOverall: 0,
      queryResultCompanyCount: 0,
      totalCompanyCountOverallFetched: false,
      addNewAccountDialogOpen: false,
      planTypes: [] as ReferenceDataItem[],
      storageAmounts: [] as ReferenceDataItem[],
      showCreateCompanySuccessSnackbar: false,
      showUpdateCompanySuccessSnackbar: false,
      newCompanyName: "",
      updatedCompanyName: "",
      companyPageNumber: 1,
      companyPageSize: 10,
      companyNameSortDirection: "asc",
      loadingCompanies: true,
      companiesPaginated: true,
      companySearchQuery: "",
      fetchCompaniesDebounced: debounce(() => {}, 0),
      editCompanyDialogOpen: false,
      companyIdToEdit: "",
      showAddNewAccountError: false,
      showErrorCreatingCompanySnackbar: false,
    }
  },
  computed: {
    companyPluralization(): string {
      return pluralize(this.totalCompanyCountOverall)
    },
    companyToEdit(): undefined | CompanyRecord {
      return this.companies?.find(company => company.id === this.companyIdToEdit)
    },
    editCompanyDialogTitle(): string {
      return this.companyToEdit ? `Edit ${this.companyToEdit.name}` : ""
    },
  },
  created() {
    this.fetchCompaniesDebounced = debounce(this.fetchCompanies, DEBOUNCE_INTERVAL)
    this.initialize()
  },
  methods: {
    async initialize() {
      await this.fetchReferenceDataLists()
      this.fetchCompanies()
    },
    async fetchReferenceDataLists() {
      const planTypes = await getReferenceDataValues("plan-types")
      const storageAmounts = await getReferenceDataValues("storage-amounts")

      this.planTypes = planTypes
      this.storageAmounts = storageAmounts
    },
    async mapCompaniesToReferenceData(companies: CompanyRecord[]) {
      return companies.map(company => ({
        ...company,
        planDisplayName: this.planTypes.find(plan => plan.id === company.planTypeId)?.displayName,
        storageAmountDisplayName: this.storageAmounts.find(
          storageAmount => storageAmount.id === company.storageAmountId
        )?.displayName,
      }))
    },
    async fetchCompanies() {
      this.loadingCompanies = true

      const companiesResponse = await makeAuthenticatedRequest<PaginatedResponse<CompanyRecord>>(
        getCompaniesWithMetadataUrl(
          this.companiesPaginated,
          this.companyPageSize,
          this.companyPageNumber,
          this.companyNameSortDirection,
          this.companySearchQuery
        )
      )

      if (!this.totalCompanyCountOverallFetched) {
        this.totalCompanyCountOverallFetched = true
        this.totalCompanyCountOverall = companiesResponse.total
      }

      this.queryResultCompanyCount = companiesResponse.total

      const mappedCompanies = await this.mapCompaniesToReferenceData(companiesResponse.items)

      this.companies = mappedCompanies

      this.loadingCompanies = false
    },
    handleDialogOpened() {
      this.addNewAccountDialogOpen = true
    },
    handleDialogClosed() {
      this.addNewAccountDialogOpen = false
    },
    handleCompanyCreationSuccessSnackbarClosed() {
      this.showCreateCompanySuccessSnackbar = false
    },
    handleCompanyUpdateSuccessSnackbarClosed() {
      this.showUpdateCompanySuccessSnackbar = false
    },
    getPlanTypeAndStorageAmountForNewCompany(company: CompanyRecord) {
      const planDisplayName = getReferenceDataDisplayName(this.planTypes, company.planTypeId)
      const storageAmountDisplayName = getReferenceDataDisplayName(this.storageAmounts, company.storageAmountId)

      return { planDisplayName, storageAmountDisplayName }
    },
    handleCompanyCreated(company: CompanyRecord) {
      this.handleDialogClosed()

      // Only the plan type ID and storage amount ID is saved, so we need to retrieve the display names to load into the table
      const { planDisplayName, storageAmountDisplayName } = this.getPlanTypeAndStorageAmountForNewCompany(company)

      const companyWithPlanAndStorageAmountDisplayName = {
        ...company,
        planDisplayName,
        storageAmountDisplayName,
      }

      this.companies = [...(this.companies as CompanyRecord[]), companyWithPlanAndStorageAmountDisplayName]
      this.totalCompanyCountOverall += 1
      this.newCompanyName = company.name
      this.showCreateCompanySuccessSnackbar = true
    },
    handleCompanyPageNumberUpdated(pageNumber: number) {
      this.companyPageNumber = pageNumber

      this.fetchCompanies()
    },
    handleCompanyPageSizeUpdated(pageSize: number) {
      if (pageSize === -1) {
        this.companiesPaginated = false
      } else {
        this.companiesPaginated = true
        this.companyPageSize = pageSize
      }

      this.fetchCompanies()
    },
    handleCompanySortDescendingUpdated(sortDescending: boolean) {
      this.companyNameSortDirection = getSortDirection(sortDescending)

      this.fetchCompanies()
    },
    handleCompanySearchQueryChanged() {
      this.fetchCompaniesDebounced()
    },
    handleEditCompanyClicked(companyId: string) {
      this.companyIdToEdit = companyId
      this.editCompanyDialogOpen = true
    },
    handleEditCompanyDialogClosed() {
      this.editCompanyDialogOpen = false
    },
    handleCompanyUpdated(updatedCompany: CompanyRecord) {
      this.showUpdateCompanySuccessSnackbar = true
      this.updatedCompanyName = updatedCompany.name

      if (this.companies) {
        this.editCompanyDialogOpen = false

        const updatedCompanyIndex = this.companies.findIndex(company => company.id === this.companyIdToEdit)

        this.companies = [
          ...this.companies.slice(0, updatedCompanyIndex),
          { ...this.companies[updatedCompanyIndex], ...updatedCompany },
          ...this.companies.slice(updatedCompanyIndex + 1),
        ]
      }
    },
    async downloadCsv() {
      const response = await makeAuthenticatedRequest(getCompanyCSVUrl(), "GET", null, false, "blob")

      downloadFile(response, "text/csv", `Engiecam Company Report ${new Date().toDateString()}.csv`)
    },
    setCompanyPausedDate(companyId: string, date: Date | null) {
      if (this.companies) {
        const existingCompany = this.companies.find(company => company.id === companyId)

        this.companies = findByIdAndReplace(this.companies, companyId, {
          ...existingCompany,
          pausedDateTime: date,
        })
      }
    },
    handleCompanyPaused(companyId: string) {
      this.setCompanyPausedDate(companyId, new Date())
    },
    handleCompanyReactivated(companyId: string) {
      this.setCompanyPausedDate(companyId, null)
    },
  },
})
</script>
