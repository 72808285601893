<template>
  <div class="wrapper d-flex flex-column align-center">
    <v-card class="card">
      <div class="d-flex justify-center mb-12">
        <img class="engiecam-logo" src="../assets/img/EngieCamLogo.svg" alt="EngieCam logo" />
      </div>
      <h1 class="mb-2">{{ title }}</h1>
      <p class="mb-10">Last updated: {{ lastUpdated }}</p>
      <slot />
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  background-color: var(--light-grey);
  overflow-y: auto;

  .card {
    width: 50vw;
    margin: 2rem 0;
    padding: 4rem;
    .engiecam-logo {
      max-width: 20rem;
    }

    &::v-deep {
      & p {
        text-align: justify;
      }
      & h2 {
        border-bottom: 1px solid var(--navy);
        margin: 4rem 0 2rem 0;
      }
      & h4 {
        color: var(--navy);
        font-size: 1.6rem;
      }
      & dl {
        padding-left: 4rem;
      }
      & ul {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  .wrapper {
    .card {
      width: 80vw;
    }
  }
}

@media (max-device-width: 812px) {
  .wrapper {
    .card {
      width: 100%;
      padding: 2rem;
      margin-top: 0;
      padding-top: 6rem;

      &::v-deep {
        & dl {
          padding-left: 2rem;
        }
      }

      &::v-deep {
        & h1 {
          font-size: 3rem;
        }
        & h2 {
          font-size: 2rem;
        }
        & h3 {
          font-size: 1.8rem;
        }
      }
    }
  }
}
</style>
