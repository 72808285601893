<template>
  <EngieSnackbar
    absolute
    :value="value"
    :color="'red'"
    :text="text"
    @input="handleInput($event)"
    @close="handleClose()"
  />
</template>

<script lang="ts">
import Vue from "vue"
import EngieSnackbar from "./EngieSnackbar.vue"

export default Vue.extend({
  components: {
    EngieSnackbar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInput(event: Event) {
      this.$emit("input", event)
    },
    handleClose() {
      this.$emit("close")
    },
  },
})
</script>

<style></style>
