<template>
  <v-btn :color="color" class="engie-text-button" text @click="handleClick($event)"><slot></slot></v-btn>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    color: {
      default: "secondary",
      type: String,
    },
  },
  methods: {
    handleClick($event: Event) {
      this.$emit("click", $event)
    },
  },
})
</script>

<style lang="scss" scoped>
.v-btn {
  margin-left: auto;
  text-transform: none;
  text-decoration: underline;
  font-size: 1.2rem;
  font-family: "CamptonMedium";

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover::before {
      opacity: 0;
    }
  }
}
</style>
