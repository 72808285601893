<template>
  <div style="padding-left: 2rem" class="department-container">
    <h2 class="department-title">Department(s)</h2>
    <div v-if="loading" class="loading-container">
      <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <div v-else class="content-wrapper">
      <div v-if="companyDepartmentsWithoutNoDepartment.length > 0" class="checkbox-wrapper">
        <DisciplineCategoryCheckbox
          v-for="department in companyDepartmentsWithoutNoDepartment"
          :key="department.id"
          :item="department"
          :checked="isDepartmentChecked(department.id)"
          :company-id="companyId"
          item-name="department"
          :delete-fnc="() => deleteDepartment(department.id)"
          @change="handleDepartmentChecked"
          @deleted="handleDepartmentDeleted"
        />
      </div>
      <div v-else>No departments found</div>
      <EngieCreateButton
        class="add-department-btn"
        :title="'Add Department'"
        :dialog-title="'Create New Department'"
        :open="createDepartmentDialogOpen"
        :narrow="true"
        :department="true"
        @create="handleCreateDepartment"
        @dialog-closed="handleCreateDepartmentDialogClose"
      >
        <template #button>
          <EngieButton color="secondary" @click="handleCreateDepartmentDialogOpen"> Create Department </EngieButton>
        </template>
      </EngieCreateButton>
    </div>
    <div class="submit-btn-container">
      <EngieButton
        class="submit-btn"
        color="primary"
        style="margin-right: 2rem"
        :disabled="!disabled"
        @click="handleSubmitWithoutDepartment()"
      >
        Submit Without Department
      </EngieButton>
      <EngieButton class="submit-btn" color="primary" :disabled="disabled" @click="handleSelectDepartment()">
        Next: Select Category
      </EngieButton>
    </div>
  </div>
</template>

<script>
import EngieButton from "../forms/EngieButton.vue"
import EngieCreateButton from "../ProjectDashboard/EngieCreateButton.vue"
import DisciplineCategoryCheckbox from "./DisciplineCategoryCheckbox.vue"
import { deleteDiscipline } from "../../services/projectService"

export default {
  components: { EngieButton, EngieCreateButton, DisciplineCategoryCheckbox },
  props: {
    companyDepartments: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    companyId: {
      type: String,
      default: null,
    },
    selectedProject: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    selectedDepartments: [],
    createDepartmentDialogOpen: false,
  }),
  computed: {
    companyDepartmentsWithoutNoDepartment() {
      return this.companyDepartments.filter(department => department.id !== null)
    },
    disabled() {
      if (this.selectedDepartments.length === 0) {
        return true
      }
      return false
    },
  },
  watch: {
    companyDepartments() {
      this.selectedDepartments = []
    },
  },

  methods: {
    deleteDepartment(departmentId) {
      return deleteDiscipline(this.companyId, departmentId)
    },
    handleDepartmentChecked(event) {
      const { checked, item } = event

      if (checked) {
        this.selectedDepartments = [...this.selectedDepartments, item]
      } else {
        this.selectedDepartments = this.selectedDepartments.filter(selectedDepartments => selectedDepartments !== item)
      }
    },

    isDepartmentChecked(value) {
      return this.selectedDepartments.some(selectedDepartments => selectedDepartments.id === value)
    },

    handleSelectDepartment() {
      this.$emit("submitted", this.selectedDepartments)
    },

    handleSubmitWithoutDepartment() {
      this.$emit("no-department-submitted")
    },

    handleCreateDepartmentDialogOpen() {
      this.createDepartmentDialogOpen = true
    },

    handleCreateDepartmentDialogClose() {
      this.createDepartmentDialogOpen = false
    },

    handleCreateDepartment(name) {
      this.$emit("department-created", { name, projectId: this.selectedProject.id })
      this.handleCreateDepartmentDialogClose()
    },
    handleDepartmentDeleted(departmentId) {
      this.$emit("department-deleted", departmentId)
    },
  },
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  padding-top: 2rem;
  .checkbox-wrapper {
    max-height: 40rem;
    min-height: 10rem;
    overflow: auto;
    padding-left: 1rem;
  }
}
.department-container {
  min-height: 53rem;
  width: 100%;

  .add-department-btn {
    margin-top: 2rem;
  }
}

.submit-btn-container {
  width: calc(100% - 12rem);
  position: absolute;
  bottom: 3rem;
  display: flex;
  justify-content: flex-end;
}

.department-title {
  color: var(--black);
  font-weight: 400 !important;
}

.delete-btn {
  margin-left: 1rem;
  .delete-icon {
    width: 1.4rem;
  }
}

.loading-container {
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep {
  .engie-button .v-btn {
    min-height: 0px !important;
    min-width: 0px !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.75px !important;
    line-height: 1.6rem !important;
    padding: 0 1rem !important;
  }
}
</style>
