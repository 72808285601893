<template>
  <div class="virtual-scroller-content-container">
    <div v-if="!defaultPhoto" class="loading-container">
      <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <v-slide-group
      v-else
      v-model="selectedPhoto"
      mandatory
      show-arrows
      class="v-scroll-card-container"
      @click:next="handleNextClick()"
    >
      <template #prev>
        <div class="arrow-btn">
          <v-icon class="arrow-icon">mdi-arrow-left</v-icon>
        </div>
      </template>
      <template #next>
        <div class="arrow-btn">
          <v-icon class="arrow-icon">mdi-arrow-right</v-icon>
        </div>
      </template>
      <v-slide-item
        v-for="(photo, index) in photos"
        :id="index === photos.length - 1 ? 'last-photo' : ''"
        :key="photo.id"
        v-slot="{ active, toggle }"
        :value="photo"
        class="thumbnail"
        @change="handleSelectPhoto(photo)"
      >
        <v-img
          :class="active ? 'card-img active' : 'card-img'"
          :src="photo.thumbnailUrl"
          max-width="7.9rem"
          max-height="8.7rem"
          cover
          @click="toggle"
        ></v-img>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    defaultPhoto: {
      type: Object,
      default: () => ({}),
    },
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    totalPhotos: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: true,
    selectedPhoto: null,
    observer: null,
    totalNextClicks: 0,
    totalWidth: 0,
    totalRightMoved: 0,
    totalWidthCovered: 0,
    right: 0,
  }),
  mounted() {
    this.setSelectedToDefault()
  },
  methods: {
    handleSelectPhoto(value) {
      const isPhotoSelected = this.selectedPhoto === value
      if (!isPhotoSelected) {
        this.selectedPhoto = value
        this.$emit("selected", this.selectedPhoto.id)
      }
    },
    setSelectedToDefault() {
      const [defaultSelect] = this.photos.filter(photo => photo.id === this.defaultPhoto.id)
      this.selectedPhoto = defaultSelect
    },
    resetSelectPhoto() {
      if (this.dialogOpen === false) {
        this.selectedPhoto = null
        this.loading = false
      } else {
        this.selectedPhoto = { ...this.defaultPhoto }
        this.loading = false
      }
    },
    handleNextClick() {
      if (this.observer !== null) {
        const lastSlide = document.getElementById("last-photo")
        this.observer.unobserve(lastSlide)
      }
      this.initObserver()
    },

    initObserver() {
      const lastSlide = document.getElementById("last-photo")
      const contentContainer = document.querySelector(".virtual-scroller-container")
      const options = {
        root: contentContainer,
        // create an array of thresholds from .00 to .99
        threshold: [...Array(10)].map((el, index) => 0.1 * index),
      }

      const handleIntersectionEvent = async entry => {
        if (entry[0].isIntersecting) {
          if (this.photos.length < this.totalPhotos) {
            this.$emit("end-of-scroll")
          }
        }
      }

      this.observer = new IntersectionObserver(handleIntersectionEvent, options)
      this.observer.observe(lastSlide)
    },
  },
}
</script>

<style lang="scss" scoped>
.virtual-scroller-content-container {
  height: 100%;

  .v-scroll-card-container {
    height: 8.2rem;
    width: 100%;

    .thumbnail {
      height: 9rem !important;
      width: 9rem;
      padding: 0 !important;
      margin: 0rem 0rem !important;
    }

    .card-img {
      height: 8.2rem !important;
      width: 7.9rem !important;
      padding: 0 !important;
      margin: 0 0.2rem !important;
      border-radius: 3px !important;
    }
  }
}

.active {
  border: 4px solid var(--navy) !important;
}

::v-deep {
  .v-slide-group__prev {
    position: absolute;
    height: 100%;
    left: 0;
    z-index: 3;
  }
  .v-slide-group__next {
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 3;
  }
}

.arrow-btn {
  height: 3.6rem;
  width: 3.6rem;
  border-radius: 50%;
  display: flex;
  background-color: var(--orange);
  justify-content: center;
  align-items: center;
  position: absolute;

  .arrow-icon {
    color: white;
    font-size: 3.2rem;
  }
}

.thumbnail {
  cursor: pointer;
}
</style>
