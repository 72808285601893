<template>
  <div class="definition">
    <dt>{{ word }}</dt>
    <span v-if="andOr !== null" class="and-or">
      and/or
      <dt>{{ andOr }}</dt></span
    >
    <dd>{{ noSpace !== null ? "" : "&nbsp;" }}{{ definition }}</dd>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    word: {
      type: String,
      required: true,
    },
    andOr: {
      type: String,
      default: null,
    },
    definition: {
      type: String,
      required: true,
    },
    noSpace: {
      type: Boolean,
      default: null,
    },
  },
})
</script>

<style lang="scss" scoped>
.definition {
  margin-bottom: 1rem;
  dt,
  dd {
    display: inline;
    font-size: 1.5rem;
  }
  dt {
    font-weight: 800;
  }

  .and-or {
    font-size: 1.5rem;
  }
}
</style>
