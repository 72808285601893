<template>
  <div>
    <!-- <v-menu v-if="isUserAdmin" class="d-flex" offset-y>
      <template #activator="{ on, attrs }">
        <v-btn class="dropdown" dark color="#78d16d" depressed v-bind="attrs" v-on="on"
          >{{ defaultSelected }} <v-icon style="font-size: 24px">mdi-chevron-down</v-icon></v-btn
        >
      </template>
      <v-list class="menu-list" outlined>
        <div :class="{ active: defaultSelected === 'Active' }" class="menu-pointer"></div>
        <v-list-item
          v-for="(item, index) in items"
          id="items"
          :key="index"
          mandatory
          :class="{ last: index === items.length - 1, first: index === 0, active: defaultSelected === item }"
          class="menu-items"
          @click="
            {
              {
                handleClick(item)
              }
            }
          "
          >{{ item.toUpperCase() }}</v-list-item
        >
      </v-list>
    </v-menu> -->
    <div>
      <div class="status-display">{{ defaultSelected.toUpperCase() }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    status: {
      type: String,
      default: "Active",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultSelected: this.$props.status,
      items: ["Active", "Proposed", "Archived"],
    }
  },
  methods: {
    handleClick(value: string) {
      this.defaultSelected = value
    },
  },
})
</script>

<style lang="scss" scoped>
.status-display {
  background-color: #78d16d;
  color: white;
  border-radius: 1.5rem;
  font-size: 1.4rem;
  letter-spacing: 0.32px;
  font-weight: 600;
  height: 3.2rem !important;
  padding: 0.1rem 1rem 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
//   color: inherit !important;
//   &:hover {
//     color: white !important;
//   }
// }

// .dropdown {
//   border-radius: 1.5rem;
//   font-size: 1.4rem;
//   letter-spacing: 0.32px;
//   font-weight: 600;
//   height: 3.2rem !important;
//   padding: 0 1rem !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // color: var(--light-mid-grey);

//   &::v-deep {
//     span {
//       display: block;
//       padding-top: 2px;
//     }
//     i {
//       padding-bottom: 2px;
//     }
//   }
// }

// .menu-list {
//   border-radius: 1.5rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   font-size: 1.4rem;
//   padding: 0 !important;
//   border: 1px solid var(--lightest-grey);
//   box-shadow: 0px 6px 10px #00000014;
//   &::v-deep {
//     color: var(--mid-light-grey) !important;
//   }
// }

// .menu-pointer {
//   height: 1rem;
//   width: 1rem;
//   position: absolute;
//   top: 0.5rem;
//   right: 18%;
//   transform: rotate(45deg);
//   border-top: 1px solid var(--lightest-grey);
//   border-left: 1px solid var(--lightest-grey);
//   background-color: white;
//   transition: 0.25s;
// }

// #items {
//   color: var(--mid-light-grey) !important;
//   border-bottom: 1px solid var(--lightest-grey);
//   padding: 0.8rem;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   min-height: 0px;
//   transition: 0.25s;
//   &:hover {
//     background-color: #78d16d;
//     color: white !important;
//   }
//   &.active {
//     background-color: #78d16d;
//     color: white !important;
//     &:hover {
//       background-color: var(--navy);
//     }
//   }
// }

// .active {
//   background-color: #78d16d;
// }

// .first {
//   border-top-right-radius: 1.5rem;
//   border-top-left-radius: 1.5rem;
// }

// .last {
//   border-bottom: none;
//   border-bottom-right-radius: 1.5rem;
//   border-bottom-left-radius: 1.5rem;
// }

// .v-menu__content,
// .menuable__content__active {
//   overflow-x: visible !important;
//   overflow-y: visible !important;
//   padding-top: 1rem !important;
//   contain: none !important;
//   border-radius: 1.5rem !important;
//   box-shadow: none !important;
// }
</style>
