<template>
  <AuthForm
    title="Please set your new password"
    action-button-text="Set password"
    :show-title="!codeError"
    :show-action-button="!codeError"
    :show-error="showFormError"
    :error-message="formErrorMessage"
    :performing-action="savingPassword"
    @action-button-clicked="handleSetPasswordClicked()"
  >
    <div v-if="codeError" class="invalid-url-text">{{ codeErrorMessage }}</div>
    <v-progress-circular v-else-if="isLoading" indeterminate color="primary"></v-progress-circular>
    <div class="password-form-container">
      <EngieTextInput
        v-model="password"
        class="password-input"
        label="Password"
        type="password"
        autofocus
        :disabled="savingPassword"
        :animated-label="true"
        @enter-pressed="handleSetPasswordClicked()"
      />
      <EngieTextInput
        v-model="confirmPassword"
        class="password-input"
        label="Confirm password"
        type="password"
        :disabled="savingPassword"
        :animated-label="true"
        @enter-pressed="handleSetPasswordClicked()"
      />
    </div>
  </AuthForm>
</template>

<script lang="ts">
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getSignupCodeVerificationUrl, getSignupCodeRedemptionUrl } from "@/util/urls"
import Vue from "vue"
import "firebase/auth"
import { signIn } from "@/services/authService"
import { getCurrentAuthUser } from "@/services/getCurrentAuthUser"
import AuthForm from "../components/AuthForm.vue"
import EngieTextInput from "../components/forms/EngieTextInput.vue"
import { SignupCodeVerificationResponse } from "../types/SignupCodeVerificationResponse"

const PASSWORDS_DO_NOT_MATCH_ERROR_MESSAGE = "Passwords do not match"
const PASSWORD_TOO_SHORT_ERROR_MESSAGE = "Your password must be at least 6 characters long"
const ERROR_OCCURRED_SETTING_PASSWORD_MESSAGE = "An error occurred setting your password"
const ERROR_OCCURRED_LOGGING_IN = "An error logging in"

export default Vue.extend({
  components: {
    AuthForm,
    EngieTextInput,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      isLoading: true,
      savingPassword: false,
      codeError: false,
      codeErrorMessage: "",
      passwordsDoNotMatch: false,
      passwordIsTooShort: false,
      errorOccurredSettingPassword: false,
      errorOccurredLoggingIn: false,
      userEmail: "",
      codeVerified: false,
    }
  },
  computed: {
    showFormError(): boolean {
      return this.passwordsDoNotMatch || this.passwordIsTooShort || this.errorOccurredSettingPassword
    },
    formErrorMessage(): string {
      if (this.passwordsDoNotMatch) {
        return PASSWORDS_DO_NOT_MATCH_ERROR_MESSAGE
      }

      if (this.passwordIsTooShort) {
        return PASSWORD_TOO_SHORT_ERROR_MESSAGE
      }

      if (this.errorOccurredSettingPassword) {
        return ERROR_OCCURRED_SETTING_PASSWORD_MESSAGE
      }

      if (this.errorOccurredLoggingIn) {
        return ERROR_OCCURRED_LOGGING_IN
      }

      return ""
    },
  },
  created() {
    // When a user sets their new password, we sign them in with the firebase signInWithEmailAndPassword function.
    // This causes a rerender, however, so to prevent us from verifiying the signup code a second time, we check to ensure
    // the user has not logged in. If they have logged in, than either the password creation was successful or they have navigated here after having logged
    // in, so we take them to the dashboard
    if (!getCurrentAuthUser()) {
      this.verifySignupCodeIfSupplied()
    } else {
      this.$router.push({ name: "RoleBasedDashboard" })
    }
  },
  methods: {
    getUserIdAndCode() {
      const { userId, code } = this.$route.query

      return { userId, code }
    },
    async verifySignupCodeIfSupplied() {
      const { userId, code } = this.getUserIdAndCode()
      if (!userId || !code) {
        this.codeError = true
        this.codeErrorMessage = "Invalid signup link"
        this.isLoading = false
      } else {
        this.verifySignupCode(userId as string, code as string)
      }
    },
    async verifySignupCode(userId: string, code: string) {
      try {
        const response: SignupCodeVerificationResponse = await makeAuthenticatedRequest(
          getSignupCodeVerificationUrl(userId as string, code as string)
        )

        this.userEmail = response.email
        this.codeVerified = true

        this.redirectToLoginIfAlreadyRedeemed(response)
      } catch (error) {
        this.codeError = true
        this.codeErrorMessage = "Invalid signup code"
      } finally {
        this.isLoading = false
      }
    },
    redirectToLoginIfAlreadyRedeemed(response: SignupCodeVerificationResponse) {
      const { redeemed } = response

      if (redeemed) {
        this.$router.push("/login")
      }
    },
    handleSetPasswordClicked() {
      if (this.password !== this.confirmPassword) {
        this.passwordsDoNotMatch = true
      } else if (this.password.length < 6) {
        this.passwordIsTooShort = true
      } else {
        this.passwordsDoNotMatch = false
        this.passwordIsTooShort = false

        this.setPassword()
      }
    },
    async setPassword() {
      const { userId, code } = this.getUserIdAndCode()
      this.savingPassword = true

      try {
        await makeAuthenticatedRequest(getSignupCodeRedemptionUrl(userId as string, code as string), "POST", {
          password: this.password,
        })

        this.logInAndRedirect()
      } catch (error) {
        this.savingPassword = false
        this.errorOccurredSettingPassword = true
      }
    },
    async logInAndRedirect() {
      try {
        await signIn(this.userEmail, this.password)
        this.$router.push({ name: "RoleBasedDashboard" })
      } catch (error) {
        this.errorOccurredLoggingIn = true
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.invalid-url-text {
  font-size: 1.2rem;
}

.password-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-input {
  width: 40rem;
}

@media screen and (max-width: 440px) {
  .password-input {
    width: 30rem;
  }
}
</style>
