<template>
  <div
    class="unassigned-photos-summary"
    :style="backgroundStyle"
    @click="handleRouteClick"
    @keydown.enter="handleRouteClick"
  >
    <div class="summary">
      <div class="photo-count">{{ numberOfUnassignedPhotos }}</div>
      <p class="unassigned-photos-label">Unassigned Photos</p>
    </div>
    <div class="organize-button-container">
      <v-btn text color="white" :ripple="false" plain>{{
        numberOfUnassignedPhotos.length > 0 ? "Organize them now" : "Go to Photos"
      }}</v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    numberOfUnassignedPhotos: {
      type: Number,
      required: true,
    },
    featuredPhotoUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    backgroundStyle() {
      return `background-image: url(${this.featuredPhotoUrl});`
    },
  },
  methods: {
    handleRouteClick() {
      this.$router.push({
        path: `/photos`,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.unassigned-photos-summary {
  height: 31rem;
  width: 36vw;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: var(--white);
  background-size: cover;
  cursor: pointer;

  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: #0025332c;
    width: 100%;
    backdrop-filter: brightness(0.5);

    .photo-count {
      font-size: 9rem;
      color: var(--white);
      font-family: "CamptonMedium";
      height: 12rem;
    }

    .unassigned-photos-label {
      font-size: 2rem;
      color: var(--white);
    }
  }

  .organize-button-container {
    width: 100%;
    background: var(--navy);
    text-align: center;
    height: 5rem;

    &::v-deep .v-btn {
      text-transform: none !important;
      font-size: 1.5rem;
      position: relative;
      top: 0.5rem;

      .v-btn__content {
        opacity: 1 !important;
      }
    }
  }
}
</style>
