export const replaceAtIndex = (array: any[], index: number, updatedItem: any) => [
  ...array.slice(0, index),
  updatedItem,
  ...array.slice(index + 1),
]

export const findByIdAndReplace = (array: any[], itemId: string, updatedItem: any) => {
  const itemToUpdateIndex = array.findIndex(item => item.id === itemId)

  return replaceAtIndex(array, itemToUpdateIndex, updatedItem)
}
