<template>
  <div class="stepper-container">
    <v-stepper :value="value" alt-labels class="stepper" color="secondary">
      <v-stepper-header class="stepper-header">
        <v-btn v-if="value < 4" class="action-btn" icon @click="handleBackArrowClick">
          <ArrowIcon class="arrow-icon" />
        </v-btn>
        <slot name="item1"></slot>
        <v-divider :style="value >= 1 ? 'border-color: var(--navy)' : ''"></v-divider>
        <slot name="item2"></slot>
        <v-divider :style="value >= 2 ? 'border-color: var(--navy)' : ''"></v-divider>
        <slot name="item3"></slot>
        <v-divider :style="value >= 3 ? 'border-color: var(--navy)' : ''"></v-divider>
        <slot name="item4"></slot>
        <slot name="item5"></slot>
        <v-btn class="action-btn" icon @click="closeModal()">
          <CloseIcon class="close-icon" />
        </v-btn>
      </v-stepper-header>
      <v-stepper-items class="stepper-content-container">
        <slot name="stepper-content"></slot>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ArrowIcon from "../common_icons/ArrowIcon.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"

export default {
  components: { CloseIcon, ArrowIcon },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    handleBackArrowClick() {
      this.$emit("back")
    },
  },
}
</script>

<style lang="scss" scoped>
.stepper-container {
  // border: 1px solid blue;
  border: 0px !important;
  width: 100%;
}

.active {
  border: 1px solid red;
}

.stepper-header {
  padding: 0 3rem;
}

.action-btn {
  margin: 2rem 2rem 0 0;
}

.arrow-icon {
  width: 2rem;
  height: 2rem;
  transform: rotate(180deg);
}

.close-icon {
  width: 2rem;
}

.stepper {
  background-color: transparent !important;
  box-shadow: none;

  .step {
    color: var(--text);
    span {
      font-size: 1.8rem;
      line-height: 26px;
      letter-spacing: 0.12px;
    }
  }

  .stepper-content-container {
    height: 100%;
    width: 100%;
  }

  &::v-deep {
    .v-stepper__header {
      box-shadow: none;
    }
    .v-stepper__step__step {
      height: 22px;
      width: 22px;
      min-width: 0px;

      .v-icon {
        font-size: 2rem;
        font-weight: 800;
      }
    }
  }
}
</style>
