<template>
  <transition name="toolbar-transition">
    <div v-show="show">
      <PageSubheader>
        <h2><slot></slot></h2>
        <template #buttons>
          <EngieTextButton @click="handleCsvDownload()">Export to CSV</EngieTextButton>
        </template>
      </PageSubheader>
      <EngieSearchInput :value="value" class="toolbar-search" :label="searchLabel" @input="handleSearchInput($event)" />
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from "vue"
import EngieTextButton from "./forms/EngieTextButton.vue"
import EngieSearchInput from "./forms/EngieSearchInput.vue"
import PageSubheader from "./PageSubheader.vue"

export default Vue.extend({
  components: {
    EngieTextButton,
    EngieSearchInput,
    PageSubheader,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    searchLabel: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleCsvDownload() {
      this.$emit("download-csv")
    },
    handleSearchInput(value: string) {
      this.$emit("input", value)
    },
  },
})
</script>

<style lang="scss" scoped>
.sub-header {
  display: flex;
  border-bottom: 2px solid var(--navy);
  margin-bottom: 2rem;

  h2 {
    padding-bottom: 1rem;
  }
}

.toolbar-search {
  margin-bottom: 2rem;
}

.toolbar-transition-enter-active,
.toolbar-transition-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.toolbar-transition-enter,
.toolbar-transition-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
