<template>
  <div>
    <div v-if="loading" class="projects-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else style="margin-left: -1.3rem">
      <div v-if="recentProjects.length > 0" class="gallery" style="margin-top: 4.2rem">
        <ProjectCard v-for="project in recentProjects" :key="project.id" :project="project" width="26rem">
          <template #action-btn>
            <EngieButton @click="handleClickRoute(project.id)">View Project</EngieButton>
          </template>
        </ProjectCard>
      </div>
      <div v-else class="pl-3 pt-3">No recent projects.</div>
    </div>
  </div>
</template>

<script>
import { getRecentProjectGalleryEntry } from "@/services/projectService"
import ProjectCard from "../Projects/ProjectCard.vue"
import EngieButton from "../forms/EngieButton.vue"

export default {
  components: { ProjectCard, EngieButton },
  props: {
    companyId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    recentProjects: [],
  }),
  mounted() {
    this.initialize()
  },
  methods: {
    async fetchRecentProjectGalleryEntry(companyId, projectId) {
      try {
        const response = await getRecentProjectGalleryEntry(companyId, projectId)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    sendRequestForEachProjectId(projectIdsArray) {
      const promises = projectIdsArray.map(async projectId => {
        const response = await this.fetchRecentProjectGalleryEntry(this.companyId, projectId)
        return response
      })
      return Promise.all(promises)
    },

    async initialize() {
      const recentProjects = JSON.parse(localStorage.getItem(`recentProjects[${this.companyId}]`))
      if (recentProjects) {
        this.recentProjects = await this.sendRequestForEachProjectId(recentProjects)
      }
      this.loading = false
    },

    handleClickRoute(value) {
      this.$router.push({
        path: `/projects/${value}/photos`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.projects-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  padding-top: 4rem;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
</style>
