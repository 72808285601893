import { CompanyRole } from "@/types/CompanyRole"

export const companyRoleList = [
  {
    value: CompanyRole.HEAD_ADMIN,
    text: "Head Admin",
  },
  {
    value: CompanyRole.ADMIN,
    text: "Admin",
  },
  {
    value: CompanyRole.USER,
    text: "User",
  },
]

export const companyRoleListWithoutHeadAdmin = companyRoleList.slice(1)
