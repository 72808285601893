<template>
  <EngieTable
    :headers="headers"
    :items="companies || []"
    item-key="accountNumber"
    freeze-first-column
    :loading="loading"
    no-data-text="No companies found"
    :total-count="totalCount"
    :sort-desc="sortDesc"
    sort-by="name"
    editable
    @page-number-updated="handlePageNumberUpdated($event)"
    @page-size-updated="handlePageSizeUpdated($event)"
    @page-sort-descending-updated="handlePageSortDescendingUpdated($event)"
    @edit-row-clicked="handleEditRowClicked($event)"
  >
    <template #item.name="{ item }">
      <div class="name-container">
        <span>{{ item.name }}</span>
        <v-chip class="status-chip" :color="getStatusChipContent(item).color" x-small>{{
          getStatusChipContent(item).text
        }}</v-chip>
      </div>
    </template>
    <template #item.numberOfActiveUsers="{ item }">
      <v-btn v-if="!item.deletedDateTime" class="users-link" :to="`/companies/${item.id}/users`" text>{{
        item.numberOfActiveUsers
      }}</v-btn>
      <span v-else>N/A</span>
    </template>
    <template #item.createdDateTime="{ item }">
      <span>{{ formatCreatedDate(item.createdDateTime) }}</span>
    </template>
  </EngieTable>
</template>

<script lang="ts">
import { CompanyRecord } from "@/types/CompanyRecord"
import Vue from "vue"
import { DataTableHeader } from "vuetify"
import { formatShortDate } from "@/util/dateUtils"
import EngieTable from "../EngieTable.vue"
import { getCssVariableValue } from "../../util/getCssVariableValue"

export default Vue.extend({
  components: {
    EngieTable,
  },
  props: {
    companies: {
      type: Array,
      default: null,
    },
    totalCount: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberOfActiveAccounts: 0,
      sortDesc: false,
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: "Company Name",
          value: "name",
        },
        {
          text: "Account Number",
          value: "id",
          sortable: false,
          align: "center",
        },
        {
          text: "Created",
          value: "createdDateTime",
          sortable: false,
          align: "center",
        },
        {
          text: "Plan",
          value: "planDisplayName",
          sortable: false,
          align: "center",
        },
        {
          text: "Seats Used",
          value: "numberOfActiveUsers",
          sortable: false,
          align: "center",
        },
        {
          text: "Storage",
          value: "storageAmountDisplayName",
          sortable: false,
          align: "center",
        },
        {
          text: "Name",
          value: "headAdminName",
          sortable: false,
          align: "center",
        },
        {
          text: "Email",
          value: "headAdminEmail",
          sortable: false,
          align: "center",
        },
        {
          text: "Phone",
          value: "headAdminPhoneNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "Company Size",
          value: "size",
          sortable: false,
          align: "center",
        },
        {
          text: "Company Address",
          value: "address",
          sortable: false,
          align: "center",
        },
      ]
    },
  },
  methods: {
    handlePageNumberUpdated(pageNumber: number) {
      this.$emit("page-number-updated", pageNumber)
    },
    handlePageSizeUpdated(pageSize: number) {
      this.$emit("page-size-updated", pageSize)
    },
    handlePageSortDescendingUpdated(sortDescending: boolean) {
      this.sortDesc = sortDescending
      this.$emit("page-sort-descending-updated", sortDescending)
    },
    handleEditRowClicked(itemId: string) {
      this.$emit("edit-company-clicked", itemId)
    },
    getStatusChipContent(item: CompanyRecord) {
      if (item.pausedDateTime) {
        return { color: getCssVariableValue("--mid-light-grey"), text: "paused" }
      }

      if (item.deletedDateTime) {
        return { color: getCssVariableValue("--red"), text: "deleted" }
      }

      return { color: getCssVariableValue("--green"), text: "active" }
    },
    formatCreatedDate(dateString: string) {
      return formatShortDate(dateString)
    },
  },
})
</script>

<style lang="scss" scoped>
.name-container {
  display: flex;

  .status-chip {
    color: var(--white);
    margin-left: auto;
    min-width: 6rem;
    display: flex;
    justify-content: center;

    &::v-deep {
      .v-chip__content {
        text-align: center;
        text-transform: uppercase;
        font-size: 0.7rem;
      }
    }
  }
}
.users-link {
  color: var(--navy);
  text-decoration: underline;
}
</style>
