<template>
  <v-card class="card" flat>
    <slot name="img-content"></slot>
    <v-card-text class="text-box d-flex justify-space-between align-content-center">
      <slot name="text-content"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style lang="scss" scoped>
.map-marker {
  margin-right: 0.5rem;
}

.edit-btn {
  z-index: 999;
}

.card {
  height: 24.4rem;
  width: 26rem;
  border-radius: 1.5rem;
}

.text-box {
  background-color: transparent;
  height: 5.1rem;
  padding: 0 1.6rem;
  position: absolute;
  bottom: -1px;
  display: flex;
  color: var(--black) !important;
  background-color: white;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}

.v-card__title {
  font-size: 1.5rem;
  line-height: 1.4rem;
  font-weight: 800;
  z-index: 3;
  padding: 0;
}

.skeleton-loader {
  box-shadow: 0px 3px 6px #00000029;
}
::v-deep {
  .v-skeleton-loader__image {
    background-color: var(--light-grey) !important;
  }
}
</style>
