import Vue from "vue"
import firebase from "firebase/app"
import "firebase/auth"
import Vuelidate from "vuelidate"
import vueDebounce from "vue-debounce"
import VueMask from "v-mask"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"

Vue.config.productionTip = false

Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(vueDebounce)

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  // measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)

let app: Vue

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      vuetify,
      render: h => h(App),
    }).$mount("#app")
  }
})
