<template>
  <v-dialog
    :retain-focus="false"
    :value="dialogOpen"
    max-width="43rem"
    class="category-dialog"
    :persistent="true"
    @keydown.esc="closeModal()"
  >
    <v-card class="dialog-content-category">
      <v-card-title class="title-container">
        <span class="dialog-title">{{ dialogTitle }}</span>
        <v-btn class="close-btn" :disabled="loading" icon @click="closeModal()">
          <CloseIcon class="close-icon" />
        </v-btn>
      </v-card-title>
      <div class="dialog-body">
        <EngieTextInput
          v-if="selectedDepartment"
          v-model="newName"
          :autofocus="true"
          :label="`${selectedDepartment.name} Category Name:`"
          class="text-input"
        />
        <h4 class="subheader">Select Category Color</h4>
        <div class="d-flex flex-wrap color-container">
          <v-btn class="color-box" depressed @click="handleColorPickerClicked"><EngiePlus /></v-btn>
          <v-list-item
            v-for="(colorChoice, idx) in colorChoices"
            :key="idx"
            class="color-box"
            :class="{ selected: colorChoice === selectedColor }"
            :style="'background-color:' + colorChoice"
            @click="handleColorClick(colorChoice)"
          ></v-list-item>
        </div>
        <div class="d-flex justify-center">
          <v-color-picker
            v-if="colorPickerOpen"
            v-model="selectedColor"
            canvas-height="162px"
            width="375px"
            dot-size="2.5rem"
            class="color-picker d-flex flex-column align-center"
          ></v-color-picker>
        </div>
        <EngieButton class="submit-btn" :loading="loading" @click="handleSubmitCategory">{{ buttonTitle }}</EngieButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import EngiePlus from "@/components/common_icons/PlusIcon.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"
import EngieButton from "../forms/EngieButton.vue"

export default {
  components: { EngieTextInput, EngiePlus, CloseIcon, EngieButton },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    selectedDepartment: {
      type: Object,
      default: () => ({}),
    },
    selectedCategory: {
      type: Object,
      default: null,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      colorChoices: [
        "#BD10E0",
        "#FFCF07",
        "#FF9D00",
        "#00FFF5",
        "#FF0AD8",
        "#B44949",
        "#765BA0",
        "#609FA2",
        "#47624B",
        "#F37353",
        "#AAA6C9",
      ],
      newName: "",
      selectedColor: "#BD10E0",
      colorPickerOpen: false,
      dialogHeight: "43rem",
    }
  },
  computed: {
    disableSubmit() {
      if (this.newName === "") {
        return false
      }
      return true
    },
  },
  methods: {
    handleSubmitCategory() {
      const categoryData = { name: this.newName, colorHexValue: this.selectedColor }
      this.$emit("submit", categoryData)
      this.newName = ""
    },

    closeModal() {
      this.newName = ""
      this.colorPickerOpen = false
      this.$emit("dialog-closed")
    },

    handleDialogOpened() {
      this.$emit("dialog-opened")
    },

    handleEnterPressed() {
      const categoryData = { name: this.newName, colorHexValue: this.selectedColor }

      this.$emit("submit", categoryData)
      this.newName = ""
    },

    handleColorPickerClicked() {
      this.colorPickerOpen = !this.colorPickerOpen
    },

    handleColorClick(value) {
      this.selectedColor = value
      this.$emit("color-clicked")
    },
  },
}
</script>

<style lang="scss" scoped>
.category-dialog {
  border: 1px solid red;
}

.dialog-title {
  font-weight: 800;
}

.dialog-content-category {
  width: 43rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .title-container {
    padding: 1.4rem 1.5rem 1.4rem 2.5rem !important;
    border: 0.5px solid var(--light-grey);
    box-shadow: 0px 1px 4px #00000029;
  }

  .dialog-title {
    font-weight: 800;
  }

  .close-btn {
    height: 2rem;
    width: 2rem;
    margin-bottom: 1.5rem;
  }

  .close-icon {
    height: 1.3rem;
    width: 1.3rem;
  }

  .dialog-body {
    padding: 0 2.5rem 1.5rem 2.5rem;
    padding-bottom: 1.5rem;

    .text-input {
      letter-spacing: 0.3px;

      &::v-deep {
        .v-label {
          font-weight: 700;
        }
      }
    }

    .subheader {
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: var(--black);
      margin-bottom: 1.5rem;
      font-weight: 400;
    }

    .color-container {
      width: 100%;
      row-gap: 1rem;
      column-gap: 0.8rem;
      justify-content: center;
      margin-bottom: 2.5rem;
    }

    .submit-btn {
      text-align: center;
    }
  }
}

::v-deep {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    min-height: 4.8rem !important;
    height: 4.8rem;
  }
}

.color-picker {
  &::v-deep {
    .v-color-picker__canvas {
      canvas {
        border-radius: 1rem;
      }
    }
    .v-color-picker__controls {
      width: 100%;
      .v-color-picker__input {
        font-size: 1.8rem;
        input {
          height: 4rem;
        }
        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.selected::after {
  content: "";
  position: absolute;
  border-radius: 2px;
  left: -6px;
  height: 6.5rem;
  width: 6.5rem;
  border: 2px solid var(--black);
  transition-delay: 1.25s;
  transition: 0.05s;
}

.color-box {
  min-height: 5.3rem;
  min-width: 5.3rem !important;
  max-height: 5.3rem;
  max-width: 5.3rem;
  border-radius: 2px;
  margin-right: 0.2rem;
  display: flex;
  flex-wrap: wrap;
}
</style>
