<template>
  <v-tooltip bottom :disabled="!title">
    <template #activator="{ on, attrs }">
      <div class="engie-select" :class="{ 'no-label': noLabel !== null }" v-bind="attrs" v-on="on">
        <v-select
          :value="value"
          :items="options"
          :label="label"
          :title="title"
          :disabled="disabled"
          :loading="loading"
          @input="handleChange($event)"
        ></v-select>
      </div>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    handleChange(value: string) {
      this.$emit("input", value)
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-select {
  &.no-label::v-deep {
    .v-select {
      padding-top: 0;
    }
  }

  &::v-deep {
    .v-text-field > .v-input__control > .v-input__slot {
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
    }

    label {
      color: var(--charcoal);
    }

    .v-input__slot {
      border-bottom: none;
    }
    .v-select {
      margin-top: 0;
      padding-top: 5rem;

      .v-select__slot {
        border: 1px solid var(--light-grey);
        border-radius: 0.5rem;
        height: 5.6rem;

        .v-input__append-inner {
          align-self: center;

          & > div {
            width: 3.2rem;
            height: 3.2rem;

            & > i::before {
              font-size: 3rem;
            }
          }
        }
      }

      label {
        transform: translateY(-4rem);
      }

      .v-select__selection {
        margin-left: 2rem;
        color: var(--navy);
        text-decoration: underline var(--navy);
      }
    }
  }
}
</style>
