<template>
  <EngieDialogButton
    button-label="Create new account"
    :open="open"
    :disable-close-button="saving"
    @dialog-opened="handleDialogOpened()"
    @dialog-closed="handleDialogClosed()"
  >
    <div class="form-container">
      <EngieTextInput
        v-model="companyName"
        label="Company Name"
        :disabled="saving"
        :error-messages="companyNameErrors"
        @blur="$v.companyName.$touch()"
      />
      <EngieReferenceDataSelect
        v-model="planLevelId"
        label="Plan Level"
        reference-data-name="plan-types"
        :disabled="saving"
        @default-option-fetched="handleDefaultPlanLevelIdFetched($event)"
      />
      <EngieTextInput
        v-model="companyAddress"
        label="Company Address"
        :disabled="saving"
        :error-messages="companyAddressErrors"
        @blur="$v.companyAddress.$touch()"
      />
      <EngieReferenceDataSelect
        v-model="storageAmountId"
        label="Storage Amount"
        reference-data-name="storage-amounts"
        :disabled="saving"
        @default-option-fetched="handleDefaultStorageAmountIdFetched($event)"
      />
      <EngieTextInput
        v-model="companySize"
        label="Company Size"
        required
        :disabled="saving"
        type="number"
        :min="1"
        :error-messages="companySizeErrors"
        @blur="$v.companySize.$touch()"
      />
      <EngieTextInput
        v-model="headAdminName"
        label="Head Admin Name"
        :disabled="saving"
        :error-messages="headAdminNameErrors"
        @blur="$v.headAdminName.$touch()"
      />
      <EngieUserEmailInput
        v-model="headAdminEmail"
        label="Head Admin Email"
        :disabled="saving"
        :error-messages="headAdminEmailErrors"
        @blur="$v.headAdminEmail.$touch()"
        @unused-email-entered="handleUnusedEmailEntered()"
        @existing-email-entered="handleExistingEmailEntered()"
      />
      <EngieTextInput
        v-model="headAdminPhoneNumber"
        phone
        label="Head Admin Phone Number"
        :disabled="saving"
        :error-messages="headAdminPhoneNumberErrors"
        @blur="$v.headAdminPhoneNumber.$touch()"
        @unused-email-entered="handleUnusedEmailEntered()"
        @existing-email-entered="handleExistingEmailEntered()"
      />
    </div>
    <template #action-buttons>
      <EngieButton class="create-account-button" :loading="saving" @click="onCreateClicked()"
        >Create New Account</EngieButton
      >
    </template>
  </EngieDialogButton>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue"
import { validationMixin } from "vuelidate"
import { required, minValue, email, minLength } from "vuelidate/lib/validators"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompaniesUrl } from "@/util/urls"
import { CompanyRecord } from "@/types/CompanyRecord"
import { checkEmail } from "@/util/validators/checkEmail"
import { checkPhoneNumber } from "@/util/validators/checkPhoneNumber"
import EngieDialogButton from "../EngieDialogButton.vue"
import EngieButton from "../forms/EngieButton.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import EngieUserEmailInput from "../forms/EngieUserEmailInput.vue"
import EngieReferenceDataSelect from "../forms/EngieReferenceDataSelect.vue"
import { checkRequired } from "../../util/validators/checkRequired"
import { checkCompanySize } from "../../util/validators/checkCompanySize"

export default (Vue as VueConstructor<Vue & typeof validationMixin>).extend({
  components: {
    EngieButton,
    EngieDialogButton,
    EngieTextInput,
    EngieReferenceDataSelect,
    EngieUserEmailInput,
  },
  mixins: [validationMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companyName: "",
      planLevelId: "",
      defaultPlanLevelId: "",
      companyAddress: "",
      storageAmountId: "",
      defaultStorageAmountId: "",
      companySize: "1",
      headAdminName: "",
      headAdminEmail: "",
      headAdminPhoneNumber: "",
      saving: false,
      usableEmailEntered: true,
    }
  },
  computed: {
    formattedHeadAdminPhoneNumber(): string {
      if (this.headAdminPhoneNumber[0] !== "(") {
        return `(${this.headAdminPhoneNumber}`
      }

      return this.headAdminPhoneNumber
    },
    companyNameErrors(): string[] {
      return checkRequired(this.$v.companyName)
    },
    companyAddressErrors(): string[] {
      return checkRequired(this.$v.companyAddress)
    },
    companySizeErrors(): string[] {
      return checkCompanySize(this.$v.companySize)
    },
    headAdminNameErrors(): string[] {
      return checkRequired(this.$v.headAdminName)
    },
    headAdminEmailErrors(): string[] {
      return checkEmail(this.$v.headAdminEmail)
    },
    headAdminPhoneNumberErrors(): string[] {
      return checkPhoneNumber(this.$v.headAdminPhoneNumber)
    },
  },
  methods: {
    onCreateClicked() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.$emit("company-form-error-occurred")
      } else {
        this.createCompany()
      }
    },
    getCreatedRecord(companyResponse: CompanyRecord) {
      return {
        ...companyResponse,
        headAdminName: this.headAdminName,
        headAdminEmail: this.headAdminEmail,
        headAdminPhoneNumber: this.headAdminPhoneNumber,
      }
    },
    createCompany() {
      this.saving = true

      const body = {
        name: this.companyName,
        planTypeId: this.planLevelId,
        storageAmountId: this.storageAmountId,
        headAdminEmail: this.headAdminEmail,
        headAdminPhoneNumber: this.headAdminPhoneNumber,
        headAdminName: this.headAdminName,
        size: parseInt(this.companySize, 10),
        address: this.companyAddress,
      }

      makeAuthenticatedRequest(getCompaniesUrl(), "POST", body)
        .then(response => {
          this.$emit("company-created", this.getCreatedRecord(response))
          this.reset()
        })
        .catch(() => {
          this.$emit("company-creation-error-occurred")
        })
        .finally(() => {
          this.saving = false
        })
    },
    handleUnusedEmailEntered() {
      this.usableEmailEntered = true
    },
    handleExistingEmailEntered() {
      this.$v.headAdminEmail.$touch()
      this.usableEmailEntered = false
    },
    handleDefaultStorageAmountIdFetched(value: string) {
      this.defaultStorageAmountId = value
    },
    handleDefaultPlanLevelIdFetched(value: string) {
      this.defaultPlanLevelId = value
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleDialogClosed() {
      this.$emit("dialog-closed")
      this.reset()
    },
    reset() {
      this.companyName = ""
      this.companyAddress = ""
      this.headAdminName = ""
      this.headAdminEmail = ""
      this.headAdminPhoneNumber = ""
      this.companySize = "1"
      this.planLevelId = this.defaultPlanLevelId
      this.storageAmountId = this.defaultStorageAmountId

      this.$v.$reset()
    },
  },
  validations: {
    companyName: {
      required,
    },
    planLevelId: {
      required,
    },
    companyAddress: {
      required,
    },
    storageAmountId: {
      required,
    },
    companySize: {
      required,
      minValue: minValue(1),
    },
    headAdminName: {
      required,
    },
    headAdminEmail: {
      required,
      email,
      notTaken() {
        return this.usableEmailEntered
      },
    },
    headAdminPhoneNumber: {
      required,
      minLength: minLength(14),
    },
  },
})
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1rem));
  grid-column-gap: 2rem;

  .engie-text-input {
    width: 100%;
  }
}
</style>
