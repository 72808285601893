<template>
  <div class="photo-detail-information">
    <div v-if="loading" class="project-photo-detail-map-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else class="photo-detail-content">
      <v-img
        class="info-img"
        max-height="41rem"
        max-width="100%"
        :src="photoDetailInformation.previewImageUrl"
        @click="handlePhotoLightboxDialogOpen"
      ></v-img>
      <h3 v-if="photoDetailInformation.projectName" class="photo-detail-project">
        {{ photoDetailInformation.projectName }}
      </h3>
      <h3 v-else class="photo-detail-project">Unassigned</h3>
      <p class="photo-author">Uploaded By: {{ photoDetailInformation.uploaderDisplayName }}</p>
      <div class="d-flex align-center" style="margin-bottom: 1.2rem">
        <div class="d-flex align-center photo-detail-body" style="margin-right: 1.35rem">
          <CalendarIcon class="photo-detail-date-time" />
          <p class="date-time-text">{{ projectDate.date }}</p>
        </div>
        <div class="d-flex align-center photo-detail-body">
          <TimeIcon class="photo-detail-date-time" />
          <p class="date-time-text">{{ projectDate.time }}</p>
        </div>
      </div>
      <div v-if="!isShared" class="photo-notes-form">
        <EngieTextInput
          v-model="photoNotes"
          :placeholder="'Add Notes Here'"
          :hint="'Type here to add notes to photo'"
          persistent-hint
          :label="'Notes:'"
          class="notes-input"
        />
        <v-btn
          v-if="photoNotes !== photoDetailInformation.notes && photoNotes !== null"
          rounded
          depressed
          color="secondary"
          class="notes-submit-btn"
          @click="handlePhotoNotesSubmit"
          >Update Notes</v-btn
        >
      </div>
      <div v-else class="photo-notes-form">
        <EngieTextInput
          v-model="photoNotes"
          :placeholder="'Add Notes Here'"
          :label="'Notes:'"
          class="notes-input"
          disabled
        />
      </div>
      <p class="photo-detail-body">
        Department:
        {{
          photoDetailInformation.departments.length >= 1
            ? handlePhotoDepartmentOrCategoryNames(photoDetailInformation.departments)
            : " Unassigned"
        }}
      </p>
      <p class="photo-detail-body">
        Category:
        {{
          photoDetailInformation.categories.length >= 1
            ? handlePhotoDepartmentOrCategoryNames(photoDetailInformation.categories)
            : " Unassigned"
        }}
      </p>
      <v-btn v-if="!isShared" rounded depressed color="secondary" class="location-btn" @click="handlePhotoLocationOpen">
        Set Location
      </v-btn>
    </div>
    <SetLocationDialog
      v-if="!isShared"
      :location-dialog-open="locationDialogOpen"
      @close="closeModal"
      @location-updated="handleLocationSubmit"
    />
    <PhotoLightbox
      :is-shared="isShared"
      :is-external-user="isExternalUser"
      :dialog-open="photoLightboxDialogOpen"
      :photo="photoDetailInformation"
      :project-id="project ? project.id : null"
      :company-id="companyId"
      @close-lightbox="handlePhotoLightboxDialogClose"
    />
  </div>
</template>

<script>
import PhotoLightbox from "@/components/PhotoLightbox.vue"
import CalendarIcon from "../common_icons/CalendarIcon.vue"
import TimeIcon from "../common_icons/TimeIcon.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import SetLocationDialog from "./SetLocationDialog.vue"

export default {
  components: { CalendarIcon, TimeIcon, EngieTextInput, SetLocationDialog, PhotoLightbox },
  props: {
    photoDetailInformation: {
      type: Object,
      default: () => ({}),
    },
    project: {
      type: Object,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    photoNotes: null,
    locationDialogOpen: false,
    photoLightboxDialogOpen: false,
  }),
  computed: {
    projectDate() {
      if (this.photoDetailInformation !== {}) {
        const date = new Date(this.photoDetailInformation.createdDateTime)
        const updatedProjectDate = { date: "", time: "" }
        updatedProjectDate.date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
        const hour = date.getHours()
        const minutes = date.getMinutes()
        const updatedTime = this.meridianCheck(hour, minutes)
        updatedProjectDate.time = `${updatedTime.hour}:${updatedTime.minutes} ${updatedTime.meridian}`
        return updatedProjectDate
      }
      return {}
    },
    loading() {
      if (this.photoDetailInformation) {
        return false
      }
      return true
    },
    checkPhotoNotes() {
      if (!this.loading) {
        if (this.photoDetailInformation.notes) {
          return true
        }
        return false
      }
      return false
    },
  },
  watch: {
    photoDetailInformation(currentValue, previousValue) {
      if (currentValue !== previousValue && currentValue !== null) {
        if (this.checkPhotoNotes) {
          this.photoNotes = this.photoDetailInformation.notes
        } else {
          this.photoNotes = null
        }
      }
    },
  },
  mounted() {
    if (this.checkPhotoNotes) {
      this.photoNotes = this.photoDetailInformation.notes
    } else {
      this.photoNotes = null
    }
  },
  methods: {
    meridianCheck(hour, minutes) {
      const updatedTime = { hour: "", minutes: "", meridian: "" }

      updatedTime.hour = `${hour > 12 ? hour - 12 : hour}`
      if (hour === 0) {
        updatedTime.hour = "12"
      }
      updatedTime.meridian = hour >= 12 ? "PM" : "AM"
      updatedTime.minutes = this.setMinutes(minutes)
      return updatedTime
    },

    setMinutes(minutes) {
      if (minutes === 0) {
        return "00"
      }
      if (minutes < 10) {
        return `0${minutes}`
      }
      return `${minutes}`
    },

    handlePhotoNotesSubmit() {
      this.$emit("notes-updated", this.photoNotes)
    },

    handlePhotoLocationOpen() {
      this.locationDialogOpen = true
    },
    closeModal() {
      this.locationDialogOpen = false
    },
    handleLocationSubmit(value) {
      this.$emit("location-updated", value)
      this.closeModal()
    },
    handlePhotoDepartmentOrCategoryNames(arr) {
      const names = arr.map(item => item.name)
      return names.join(", ")
    },

    handlePhotoLightboxDialogOpen() {
      this.photoLightboxDialogOpen = true
    },

    handlePhotoLightboxDialogClose() {
      this.photoLightboxDialogOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.info-img {
  width: 32.3rem;
  margin-bottom: 2rem;
  height: 21.4rem;
  background-color: var(--light-grey);
  cursor: pointer;
}
.photo-detail-project {
  font-size: 2.6rem;
  line-height: 2.75rem;
  font-weight: 400;
  margin-bottom: 2rem;
}
.photo-detail-body {
  font-size: 1.4rem;
  line-height: 3.5rem;
  letter-spacing: 0.35px;
  margin-bottom: 0;
}

.photo-detail-date-time {
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
}

.date-time-text {
  margin-bottom: 0 !important;
  margin-top: 0.25rem !important;
}

.photo-notes-form {
  position: relative;
}

.notes-submit-btn {
  height: 3rem !important;
  width: 10rem;
  min-height: 0 !important;
  min-width: 0 !important;
  font-weight: 900;
  letter-spacing: 1.25px;
  position: absolute;
  top: -0.5rem;
  right: 0;
}

.engie-text-input::v-deep {
  label {
    font-size: 1.4rem;
  }
  .v-text-field {
    padding-top: 3rem !important;
    margin-bottom: 1.2rem !important;
  }
  .v-input__slot {
    background-color: var(--light-grey);
  }
  .v-text-field__details,
  .v-messages {
    display: block;
    margin-top: 0.25rem;
  }
}

.location-btn {
  height: 3rem !important;
  width: 15rem;
  font-size: 1.2rem;
  min-height: 0 !important;
  min-width: 0 !important;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-top: 1rem;

  &::v-deep {
    .v-btn__content {
      margin-top: 0.2rem;
    }
  }
}
</style>
