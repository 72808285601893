<template>
  <v-dialog
    :retain-focus="false"
    :value="open"
    max-width="81rem"
    class="assign-dialog"
    :persistent="true"
    @keydown.esc="closeModal()"
  >
    <div class="dialog-content-container" style="height: 64.7rem">
      <div class="dialog-title-container">
        <span class="dialog-title">Share {{ `${itemType}` }}</span>
        <v-btn class="close-btn" icon @click="closeModal()">
          <CloseIcon class="close-icon" />
        </v-btn>
      </div>
      <div class="dialog-to-container">
        <div class="dialog-to-input-container">
          <h3 class="dialog-to" style="line-height: 3rem">To:</h3>
          <v-combobox
            :value="selectedEmails"
            class="dialog-autocomplete"
            hide-selected
            clearable
            chips
            multiple
            solo
            :loading="loading"
            :items="searchResponseEmails"
            item-color="primary"
            item-text="email"
            :search-input.sync="search"
            @change="handleEmailsChanged"
            @input="handleInput($event)"
          >
            <template #selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                dark
                close
                color="var(--navy)"
                v-on="on"
                @click:close="!item.email ? removeChip(item) : removeChip(item.email)"
              >
                <span v-text="item.email ? item.email : item"></span>
              </v-chip>
            </template>
          </v-combobox>
          <v-card v-if="validEmailEntered" class="search-message"
            ><span class="search-message-content">Press <kbd>enter</kbd> to add</span></v-card
          >
        </div>
      </div>
      <div class="dialog-add-message-container">
        <v-textarea
          v-model="emailMessage"
          class="add-message-input"
          :label="''"
          placeholder="Add a message (optional)"
          no-resize
          rows="18"
        ></v-textarea>
      </div>
      <div class="dialog-submit-container">
        <v-btn text class="share-link-btn" :loading="generatingShareLink" @click="handleShareLink">Copy Link</v-btn>
        <EngieButton
          :color="'primary'"
          :disabled="submitBtnDisabled"
          :loading="sending"
          @click="handleSubmitEmailAddresses"
          >Send Link</EngieButton
        >
      </div>
    </div>
    <EngieErrorSnackbar
      v-model="shareProjectErrorSnackbarOpen"
      text="An error occurred while sharing your photos"
      @close="handleShareProjectErrorSnackbarClose()"
    />
    <EngieErrorSnackbar
      v-model="shareProjectLinkErrorSnackbarOpen"
      text="An error occurred while creating your share link"
      @close="handleShareProjectLinkErrorSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="shareProjectLinkSuccessSnackbarOpen"
      text="Link copied!"
      style="bottom: 1rem"
      @close="handleShareProjectLinkSuccessSnackbarClose()"
    />
  </v-dialog>
</template>

<script>
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getAllUsersByEmailAddressSearch } from "@/services/projectService"
import { getCompanyUrl } from "@/util/urls"
import { copyToClipboard } from "@/util/copyToClipboard"
import CloseIcon from "./common_icons/CloseIcon.vue"
import EngieButton from "./forms/EngieButton.vue"
import EngieErrorSnackbar from "./EngieErrorSnackbar.vue"
import EngieSuccessSnackbar from "./EngieSuccessSnackbar.vue"

const EMAIL_REGEX = /.+@.+\..+/

export default {
  components: { CloseIcon, EngieButton, EngieErrorSnackbar, EngieSuccessSnackbar },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    itemType: {
      type: String,
      default: "",
    },
    project: {
      type: Object,
      default: null,
    },
    photos: {
      type: Array,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    search: "",
    emailMessage: "",
    selectedEmails: null,
    searchResponseEmails: [],
    loading: false,
    sending: false,
    shareProjectErrorSnackbarOpen: false,
    shareProjectLinkErrorSnackbarOpen: false,
    shareProjectLinkSuccessSnackbarOpen: false,
    generatedShareLink: null,
    showTooltip: true,
    generatingShareLink: false,
  }),
  computed: {
    submitBtnDisabled() {
      if (this.selectedEmails === null || this.selectedEmails.length === 0) {
        return true
      }
      return false
    },
    validEmailEntered() {
      return EMAIL_REGEX.test(this.search)
    },
  },
  watch: {
    search(value) {
      if (!value) {
        return
      }
      this.fetchEntriesDebounced(value)
    },
  },

  methods: {
    handleEmailsChanged(event) {
      const filteredInput = event.filter(entry => typeof entry === "object" || EMAIL_REGEX.test(entry))
      this.selectedEmails = filteredInput
    },
    closeModal() {
      this.generatedShareLink = null
      this.$emit("close")
    },

    fetchEntriesDebounced(value) {
      // eslint-disable-next-line no-underscore-dangle
      clearTimeout(this._timerId)

      // eslint-disable-next-line no-underscore-dangle
      this._timerId = setTimeout(() => {
        this.searchUsers(value)
      }, 500)
    },

    async searchUsers(value) {
      this.loading = true
      const searchResponse = await this.fetchAllUsersBySearchInput(value)
      this.searchResponseEmails = searchResponse.items
      this.loading = false
    },

    async fetchAllUsersBySearchInput(search) {
      try {
        const response = await getAllUsersByEmailAddressSearch(search)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    removeChip(item) {
      this.selectedEmails = this.selectedEmails.filter(selectedEmail => {
        if (!selectedEmail.email) {
          return selectedEmail !== item
        }
        return selectedEmail.email !== item
      })
    },

    handleInput(event) {
      this.search = ""
      const response = event.map(item => {
        if (!item.email) {
          return item
        }
        return item.email
      })
      this.selectedEmails = response
    },

    resetData() {
      this.search = ""
      this.emailMessage = ""
      this.selectedEmails = null
      this.searchResponseEmails = []
    },

    getPhotoIds() {
      return this.photos.map(photo => {
        if (typeof photo === "string") {
          return photo
        }

        return photo.id
      })
    },

    async postPhotoCollection(useEmail) {
      if (useEmail) {
        const photoIdsArray = this.getPhotoIds()
        const submissionData = {
          photoIds: photoIdsArray,
          emailAddressesToNotify: this.selectedEmails,
          message: this.emailMessage,
        }
        try {
          const response = await makeAuthenticatedRequest(
            `${getCompanyUrl(this.companyId)}/shared-photo-collections`,
            "POST",
            submissionData
          )
          this.resetData()
          this.generatedShareLink = null
          this.$emit("share-submitted")
          return response
        } catch (error) {
          this.shareProjectErrorSnackbarOpen = true
          throw new Error(`${error}`)
        }
      } else {
        const photoIdsArray = this.getPhotoIds()
        const submissionData = { photoIds: photoIdsArray }
        try {
          const response = await makeAuthenticatedRequest(
            `${getCompanyUrl(this.companyId)}/shared-photo-collections`,
            "POST",
            submissionData
          )
          this.resetData()
          this.generatedShareLink = null
          return response
        } catch (error) {
          this.shareProjectErrorSnackbarOpen = true
          throw new Error(`${error}`)
        }
      }
    },

    getEmailAddressesToSend() {
      // auto-completed email addresses of engiecam account owners are objects, so we need to get their raw email address before sending
      return this.selectedEmails.map(emailEntry => (typeof emailEntry === "object" ? emailEntry.email : emailEntry))
    },

    async handleSubmitEmailAddresses() {
      if (this.project) {
        this.sending = true
        const emailAddresses = this.getEmailAddressesToSend()

        const submissionData = { emailAddresses, message: this.emailMessage }
        try {
          const response = await makeAuthenticatedRequest(
            `${getCompanyUrl(this.companyId)}/projects/${this.project.id}/share-messages`,
            "POST",
            submissionData
          )
          this.resetData()
          this.generatedShareLink = null
          this.$emit("share-submitted")
          return response
        } catch (error) {
          this.shareProjectErrorSnackbarOpen = true
          throw new Error(`${error}`)
        } finally {
          this.sending = false
        }
      } else {
        const response = this.postPhotoCollection(true)
        return response
      }
    },

    handleShareProjectErrorSnackbarClose() {
      this.shareProjectErrorSnackbarOpen = false
    },

    async copyLinkToClipboard(text) {
      try {
        await copyToClipboard(text)
        this.shareProjectLinkSuccessSnackbarOpen = true
      } catch (error) {
        console.error(error)
        this.shareProjectLinkErrorSnackbarOpen = true
      }
    },

    async handleShareLink() {
      this.generatingShareLink = true

      if (this.project) {
        this.generatedShareLink = `${process.env.VUE_APP_UI_URL}/share/companies/${this.companyId}/projects/${this.project.id}`
        this.copyLinkToClipboard(this.generatedShareLink)
      } else {
        const response = await this.postPhotoCollection()
        if (this.generatedShareLink === null) {
          this.generatedShareLink = `${process.env.VUE_APP_UI_URL}/share/companies/${this.companyId}/photo-collections/${response.id}`
          this.copyLinkToClipboard(this.generatedShareLink)
        } else {
          this.copyLinkToClipboard(this.generatedShareLink)
        }
      }

      this.generatingShareLink = false
    },

    handleShareProjectLinkErrorSnackbarClose() {
      this.shareProjectLinkErrorSnackbarOpen = false
    },

    handleShareProjectLinkSuccessSnackbarClose() {
      this.shareProjectLinkSuccessSnackbarOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-container {
  height: 64.7rem !important;
  width: 81rem !important;
  position: relative;
}

.dialog-content-container {
  background-color: white;
  display: flex;
  flex-direction: column;

  .dialog-title-container {
    border-bottom: 1px solid var(--mid-light-grey);
    height: 6.2rem;
    padding: 1.6rem 2.5rem 0.8rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
      font-weight: 900;
      font-size: 2.9rem;
      margin-right: 3rem;
      word-break: break-word;
      line-height: 2.9rem;
    }

    .close-icon {
      width: 2rem;
    }
  }
}

.dialog-to-container {
  height: 6.7rem;
  border-bottom: 1px solid var(--mid-light-grey);
  padding: 1.6rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .dialog-to {
    padding-top: 0.5rem;
    font-weight: 400;
    font-family: "CamptonRegular";
    color: var(--mid-light-grey);
  }

  .search-message {
    position: absolute;
    top: 6rem;
    left: 6rem;
    padding: 1.6rem !important;
    z-index: 1;

    .search-message-content {
      padding: 3rem;
    }
  }

  .dialog-to-input-container {
    display: flex;

    h3 {
      width: 3rem;
      margin-right: 1rem;
    }

    .dialog-autocomplete {
      padding-top: 0 !important;
      margin-top: 0 !important;

      &::v-deep {
        .v-text-field__details {
          display: none !important;
        }
      }
    }
  }
}

.dialog-add-message-container {
  border-bottom: 1px solid var(--mid-light-grey);
  height: 100%;
  width: 100%;
  padding: 1.6rem 2.5rem;

  .add-message-input {
    height: 5rem;
    width: 100%;
  }

  &::v-deep {
    .v-input__slot::before {
      display: none;
    }
    .v-input__slot::after {
      display: none;
    }

    .theme--light.v-input,
    textarea {
      color: var(--navy);
      font-family: "CamptonRegular";
    }
  }
}

.dialog-submit-container {
  display: flex;
  justify-content: space-between;
  padding: 3.2rem 3.8rem;

  .share-link-btn {
    text-transform: none;
    font-size: 1.8rem;
    line-height: 2rem;
    color: var(--primary);
    letter-spacing: 0px;
    margin-top: 0.5rem;
  }
}

::v-deep {
  .v-snack {
    bottom: 1rem !important;
  }

  .v-dialog,
  .v-card,
  .v-card__text {
    padding: 0 !important;
  }

  .v-text-field.v-text-field--solo,
  .v-input__control {
    min-height: 0px !important;
  }

  .v-input__slot {
    box-shadow: none !important;
  }

  .theme--light.v-input,
  input {
    color: var(--navy) !important;
  }
}
</style>
