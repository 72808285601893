import userCompanyStateStore from "@/stores/userCompanyStateStore"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { UserProfilePicture } from "@/types/UserProfilePicture"
import { getCurrentAuthUserRecord } from "./authService"
import { getUsersUrl } from "../util/urls"

export const getCurrentUserCompanyId = async () => {
  if (userCompanyStateStore.state.userCompanyId === null) {
    const user = await getCurrentAuthUserRecord()

    userCompanyStateStore.setUserCompanyId(user.companyId)

    return user.companyId
  }

  return userCompanyStateStore.state.userCompanyId
}

// POST NEW PROFILE PHOTO TO USER
export const postUserProfilePhoto = async (userId: string, profilePhoto: FormData): Promise<UserProfilePicture> => {
  try {
    const response: UserProfilePicture = await makeAuthenticatedRequest(
      `${getUsersUrl()}/${userId}/profile-picture`,
      "POST",
      profilePhoto
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}
