<template>
  <EngieDialogButton
    :open="open"
    narrow
    error-message="Please enter at least one email address"
    :error-occurred="showEnterAtLeastOneEmailError"
    button-label="Add new user(s)"
    :dialog-title="`Add New User(s)`"
    :disable-close-button="saving"
    :align-action-buttons-right="true"
    :outlined-actions-section="true"
    :loading="loading"
    @dialog-closed="handleDialogClosed()"
    @dialog-opened="handleDialogOpened()"
  >
    <form>
      <transition-group :name="transitionName">
        <div v-for="(newUser, index) in newUsers" :key="newUser.id" class="new-user-input-wrapper">
          <EngieUserEmailInput
            v-model="newUser.email"
            label="Email"
            :autofocus="index === 0"
            :inline-label="true"
            :disabled="saving"
            :error-messages="emailErrors.get(newUser.id)"
            @blur="handleEmailInputBlurred(newUser.id, index)"
            @unused-email-entered="handleUnusedEmailEntered(index)"
            @existing-email-entered="handleExistingEmailEntered(index)"
          />
          <EngieSelect v-model="newUser.companyRole" :options="roleOptions" no-label :disabled="saving" />
        </div>
      </transition-group>
    </form>
    <div v-if="maxUsersAlreadyRegistered" class="max-users-message">
      <p>
        <strong
          >You have already registed the maximum number of users for your plan ({{
            totalNumberOfUsersAllowedByPlan
          }}).</strong
        >
      </p>
      <p>To add more users to your plan, contact hello@engiecam.com.</p>
    </div>
    <div v-else class="user-count-information">
      <p>
        You have used <strong>{{ numberOfUsersAdded }}</strong> of
        <strong>{{ totalNumberOfUsersAllowedByPlan }}</strong> users
      </p>
      <v-progress-linear :value="percentageOfUsersRegistered"></v-progress-linear>
    </div>
    <EngieErrorSnackbar
      v-model="showErrorSnackbar"
      text="An error occurred adding the new users"
      @close="handleErrorSnackbarClosed()"
    />
    <template #action-buttons>
      <EngieButton class="save-changes-button" color="primary" :loading="saving" @click="handleAddNewUsersClicked()">
        Send
      </EngieButton>
    </template>
  </EngieDialogButton>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue"
import { validationMixin } from "vuelidate"
import { email } from "vuelidate/lib/validators"
import { checkEmail } from "@/util/validators/checkEmail"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompanyUserCountUrl, getCreateNewUsersUrl } from "@/util/urls"
import { mapFromZero } from "@/util/mapRange"
import { CompanyRole } from "@/types/CompanyRole"
import { NewUser } from "@/types/NewUser"
import { UserCountResponse } from "@/types/UserCountResponse"
import EngieDialogButton from "../EngieDialogButton.vue"
import EngieUserEmailInput from "../forms/EngieUserEmailInput.vue"
import EngieButton from "../forms/EngieButton.vue"
import EngieErrorSnackbar from "../EngieErrorSnackbar.vue"
import EngieSelect from "../forms/EngieSelect.vue"
import { companyRoleListWithoutHeadAdmin } from "../../constants/companyRoleList"

export default (Vue as VueConstructor<Vue & typeof validationMixin>).extend({
  components: {
    EngieDialogButton,
    EngieUserEmailInput,
    EngieButton,
    EngieErrorSnackbar,
    EngieSelect,
  },
  mixins: [validationMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      required: true,
    },
    numberOfActiveAccounts: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newUsers: [],
      showErrorSnackbar: false,
      saving: false,
      loading: true,
      showDeletionErrorSnackbar: false,
      roleOptions: companyRoleListWithoutHeadAdmin,
      // This data property is used to disable the list transition from playing when initially opening the form;
      // we only want the transition group animation to apply when the user tabs past the last form element
      transitionFormElements: false,
      showEnterAtLeastOneEmailError: false,
      totalNumberOfUsersAllowedByPlan: null,
      currentNumberOfUsers: null,
      defaultUsersLoaded: false,
      maxUsersAlreadyRegistered: false,
    }
  },
  computed: {
    emailErrors(): Map<string, string[]> {
      const mapParams = this.newUsers.map((newUser: NewUser, index: number) => {
        const isNotEmpty = newUser.email.trim().length > 0

        const validationResult = isNotEmpty ? checkEmail(this.getEmailValidation(index).email, false) : []

        return [newUser.id, validationResult]
      })

      return new Map(mapParams)
    },
    transitionName(): string | null {
      return this.transitionFormElements ? "new-user-inputs" : null
    },
    numberOfNewUsers(): number {
      return this.getNewEmailAddress().length
    },
    numberOfUsersAdded(): number {
      return this.currentNumberOfUsers + this.numberOfNewUsers
    },
    companyUserCountInformationHasLoaded(): boolean {
      return (
        this.totalNumberOfUsersAllowedByPlan !== null && this.currentNumberOfUsers !== null && this.defaultUsersLoaded
      )
    },
    percentageOfUsersRegistered(): number {
      return (this.numberOfUsersAdded / this.totalNumberOfUsersAllowedByPlan) * 100
    },
    maximumNumberOfUsersReached(): boolean {
      return this.numberOfUsersAdded === this.totalNumberOfUsersAllowedByPlan
    },
  },
  watch: {
    open(open: boolean) {
      if (open) {
        this.transitionFormElements = false
      } else {
        this.reset()
      }
    },
  },
  created() {
    this.reset()
  },
  mounted() {
    this.getPlanUserCountInfo()
  },
  methods: {
    async getPlanUserCountInfo() {
      const userCountInfo: UserCountResponse = await makeAuthenticatedRequest(getCompanyUserCountUrl(this.companyId))

      this.totalNumberOfUsersAllowedByPlan = userCountInfo.numberOfUsersPlanAllows
      this.currentNumberOfUsers = this.numberOfActiveAccounts
      // this.currentNumberOfUsers = userCountInfo.numberOfActiveUsers

      if (this.totalNumberOfUsersAllowedByPlan === this.currentNumberOfUsers) {
        this.maxUsersAlreadyRegistered = true
      }
    },
    getEmailValidation(index: number) {
      return this.$v.newUsers.$each[index]
    },
    getNewUser() {
      return {
        id: Math.random().toString().slice(2),
        email: "",
        companyRole: CompanyRole.USER,
        usableEmailEntered: true,
      }
    },
    getDefaultUsers(): NewUser[] {
      const numberOfInputsToAdd = Math.min(3, this.totalNumberOfUsersAllowedByPlan - this.currentNumberOfUsers)

      return mapFromZero<NewUser>(numberOfInputsToAdd, () => this.getNewUser())
    },
    addNewDefaultUser() {
      this.newUsers = [...this.newUsers, this.getNewUser()]
    },
    isLastEmailInputFilled() {
      return this.newUsers[this.newUsers.length - 1].email.trim().length > 0
    },
    handleEmailInputBlurred(id: string, index: number) {
      this.getEmailValidation(index).email.$touch()
      this.transitionFormElements = true

      if (!this.maximumNumberOfUsersReached && index + 1 === this.newUsers.length && this.isLastEmailInputFilled()) {
        this.addNewDefaultUser()
      }
    },
    handleDialogClosed() {
      this.$emit("dialog-closed")
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    loadDefaultUsersIfNotYetLoaded() {
      this.newUsers = this.getDefaultUsers()
      this.defaultUsersLoaded = true
    },
    async reset() {
      this.loading = true
      this.transitionFormElements = false
      this.$v.$reset()

      await this.getPlanUserCountInfo()

      this.loadDefaultUsersIfNotYetLoaded()
      this.loading = false
      this.transitionFormElements = true
    },
    handleUnusedEmailEntered(index: number) {
      this.newUsers[index].usableEmailEntered = true
    },
    handleExistingEmailEntered(index: number) {
      this.newUsers[index].usableEmailEntered = false
    },
    handleAddNewUsersClicked() {
      this.getEmailValidation(0).$touch()
      this.showEnterAtLeastOneEmailError = false

      if (this.getNewEmailAddress().length === 0) {
        this.showEnterAtLeastOneEmailError = true
      } else if (!this.$v.$invalid) {
        this.saveNewUsers()
      }
    },
    getNewEmailAddress() {
      return this.newUsers.filter((newUser: NewUser) => newUser.email.trim().length > 1)
    },
    getAddUsersPostBody() {
      return this.getNewEmailAddress().map((newUser: NewUser) => ({
        email: newUser.email,
        companyRole: newUser.companyRole,
      }))
    },
    async saveNewUsers() {
      this.saving = true

      const updateBody = this.getAddUsersPostBody()

      try {
        const response = await makeAuthenticatedRequest(getCreateNewUsersUrl(this.companyId), "POST", updateBody)
        this.$emit("users-created", response)
      } catch (error) {
        this.showErrorSnackbar = true
      }

      this.saving = false
    },
    handleErrorSnackbarClosed() {
      this.showErrorSnackbar = false
    },
    handleConfirmDeleteUserDialogOpened() {
      this.confirmDeleteUserDialogOpen = true
    },
    handleConfirmDeleteUserDialogClosed() {
      this.confirmDeleteUserDialogOpen = false
    },
  },
  validations: {
    newUsers: {
      $each: {
        email: {
          email,
          notTaken(value: string) {
            const newUserWithEmail = this.newUsers.find((newUser: NewUser) => newUser.email === value)

            return newUserWithEmail.usableEmailEntered
          },
        },
      },
    },
  },
})
</script>

<style lang="scss" scoped>
form::v-deep {
  margin-top: 2rem;

  .new-user-input-wrapper {
    display: flex;
    max-width: 100%;

    .engie-text-input {
      width: 66%;
      margin-right: 2rem;
    }
    .engie-select {
      width: 33%;
    }
  }
}

.user-count-information,
.max-users-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.new-user-inputs-enter-active,
.new-user-inputs-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.new-user-inputs-enter,
.new-user-inputs-leave-to {
  opacity: 0;
  transform: translateX(-2rem);
}
</style>
