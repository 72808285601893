<template>
  <AuthForm
    title="Please set your new password"
    action-button-text="Set password"
    :show-error="showFormError"
    :error-message="formErrorMessage"
    :performing-action="savingPassword"
    @action-button-clicked="handleSetPasswordClicked()"
  >
    <div class="password-form-container">
      <EngieTextInput
        v-model="password"
        label="Password"
        class="password-input"
        type="password"
        :disabled="savingPassword"
        :animated-label="true"
        @enter-pressed="handleSetPasswordClicked()"
      />
      <EngieTextInput
        v-model="confirmPassword"
        label="Confirm password"
        class="password-input"
        type="password"
        :disabled="savingPassword"
        :animated-label="true"
        @enter-pressed="handleSetPasswordClicked()"
      />
    </div>
  </AuthForm>
</template>

<script lang="ts">
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import Vue from "vue"
import "firebase/auth"
import AuthForm from "../components/AuthForm.vue"
import EngieTextInput from "../components/forms/EngieTextInput.vue"
import { getForgotPasswordRedemptionUrl } from "../util/urls"

const PASSWORDS_DO_NOT_MATCH_ERROR_MESSAGE = "Passwords do not match"
const PASSWORD_TOO_SHORT_ERROR_MESSAGE = "Your password must be at least 6 characters long"
const ERROR_OCCURRED_SETTING_PASSWORD_MESSAGE = "The password link has expired."

export default Vue.extend({
  components: {
    AuthForm,
    EngieTextInput,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      isLoading: true,
      savingPassword: false,
      passwordsDoNotMatch: false,
      passwordIsTooShort: false,
      errorOccurredSettingPassword: false,
    }
  },
  computed: {
    showFormError(): boolean {
      return this.passwordsDoNotMatch || this.passwordIsTooShort || this.errorOccurredSettingPassword
    },
    formErrorMessage(): string {
      if (this.passwordsDoNotMatch) {
        return PASSWORDS_DO_NOT_MATCH_ERROR_MESSAGE
      }

      if (this.passwordIsTooShort) {
        return PASSWORD_TOO_SHORT_ERROR_MESSAGE
      }

      if (this.errorOccurredSettingPassword) {
        return ERROR_OCCURRED_SETTING_PASSWORD_MESSAGE
      }

      return ""
    },
  },
  methods: {
    handleSetPasswordClicked() {
      if (this.password !== this.confirmPassword) {
        this.passwordsDoNotMatch = true
      } else if (this.password.length < 6) {
        this.passwordIsTooShort = true
      } else {
        this.passwordsDoNotMatch = false
        this.passwordIsTooShort = false

        this.setPassword()
      }
    },
    async setPassword() {
      const { forgotPasswordId } = this.$route.params
      this.savingPassword = true

      try {
        await makeAuthenticatedRequest(getForgotPasswordRedemptionUrl(forgotPasswordId), "POST", {
          newPassword: this.password,
        })

        this.$router.push("/login")
      } catch (error) {
        this.savingPassword = false
        this.errorOccurredSettingPassword = true
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.invalid-url-text {
  font-size: 1.2rem;
}

.password-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-input {
  width: 40rem;
}

@media screen and (max-width: 440px) {
  .password-input {
    width: 30rem;
  }
}
</style>
