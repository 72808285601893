<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206 188">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M184,188H22A22,22,0,0,1,0,166V22a4,4,0,0,1,4-4H202a4,4,0,0,1,4,4V166A22,22,0,0,1,184,188ZM8,26V166a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V26Z"
        />
        <path class="cls-1" d="M121,44a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V40A4,4,0,0,1,121,44Z" />
        <path class="cls-1" d="M85,44a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V40A4,4,0,0,1,85,44Z" />
        <path class="cls-1" d="M157,44a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V40A4,4,0,0,1,157,44Z" />
        <path class="cls-1" d="M49,44a4,4,0,0,1-4-4V4a4,4,0,0,1,8,0V40A4,4,0,0,1,49,44Z" />
        <path
          class="cls-1"
          d="M89.5,152a4,4,0,0,1-3.58-5.79L119,80H80.5a4,4,0,0,1,0-8h45a4,4,0,0,1,3.58,5.79l-36,72A4,4,0,0,1,89.5,152Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #003347;
}
</style>
