<template>
  <EngieTable
    :headers="headers"
    :items="users || []"
    item-key="id"
    :loading="loading"
    freeze-first-column
    no-data-text="No users found"
    :total-count="totalCount"
    sort-by="name"
    :sort-desc="sortDesc"
    editable
    @page-number-updated="handlePageNumberUpdated($event)"
    @page-size-updated="handlePageSizeUpdated($event)"
    @page-sort-descending-updated="handlePageSortDescendingUpdated($event)"
    @edit-row-clicked="handleEditRowClicked($event)"
  >
    <template #item.companyRole="{ item }">
      <span>{{ getFormattedRoleName(item.companyRole) }}</span>
    </template>
    <template #item.createdDateTime="{ item }">
      <span>{{ formatCreatedDate(item.createdDateTime) }}</span>
    </template>
  </EngieTable>
</template>

<script lang="ts">
import Vue from "vue"
import { DataTableHeader } from "vuetify"
import { formatShortDate } from "@/util/dateUtils"
import { CompanyRole } from "../../types/CompanyRole"
import EngieTable from "../EngieTable.vue"

export default Vue.extend({
  components: {
    EngieTable,
  },
  props: {
    users: {
      type: Array,
      default: null,
    },
    totalCount: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortDesc: false,
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: "User Name",
          value: "name",
        },
        {
          text: "Job Title",
          value: "jobTitle",
          sortable: false,
          align: "center",
        },
        {
          text: "Role",
          value: "companyRole",
          sortable: false,
          align: "center",
        },
        {
          text: "Created",
          value: "createdDateTime",
          sortable: false,
          align: "center",
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
          align: "center",
        },
        {
          text: "Phone",
          value: "phoneNumber",
          sortable: false,
          align: "center",
        },
      ]
    },
  },
  methods: {
    handlePageNumberUpdated(pageNumber: number) {
      this.$emit("page-number-updated", pageNumber)
    },
    handlePageSizeUpdated(pageSize: number) {
      this.$emit("page-size-updated", pageSize)
    },
    handlePageSortDescendingUpdated(sortDescending: boolean) {
      this.sortDesc = sortDescending
      this.$emit("page-sort-descending-updated", sortDescending)
    },
    handleEditRowClicked(itemId: string) {
      this.$emit("edit-user-clicked", itemId)
    },
    getFormattedRoleName(roleName: string) {
      if (roleName === CompanyRole.HEAD_ADMIN) {
        return "Head Admin"
      }

      if (roleName === CompanyRole.ADMIN) {
        return "Admin"
      }

      return "User"
    },
    formatCreatedDate(dateString: string) {
      return formatShortDate(dateString)
    },
  },
})
</script>

<style lang="scss" scoped>
.name-container {
  display: flex;

  .status-chip {
    color: var(--white);
    margin-left: auto;
    min-width: 6rem;
    display: flex;
    justify-content: center;

    &::v-deep {
      .v-chip__content {
        text-align: center;
        text-transform: uppercase;
        font-size: 0.7rem;
      }
    }
  }
}
.users-link {
  color: var(--navy);
  text-decoration: underline;
}
</style>
