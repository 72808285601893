import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { ProjectThumbnail } from "@/types/ProjectThumbnail"
import { CompanyRecord } from "@/types/CompanyRecord"
import { Project } from "@/types/Project"
import { getCompanyUrl, getUsersUrl } from "@/util/urls"

// POST to /companies/{companyId}/projects/thumbnails
export const createProjectThumbnail = async (
  projectThumbnailData: FormData,
  companyId: string
): Promise<ProjectThumbnail> => {
  try {
    const response: ProjectThumbnail = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/thumbnails`,
      "POST",
      projectThumbnailData
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// POST to /companies/{companyId}/projects
export const createProject = async (projectData: Project, companyId: string): Promise<Project> => {
  try {
    const response: Project = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects`,
      "POST",
      projectData
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL COMPANY USERS
export const getAllCompanyUsersWithProfilePicture = async (companyId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getUsersUrl()}/gallery?companyId=${companyId}&sortBy=createdDateTime&sortDirection=desc`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// SORT BY STATUS RESULTS AS QUERY
export const sortByStatusSortDirectionQuery = (sortByStatus: string, sortByField: string, sortDirection: string) => {
  if (sortByStatus && sortByField && sortDirection) {
    return `status=${sortByStatus}&sortBy=${sortByField}&sortDirection=${sortDirection}`
  }
  if (!sortByStatus && sortByField && sortDirection) {
    return `sortBy=${sortByField}&sortDirection=${sortDirection}`
  }
  return ``
}

// SEARCH QUERY
const getSearchQueryParam = (search?: string) => (search ? `&search=${search}` : "")

// ADD USER ID AS QUERY
export const sortByUserQuery = (userId?: string) => (userId ? `&userId=${userId}` : ``)

// ADD PAGE SIZE
export const getPageSize = (pageSize?: String) => (pageSize ? `&pageSize=${pageSize}` : ``)

// ADD PAGE NUMBER
export const getPageNumber = (pageNumber?: String) => (pageNumber ? `&pageNumber=${pageNumber}` : ``)

// GET ALL PROJECTS WITH USER
export const getAllCompanyProjects = async (
  companyId: string,
  userId: string,
  sortByStatus: string,
  sortByField: string,
  sortDirection: string,
  search: string,
  pageSize: string,
  pageNumber: string
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/gallery?${sortByStatusSortDirectionQuery(
        sortByStatus,
        sortByField,
        sortDirection
      )}${getPageSize(pageSize)}${getPageNumber(pageNumber)}${getSearchQueryParam(search)}${sortByUserQuery(userId)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL PROJECTS WITH USER
export const getAllCompanyProjectsNotPaginated = async (
  companyId: string,
  userId: string,
  paginated: boolean,
  search: string
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(
        companyId
      )}/projects/gallery?paginated=${paginated}&sortBy=createdDateTime&sortDirection=desc${getSearchQueryParam(
        search
      )}${sortByUserQuery(userId)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ONE PROJECT
export const getOneCompanyProject = async (companyId: string, projectId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/project-info`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL COMPANY DEPARTMENTS
export const getAllCompanyDepartments = async (companyId: string) => {
  try {
    const response = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/departments`, "GET")
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL COMPANY DEPARTMENTS GALLERY
export const getAllCompanyDepartmentsGallery = async (companyId: string, projectId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/department-gallery`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// POST A DEPARTMENT
export const postCompanyDepartment = async (companyId: string, departmentData: any) => {
  try {
    const response = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/departments`, "POST", departmentData)
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// PATCH A DEPARTMENT
export const patchCompanyDepartment = async (companyId: string, departmentId: string, departmentData: any) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/departments/${departmentId}`,
      "PATCH",
      departmentData
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// PATCH A PROJECT
export const patchCompanyProject = async (companyId: string, projectId: string, projectData: any) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}`,
      "PATCH",
      projectData
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET NAME FOR ONE COMPANY BY COMPANYID
export const getCompanyName = async (companyId: string): Promise<String> => {
  try {
    const response: CompanyRecord = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}`, "GET")
    const companyName: String = response.name
    return companyName
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL USERS WHEN MEMBERS OF A PROJECT
export const getAllUsersWhenMembersOfProject = async (companyId: string, projectId: string) => {
  try {
    const response = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/projects/${projectId}/member-gallery`)
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL USERS WHEN MEMBERS OF A PROJECT
export const getAllExternalUsersWhenMembersOfProject = async (companyId: string, projectId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/external-member-gallery`
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL DEPARTMENT CATEGORIES
export const getAllDepartmentCategories = async (companyId: string, departmentId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/departments/${departmentId}/categories`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL DEPARTMENT CATEGORIES GALLERY
export const getAllDepartmentCategoriesGallery = async (companyId: string, projectId: string, departmentId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/${departmentId}/category-gallery`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// POST A DEPARTMENT CATEGORY
export const postDepartmentCategory = async (companyId: string, departmentId: string, categoryData: any) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/departments/${departmentId}/categories`,
      "POST",
      categoryData
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// PATCH A DEPARTMENT CATEGORY
export const patchDepartmentCategory = async (
  companyId: string,
  departmentId: string,
  categoryId: string,
  categoryData: any
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/departments/${departmentId}/categories/${categoryId}`,
      "PATCH",
      categoryData
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// SORT BY FIELD AND DIRECTION
export const sortByFieldAndDirection = (sortByField: string, sortDirection: string) =>
  `?sortBy=${sortByField}&sortDirection=${sortDirection}`

// SEARCH QUERY
const getNotesSearchQueryParam = (search?: string) => (search ? `&notesSearch=${search}` : "")

// SEARCH CATEGORY IDS
const getDepartmentCategoryIdsQueryParam = (categoryIds?: string, departmentIds?: string, noCategory?: boolean) => {
  if (categoryIds && !noCategory) {
    return `&categoryIds=${categoryIds}`
  }
  if (categoryIds && noCategory === true) {
    return `&categoryIds=${categoryIds}&departmentId=${departmentIds}&noCategory=true`
  }
  if (!categoryIds && noCategory) {
    return `&departmentId=${departmentIds}&noCategory=true`
  }
  return ``
}

// GET COMPANY MAP MARKERS
export const getProjectMarkersByCategory = async (
  companyId: string,
  projectId: string,
  sortByField: string,
  sortDirection: string,
  paginated: string,
  categoryIds?: string,
  departmentId?: string,
  noCategory?: boolean,
  search?: string
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/map-markers${sortByFieldAndDirection(
        sortByField,
        sortDirection
      )}&paginated=${paginated}${getDepartmentCategoryIdsQueryParam(
        categoryIds,
        departmentId,
        noCategory
      )}${getNotesSearchQueryParam(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const getProjectMarkersByDepartment = async (
  companyId: string,
  projectId: string,
  departmentIds: string,
  sortByField: string,
  sortDirection: string,
  paginated: string,
  search?: string
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/map-markers${sortByFieldAndDirection(
        sortByField,
        sortDirection
      )}&paginated=${paginated}&departmentIds=${departmentIds}${getNotesSearchQueryParam(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET PROJECT MARKERS NO DEPARTMENT
export const getProjectMarkersNoDepartment = async (
  companyId: string,
  projectId: string,
  sortByField: string,
  sortDirection: string,
  paginated: string,
  search?: string
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/map-markers${sortByFieldAndDirection(
        sortByField,
        sortDirection
      )}&paginated=${paginated}&noDepartment=true${getNotesSearchQueryParam(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET PROJECT MARKERS NO CATEGORY
export const getProjectMarkersNoCategory = async (
  companyId: string,
  projectId: string,
  departmentIds: string,
  sortByField: string,
  sortDirection: string,
  paginated: string,
  search?: string
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/map-markers${sortByFieldAndDirection(
        sortByField,
        sortDirection
      )}&paginated=${paginated}&departmentIds=${departmentIds}${getNotesSearchQueryParam(search)}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL USERS (FOR ADDING EXTERNAL USERS)
export const getAllUsersByEmailAddressSearchExcludingCompany = async (excludeCompanyId: string, search: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getUsersUrl()}/email-addresses?excludeCompanyId=${excludeCompanyId}&search=${search}`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET ALL ENGIECAM USERS REGARDLESS OF COMPANY
export const getAllUsersByEmailAddressSearch = async (search: string) => {
  try {
    const response = await makeAuthenticatedRequest(`${getUsersUrl()}/email-addresses?search=${search}`, "GET")
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// PATCH PROJECT'S EXTERNAL MEMBERS LIST
export const patchProjectExternalMembersList = async (
  companyId: string,
  projectId: string,
  externalMemberIds: Object
) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/external-members`,
      "PATCH",
      externalMemberIds
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

// GET RECENT PROJECT
export const getRecentProjectGalleryEntry = async (companyId: string, projectId: string) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${getCompanyUrl(companyId)}/projects/${projectId}/gallery-entry`,
      "GET"
    )
    return response
  } catch (error) {
    throw new Error(`${error}`)
  }
}

export const deleteDiscipline = (companyId: string, departmentId: string) =>
  makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/departments/${departmentId}`, "DELETE")

export const deleteCategory = (companyId: string, departmentId: string, categoryId: string) =>
  makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/departments/${departmentId}/categories/${categoryId}`, "DELETE")
