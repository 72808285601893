<template>
  <div class="engie-text-input" :class="inputClass">
    <v-text-field
      ref="textField"
      v-mask="mask"
      :value="value"
      :disabled="disabled"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      outlined
      :type="type"
      :rules="required !== null ? [requiredRule] : []"
      :error-messages="errorMessages"
      :min="min"
      :max="max"
      :autofocus="autofocus"
      @input="handleInput($event)"
      @blur="handleBlur($event)"
      @keydown.enter="handleEnterPressed()"
    >
      <template #append>
        <transition name="loading">
          <v-progress-circular v-if="loading" size="24" color="primary" indeterminate></v-progress-circular>
        </transition>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { required as requiredRule } from "vuelidate/lib/validators"

const INPUT_PADDING_PX = 24
const PHONE_MASK = "(###) ###-####"

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "Label",
    },
    placeholder: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: null,
    },
    /**
     * When set to true, the form label is initially placed inside the input, with a transform
     * that moves it when the user types.
     */
    animatedLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    phone: {
      type: Boolean,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredRule,
    }
  },
  computed: {
    mask() {
      return this.phone === null ? null : PHONE_MASK
    },
    inputClass() {
      if (this.animatedLabel && this.inlineLabel) {
        // eslint-disable-next-line no-console
        console.error("An Engie Text Input may not have both the animated-label and inline-label props set to true")
      }

      if (this.animatedLabel) {
        return "animated-label"
      }

      if (this.inlineLabel) {
        return "inline-label"
      }

      return ""
    },
  },
  mounted() {
    if (this.animatedLabel) {
      this.positionLabelAtCenter()
    }
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    handleBlur(value: Event) {
      this.$emit("blur", value)
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
    positionLabelAtCenter() {
      Vue.nextTick(() => {
        if (this.$refs.textField) {
          const textField = this.$refs.textField as Vue

          const inputWidthPx = textField.$el.clientWidth - INPUT_PADDING_PX
          const labelEl = textField.$el.querySelector("label")

          if (labelEl) {
            const inputLabelWidthPx = labelEl.clientWidth
            labelEl.style.transform = `translateX(${inputWidthPx / 2 - inputLabelWidthPx / 2}px)`
          }
        }
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-text-input::v-deep .v-text-field {
  width: 100rem;
  label {
    color: var(--charcoal);
    text-align: center;
  }
  fieldset {
    border-color: var(--light-grey);
    border-radius: 0.5rem;
  }

  legend {
    // Vuetify sets this property in javascript. So, a necessary sin.
    width: 0 !important;
  }

  &.v-input--is-focused {
    caret-color: var(--orange) !important;

    fieldset {
      border-color: var(--grey);
      border-width: 0.1rem;
      border-radius: 0;
    }
  }

  .error--text {
    color: red !important;
  }

  &.v-input {
    outline: none;
  }
}

.engie-text-input.animated-label::v-deep .v-text-field {
  input {
    padding: 2.1rem 0 0.8rem;
  }
  &.v-input--is-focused,
  &.v-input--is-dirty {
    label.v-label--active {
      color: var(--mid-grey) !important;
      transform: translate(0, -1.2rem) scale(0.7) !important;
    }
  }
}

.engie-text-input.inline-label::v-deep .v-text-field {
  &.v-input--is-focused,
  &.v-input--is-dirty {
    label.v-label--active {
      opacity: 0;
      transform: none;
    }
  }
}

.engie-text-input:not(.animated-label):not(.inline-label)::v-deep .v-text-field {
  padding-top: 5rem;
  label {
    transform: translate(-1rem, -5rem);
  }
}

.engie-text-input::v-deep {
  .v-messages__message {
    line-height: 1.4rem;
  }
}

.loading-enter-active,
.loading-leave-active {
  transition: all 1s ease-out;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
}
</style>
