import { render, staticRenderFns } from "./ProjectDashNav.vue?vue&type=template&id=2e6d2e92&scoped=true"
import script from "./ProjectDashNav.vue?vue&type=script&lang=js"
export * from "./ProjectDashNav.vue?vue&type=script&lang=js"
import style0 from "./ProjectDashNav.vue?vue&type=style&index=0&id=2e6d2e92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6d2e92",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VList,VListItem,VMenu})
