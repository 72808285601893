<template>
  <div class="sub-header">
    <h2><slot></slot></h2>
    <slot name="buttons"></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({})
</script>

<style lang="scss" scoped>
.sub-header {
  display: flex;
  border-bottom: 2px solid var(--navy);
  margin-bottom: 2rem;

  h2 {
    padding-bottom: 1rem;
  }
}
</style>
