/// <reference path="../types.d.ts" />
// Clipboard functionality differs across browsers, so we need to check for the ClipboardItem API
export const copyToClipboard = async (text: string) => {
  if (typeof ClipboardItem && navigator.clipboard.write) {
    const type = "text/plain"

    const blob = new Blob([text], { type })
    const data = [new ClipboardItem({ [type]: blob })]

    await navigator.clipboard.write(data)
  } else {
    await navigator.clipboard.writeText(text)
  }
}
