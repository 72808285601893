<template>
  <EngieSelect
    :value="value"
    :options="options"
    :loading="options.length === 0"
    :label="label"
    :disabled="disabled"
    @input="handleChange($event)"
  />
</template>

<script lang="ts">
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { PaginatedResponse } from "@/types/PaginatedResponse"
import { SelectOption } from "@/types/SelectOption"
import { ReferenceDataItem } from "@/types/ReferenceDataItem"
import { getReferenceDataListUrl } from "@/util/urls"
import Vue from "vue"
import EngieSelect from "./EngieSelect.vue"

/**
 * This specialized select component is set up to auto-populate its items through the reference data service. :)
 */
export default Vue.extend({
  components: {
    EngieSelect,
  },
  props: {
    label: {
      type: String,
      default: "Label",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    /**
     * The name of the reference data list you want to pull from, i.e. 'plan-types'
     */
    referenceDataName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [] as SelectOption[],
      value: "",
    }
  },
  created() {
    this.fetchReferenceData()
  },
  methods: {
    async fetchReferenceData() {
      // TODO: handle errors
      const response = await makeAuthenticatedRequest<PaginatedResponse<ReferenceDataItem>>(
        getReferenceDataListUrl(this.referenceDataName)
      )

      this.options = response.items.map((item: ReferenceDataItem) => ({ text: item.displayName, value: item.id }))
      const [firstOption] = this.options
      this.value = firstOption.value
      this.handleChange(firstOption.value)
      this.$emit("default-option-fetched", firstOption.value)
    },
    handleChange(value: string) {
      this.$emit("input", value)
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-text-input::v-deep .v-text-field {
  width: 100rem;
  label {
    color: var(--black);
    text-align: center;
  }
  fieldset {
    border-color: var(--light-grey);
    border-radius: 0.5rem;
  }

  input {
    padding: 2.1rem 0 0.8rem;
  }

  legend {
    // Vuetify sets this property in javascript. So, a necessary sin.
    width: 0 !important;
  }

  &.v-input--is-focused {
    caret-color: var(--orange) !important;

    fieldset {
      border-color: var(--grey);
      border-width: 0.1rem;
      border-radius: 0;
    }
  }

  &.animated-label {
    &.v-input--is-focused,
    &.v-input--is-dirty {
      label.v-label--active {
        color: var(--mid-grey) !important;
        transform: translate(0, -1.2rem) scale(0.7) !important;
      }
    }
  }

  &:not(.animated-label) {
    padding-top: 5rem;
    label {
      transform: translateY(-5rem);
    }
  }
}
</style>
