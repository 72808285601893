import { PaginatedResponse } from "@/types/PaginatedResponse"
import { ReferenceDataItem } from "@/types/ReferenceDataItem"
import { makeAuthenticatedRequest } from "./makeAuthenticatedRequest"
import { getReferenceDataListUrl } from "./urls"

type ReferenceDataName = "plan-types" | "storage-amounts"

export const getReferenceDataValues = async (referenceDataName: ReferenceDataName): Promise<ReferenceDataItem[]> => {
  const referenceDataResponse = await makeAuthenticatedRequest<PaginatedResponse<ReferenceDataItem>>(
    getReferenceDataListUrl(referenceDataName)
  )
  return referenceDataResponse.items
}

export const getReferenceDataDisplayName = (referenceDataList: ReferenceDataItem[], id: string) => {
  return referenceDataList.find(referenceDataEntry => referenceDataEntry.id === id)?.displayName
}

export const fetchReferenceDataListAndFindDisplayName = async (referenceDataName: ReferenceDataName, id: string) => {
  const referenceDataList = await getReferenceDataValues(referenceDataName)

  const displayName = getReferenceDataDisplayName(referenceDataList, id)

  if (!displayName) {
    throw new Error("Invalid reference data id")
  }

  return displayName
}
