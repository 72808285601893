<template>
  <ProjectDashboardContent>
    <template #title>
      <div class="d-flex flex-column" style="width: 100%">
        Project Details
        <v-divider style="margin-bottom: 3rem; border-color: var(--black)" />
      </div>
    </template>
    <template #content>
      <div class="d-flex content">
        <v-img
          :src="project.thumbnailImageUrl"
          alt="Project Cover Photo"
          height="35.8rem"
          width="35.8rem"
          style="border-radius: 5px; margin-right: 40px"
        />
        <div class="project-info">
          <h2 style="margin-bottom: 1.8rem">{{ project.name }}</h2>
          <h3 style="margin-bottom: 1.8rem">#{{ project.number }}</h3>
          <h3 style="margin-bottom: 1.8rem">
            <span style="margin-right: 1rem">Project Location: </span> {{ project.locationName }}
          </h3>
          <h3><span>Project Scope: </span></h3>
          <h3>{{ project.scope }}</h3>
        </div>
      </div>
    </template>
  </ProjectDashboardContent>
</template>

<script>
import Vue from "vue"
import ProjectDashboardContent from "@/components/ProjectDashboard/ProjectDashboardContent.vue"

export default Vue.extend({
  components: { ProjectDashboardContent },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
::v-deep {
  .dashboard-title,
  .dashboard-body {
    font-weight: 400 !important;
    font-family: "CamptonMedium" !important;
  }
}

.content {
  margin-left: 12px;
  line-height: 3.3rem;
}

.project-info {
  h2 {
    font-size: 3.2rem;
    font-weight: 400;
    color: var(--navy);
  }
  h3 {
    font-family: "CamptonRegular";
    font-weight: 100;
    span {
      font-weight: 800;
    }
  }
}
</style>
