<template>
  <div class="info-container">
    <InfoPanel
      v-for="infoPanel in infoPanels"
      :key="infoPanel.label"
      :label="infoPanel.label"
      :property-name-to-update="infoPanel.propertyNameToUpdate"
      :initial-value="infoPanel.content"
      :phone="infoPanel.phone"
      :editable="infoPanel.editable"
      :company-size="infoPanel.companySize"
      @saved="handleSave($event)"
      >{{ infoPanel.content }}</InfoPanel
    >
  </div>
</template>
<script lang="ts">
import { CompanyWithMetadataRecord } from "@/types/CompanyWithMetadataRecord"
import Vue from "vue"
import InfoPanel from "./InfoPanel.vue"

type InfoPanelProps = {
  label: string
  content: string
}

export default Vue.extend({
  components: {
    InfoPanel,
  },
  props: {
    infoPanels: {
      type: Array,
      default: [] as InfoPanelProps[],
    },
  },
  methods: {
    handleSave(update: Partial<CompanyWithMetadataRecord>) {
      this.$emit("saved", update)
    },
  },
})
</script>

<style lang="scss" scoped>
.info-container {
  margin: 0 0 3rem 0;
  width: 50%;

  &::v-deep .info-panel:not(:last-child) {
    border-bottom: 1px solid var(--light-grey);
  }
}
</style>
