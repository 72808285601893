<template>
  <div>
    <div v-if="loading" class="project-category-photo-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else class="container" style="">
      <div class="d-flex flex-column header-container" style="height: 16rem">
        <div class="d-flex justify-space-between align-center" style="padding: 0 3rem; height: 5rem">
          <h2 class="header">
            <router-link class="header-link" to="" @click.native="handleProjectRouteClick">
              All Departments
            </router-link>
            > No Department
          </h2>
          <NoDeptProjectPhotoSwitch :company-id="companyId" :share-switch="true" />
        </div>
        <v-divider style="width: 100%; margin-top: 1rem" />
        <div class="search-toolbar">
          <EngieSearchInput class="search" :label="'Through Notes'" @input="debouncedHandler" />
        </div>
      </div>
      <div class="content-container">
        <transition name="route" mode="out-in">
          <div v-if="routerLoading" class="project-category-photo-loading-spinner-container">
            <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
          </div>
          <router-view
            v-else
            :is-shared="true"
            :no-department="true"
            :markers="markers"
            :photos="photos"
            :search-loading="loading"
            :company-id="companyId"
            :user-home-company-id="userHomeCompanyId"
            :is-external-user="isExternalUser"
            :project="project"
            :departments="departments"
            :selected-department="selectedDepartment"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EngieSearchInput from "@/components/forms/EngieSearchInput.vue"
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import { getProjectMarkersNoDepartment } from "@/services/projectService"
import NoDeptProjectPhotoSwitch from "@/components/NoDepartment/NoDeptProjectPhotoSwitch.vue"

export default {
  components: {
    EngieSearchInput,
    NoDeptProjectPhotoSwitch,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: null,
    },
    selectedDepartment: {
      type: Object,
      default: null,
    },
    selectedCategory: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    photoSearchInput: "",
    markers: [],
    photos: [],
    routerLoading: false,
    header: "",
  }),
  computed: {
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },
  },
  created() {
    this.debouncedHandler = debounce(async event => {
      this.routerLoading = true
      await this.searchProjectPhotos(event)
    }, DEBOUNCE_INTERVAL)
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async setMarkers() {
      const markers = await this.fetchNoDepartmentMarkers(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false",
        null
      )
      return markers.items
    },

    async fetchNoDepartmentMarkers(companyId, projectId, sortByField, sortDirection, paginated, search) {
      try {
        const response = await getProjectMarkersNoDepartment(
          companyId,
          projectId,
          sortByField,
          sortDirection,
          paginated,
          search
        )
        return response
      } catch (error) {
        throw new Error(`error`)
      }
    },

    async initialize() {
      this.loading = true
      const markers = await this.fetchNoDepartmentMarkers(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false"
      )
      this.markers = markers.items
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.loading = false
    },

    async searchProjectPhotos(value) {
      this.routerLoading = true
      this.photoSearchInput = value
      const markers = await this.fetchNoDepartmentMarkers(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false",
        this.photoSearchInput
      )
      this.markers = markers.items
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.routerLoading = false
    },

    handleProjectRouteClick() {
      this.$router.push(`/share/companies/${this.companyId}/projects/${this.project.id}/`)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  font-family: "CamptonSemiBold";
  font-size: 2.8rem;
  font-weight: bold;
}

.header-link {
  text-decoration: none;
  color: var(--black);
  transition: 0.25s;
  &:hover {
    color: var(--primary);
  }
}

.content-container {
  min-height: 72.2rem;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 6rem);
  margin-top: 6rem;
  max-width: 100% !important;
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
}

.search {
  margin-right: 2rem;
  &::v-deep {
    .v-text-field,
    .v-text-field--solo,
    .v-input--dense,
    .v-input__control {
      min-height: 3.2rem !important;
      width: 32rem;
    }
    .v-input__prepend-outer {
      margin: auto !important;
      padding-bottom: 0.5rem;
      display: flex;
    }
    .v-input__control {
      padding-left: 1rem;
    }
  }
}

.search-toolbar {
  display: flex;
  align-items: center;
  padding-left: 4.5rem;
  height: 7rem;
  box-shadow: none;
}

.project-category-photo-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
