<template>
  <div class="d-flex align-center justify-space-between">
    <ProjectSwitch
      :is-external-user="isExternalUser"
      :user-projects-count="userProjectsCount"
      :total-count="totalCount"
      @clicked="handleSwitchClick"
    />
    <EngieMenu :default-option="defaultStatus" :options="statusOptions" @submit="handleStatusClick" />
    <EngieMenu :default-option="defaultSort" :options="sortOptions" @submit="handleSortClick" />
    <v-spacer />
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import EngieMenu from "../EngieMenu.vue"
import ProjectSwitch from "./ProjectSwitch.vue"

export default Vue.extend({
  components: { ProjectSwitch, EngieMenu },
  props: {
    userProjectsCount: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    select: {
      type: String,
      default: "",
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultStatus: { label: "Active", fieldValue: "active" },
      defaultSort: { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
      statusOptions: [
        { label: "Active", fieldValue: "active" },
        { label: "Proposed", fieldValue: "proposed" },
        { label: "Archived", fieldValue: "archived" },
      ],
      sortOptions: [
        { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
        { label: "Oldest to Newest", fieldValue: "createdDateTime", directionValue: "asc" },
        { label: "Project Name (Ascending)", fieldValue: "name", directionValue: "asc" },
        { label: "Project Name (Descending)", fieldValue: "name", directionValue: "desc" },
        { label: "Project Number (Ascending)", fieldValue: "number", directionValue: "asc" },
        { label: "Project Number (Descending)", fieldValue: "number", directionValue: "desc" },
      ],
    }
  },
  methods: {
    handleSwitchClick(value: String) {
      this.$emit("project-switch-clicked", value)
    },
    handleStatusClick(value: String) {
      this.$emit("project-status-clicked", value)
    },
    handleSortClick(value: String) {
      this.$emit("project-sort-clicked", value)
    },
    // change sortOptions to Objects,  { label: ui facing value, fieldValue: directionValue: }
  },
})
</script>

<style lang="scss" scoped>
.status-select {
  height: 5.3rem;
  margin-left: 1.4rem;
  &::v-deep {
    .engie-select,
    .v-select {
      padding: 0px !important;
      margin-top: 0px !important;
    }
    .v-input__append-inner {
      color: var(--navy);
      display: flex;
      align-items: center;
      height: 100%;
      margin-top: 0;
      padding-right: 0.4rem;
    }
    .v-select__selection,
    .v-select__selection--comma {
      font-family: "CamptonMedium";
      text-decoration: none !important;
    }
  }
}
</style>
